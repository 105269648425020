import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBookmarked, deleteBookMark } from "redux/actions/userPageAction";
import { PocoCard } from "components/elements/cards";
import { ShowMore } from "components/elements/showMore";
import { Grid, Typography} from "@material-ui/core";
import { ephocDateCovert } from "core/utils";

export default function MyBookMarks(props) {
  const { userId } = props;
  const dispatch = useDispatch();
  const [postCount, setpostCount] = useState(10);

  // Get post by user id
  useEffect(() => {
    if (userId) {
      dispatch(getBookmarked(1, postCount, userId));
    }
  }, [dispatch, userId, postCount]);

  // Global state call
  const { userBookmarkedPostList } = useSelector((state) => state.userDetails);
  const { tagData, totalCount } =
    (userBookmarkedPostList && userBookmarkedPostList) || [];

  const postCountUpdate = () => {
    setpostCount(postCount + 10);
  };

  const renderUserInformation = () => {
    // method to delete bookmark
    const postAction = async (postId) => {
      await dispatch(deleteBookMark(userId, postId));
      dispatch(getBookmarked(1, 10, userId));
    };
    return (
      <React.Fragment>
        <Typography variant="h6">
          <strong>My Bookmarks</strong>
        </Typography>
        <Grid container spacing={3}>
          {tagData?.map((alldata) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              key={alldata.id}
              component="span"
              className="my-bookmark"
            >
              <PocoCard
                postid={alldata.id}
                Img={alldata.postThumbnail}
                Tittle={alldata.postTitle}
                ProfilePic={alldata.postAvatar}
                UserName={alldata.postUserName}
                postCreatedOn={ephocDateCovert(alldata.createdAt)}
                PostReply={alldata.postReply}
                tag={alldata.tagName}
                view={alldata.postViews}
                like={alldata.postLikes}
                profileId={alldata.userId}
                isBookmarked={alldata.isBookmarked}
                isUserliked={alldata.isUserLiked}
                action={{
                  isdeletebtnEnable: true,
                  postAction: () => postAction(alldata.id),
                }}
              />
            </Grid>
          ))}
        </Grid>
      </React.Fragment>
    );
  };
  return (
    <div className="my-post-container">
      <React.Fragment>
        {renderUserInformation()}
        <ShowMore
          data={tagData}
          totalCount={totalCount}
          propFunction={postCountUpdate}
        />
      </React.Fragment>
    </div>
  );
}
