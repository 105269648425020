import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyDrafts } from "redux/actions/userPageAction";
import {  deletPost } from "redux/actions/allPostAction";
import { MyyDraftCard } from "components/elements/mydraftCard";
import { Grid, Typography} from "@material-ui/core";
import { ephocDateCovert } from "core/utils";
import { getUserProfile } from "redux/actions/userProfileAction";
import { ShowMore } from "components/elements/showMore";

export default function MyDrafts() {
  // Global state call
  const { myDraftList } = useSelector((state) => state.userDetails);
  const { tagData, totalCount } = (myDraftList && myDraftList) || [];

  const [draftCount, setdraftCount] = useState(10);

  const dispatch = useDispatch();
  const { isValidApiToken } = useSelector((state) => state.apiAccess);
  const { userProfile } = useSelector((state) => state.userProfile);
  const { userProfile: profileInfo } = userProfile || {};
  const { userId } = profileInfo || 0;

  // Get post by user id
  useEffect(() => {
    if (isValidApiToken) {
      dispatch(getMyDrafts(1, draftCount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isValidApiToken, draftCount]);

  const draftCountUpdate = () => {
    setdraftCount(draftCount + 10);
  };

  const renderDraftList = () => {
    // delete post action
    const postAction = async (postId) => {
      await dispatch(deletPost(postId, userId));
      await dispatch(getMyDrafts(1, draftCount));
      dispatch(getUserProfile(userId));
    };

    return (
      <React.Fragment>
        <Typography variant="h6" >
          <strong>My Drafts</strong>
        </Typography>
        <Grid container spacing={2}>
          {tagData?.map((alldata) => (
            <Grid item xs={12} sm={12} md={6} key={alldata.id} component="span">
              <MyyDraftCard
                postid={alldata.id}
                Img={alldata.postThumbnail}
                Tittle={alldata.postTitle}
                ProfilePic={alldata.postAvatar}
                UserName={alldata.postUserName}
                tag={alldata.tagName}
                view={alldata.postViews}
                like={alldata.postLikes}
                profileId={alldata.userId}
                isBookmarked={alldata.isBookmarked}
                isUserliked={alldata.isUserLiked}
                postCreatedOn={ephocDateCovert(alldata.createdAt)}
                action={{
                  isdeletebtnEnable: true,
                  isEditEnable:false,
                  postAction: () => postAction(alldata.id),
                }}
              />
            </Grid>
          ))}
        </Grid>
      </React.Fragment>
    );
  };
  return (
    <div className="my-post-container">
      <React.Fragment>
        {renderDraftList()}
        <ShowMore
          data={tagData}
          totalCount={totalCount}
          propFunction={draftCountUpdate}
        />
      </React.Fragment>
    </div>
  );
}
