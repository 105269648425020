import React, { useEffect } from "react";
import { AppBar, Box, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { CustomLink } from "components/elements/customLink";
// import HomeIcon from "@material-ui/icons/Home";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { getLoggedInuser } from "redux/actions/userAction";

import { isValidToken } from "core/utils";

export default function CmsMobileAppBar(props) {
  const { logOut } = props;
  const { isLoggedinUser } = useSelector((state) => state.userProfile);

  const dispatch = useDispatch()
  useEffect(() => {
    const isValid = isValidToken();
    dispatch(getLoggedInuser(isValid));
  }, [dispatch]);

  const renderMobileAppBar = (
    <Box
      display={{
        xs: "block",
        sm: "none",
        md: "none",
        lg: "none",
        xl: "none",
      }}
    >
      <AppBar
        position="fixed"
        color="primary"
        style={{ top: "auto", bottom: "0" }}
        className="poco-community-header"
      >
        <Box
          display="flex"
          p={2}
          justifyContent="space-around"
          alignItems="center"
          className="appbar-icons"
        >
          {/* <Box textAlign="center">
            <CustomLink loggedIn={isLoggedinUser} path={"/admin"}>
              <HomeIcon />
              <Typography variant="body2">Home</Typography>
            </CustomLink>
          </Box> */}
          <Box textAlign="center">
            <CustomLink loggedIn={isLoggedinUser} path={"/admin"}>
              <AssignmentIcon />
              <Typography variant="body2">Post Management</Typography>
            </CustomLink>
          </Box>
          <Box textAlign="center">
            <CustomLink loggedIn={isLoggedinUser} path={"/admin/user"}>
              <SupervisedUserCircleIcon />
              <Typography variant="body2">User Management</Typography>
            </CustomLink>
          </Box>
          <Box textAlign="center" onClick={logOut}>
            <CustomLink>
              <ExitToAppIcon />
              <Typography variant="body2">Logout</Typography>
            </CustomLink>
          </Box>
        </Box>
      </AppBar>
    </Box>
  );
  return <React.Fragment>{renderMobileAppBar}</React.Fragment>;
}
