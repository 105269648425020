import * as actions from "redux/constant/types";
import { tocamelCase, $axios } from "core/utils";
// import { toastOpen } from "./globalAction";

export const accessToken = (res) => ({
  type: actions.ACCESS_TOKEN_VERIFY,
  payload: res,
});

// method to get all api token
export const getApitoken = () => {
  return async (dispatch) => {
    const data = new FormData();
    const basicAuth = {
      username: process.env.REACT_APP_CLIENT_USERNAME,
      password: process.env.REACT_APP_CLIENT_PASSWORD
    };
    data.append("grant_type", "client_credentials");

    const result = await $axios
      .post(`/oauth/token`, data, { auth: basicAuth })
      .then((response) => tocamelCase(response))
      .catch((err) => {
        if (err.response) {
          // dispatch(
          //   toastOpen({
          //     isToastOpen: true,
          //     toastMessage: err.response.data.message,
          //     toastVariant: "error",
          //   })
          // );
        }
      });

    // Api token will set in cookies with expire time
    if (result) {
      const tokenExpire = new Date();
      tokenExpire.setSeconds(tokenExpire.getSeconds() + result.data.expiresIn);
      document.cookie = `apiToken=${
        result.data.accessToken
      }; expires=${tokenExpire.toUTCString()}; path=/`;
      dispatch(accessToken(true));
    }
  };
};
