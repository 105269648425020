import * as actions from "redux/constant/types";
import { toastOpen, isPageLoad } from "./globalAction";
import { getCookies, $axios, getEncrypt } from "core/utils";

// Post sectoin status enum

export const PostSectionEnum = Object.freeze({
  UNVERIFIED: "unverified",
  VERIFIED: "verified",
  REPORTED: "reported",
  DELETED: "deleted",
  UPDATED: "updated"
});

export const cmsUnverifedPostList = (payload) => ({
  type: actions.GET_CMS_UNVERIFED_POSTLIST,
  payload,
});

export const cmsVerifedPostList = (payload) => ({
  type: actions.GET_CMS_VERIFED_POSTLIST,
  payload,
});

export const cmsReportedPostList = (payload) => ({
  type: actions.GET_CMS_REPORT_POSTLIST,
  payload,
});

export const cmsDeletedPostList = (payload) => ({
  type: actions.GET_CMS_DELETED_POSTLIST,
  payload,
});

export const clearPostList = () => ({
  type: actions.CLEAR_POST_LIST,
});

export const getcmsPostlist = (
  postStatus = 0,
  status = 1,
  searchKeyword = "",
  category = "News",
  section = PostSectionEnum.UNVERIFIED,
  page = 1
) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    dispatch(clearPostList());
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";

    // status 1 is active post and 0 is deleted post, Post status is unverifed/verifed/reporeted
    const categoryString = Array.isArray(category)
      ? category?.map((element) => element.category).join(",")
      : category;

    const data = {
      section: section,
      search: searchKeyword,
      categories: categoryString,
      page: page,
      size: 10,
      sort: "updated_at",
      order: "DESC",
    };

    await $axios
      .post(`/api/poco/v1/post/admin/all`, getEncrypt(JSON.stringify(data)), {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "Content-Type": "text/json",
          JSessionID: userId,
        },
      })
      .then((res) => {
        if (res) {
          postStatus === 0 && dispatch(cmsUnverifedPostList(res.data));
          postStatus === 1 && dispatch(cmsVerifedPostList(res.data));
          postStatus === 2 && dispatch(cmsReportedPostList(res.data));
          status === 0 && dispatch(cmsDeletedPostList(res.data));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};
export const movePostTo = (postId = 0, section = "", comment = "", history) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";
    // const dataEncrypt = userId + "&" + userId;

    const data = { section, comment, postId };

    console.log(data);
    await $axios
      .post(
        `/api/poco/v1/post/admin/action`,
        getEncrypt(JSON.stringify(data)),
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
            "Content-Type": "text/json",
            JSessionID: userId,
          },
        }
      )
      .then((res) => {
        if (res) {
          console.log(res);
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
          history && history.push("/admin");
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};
export const reportPost = (postId = 0, reason = "") => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";
    // const dataEncrypt = userId + "&" + userId;

    const data = {
      postId: postId,
      reason: reason,
    };

    await $axios
      .post(`/api/poco/v1/post/report`, getEncrypt(JSON.stringify(data)), {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "Content-Type": "text/json",
          JSessionID: userId,
        },
      })
      .then((res) => {
        if (res) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: "Post reported successfully.",
              toastVariant: "success",
            })
          );
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

export const postLogsList = (payload) => ({
  type: actions.GET_POST_LOGS,
  payload,
});

// Logs of a particular post
export const postLogs = (postId = 0, loginId = 0) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";
    const dataEncrypt = postId + "&" + loginId;
    await $axios
      .get(`/api/poco/v1/post/report/${getEncrypt(dataEncrypt)}`, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "Content-Type": "text/json",
          JSessionID: userId,
        },
      })
      .then((res) => {
        if (res) {
          dispatch(postLogsList(res.data));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
        }
      });
  };
};

export const pinUnpinPost = (postId = 0,isPinned = false) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";
    // const dataEncrypt = userId + "&" + userId;

    const data = {
      postId: postId,
    };

    await $axios
      .post(`/api/poco/v1/post/admin/pin-unpin`, getEncrypt(JSON.stringify(data)), {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "Content-Type": "text/json",
          JSessionID: userId,
        },
      })
      .then((res) => {
        if (res) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: `Post ${isPinned?"Unpinned":"Pinned"}.`,
              toastVariant: "success",
            })
          );
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

export const handleIndexChange = (payload) => ({
  type: actions.GET_TAB_INDEX,
  payload,
});