import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userCommentStyle } from "core/theme/makeStyle";
import { postBookMark, deleteBookMark } from "redux/actions/userPageAction";
import { getUserProfile } from "redux/actions/userProfileAction";
import LogAndCommentSection from "components/cmsAdmin/components/logAndComment";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CustomModal from "components/elements/customModal";
import Edit from "@material-ui/icons/Edit";
import { reportPost } from "redux/actions/cmsPostManagement";
import { ReportOutlined } from "@material-ui/icons";
import { getPostAttachment } from "redux/actions/postDetailsAction";
import {
  isValidToken,
  ephocDateCovert,
  Iconsimport,
  getRole,
  Role,
  staticReportOption,
  getLocalDateFormate,
  isMobile
} from "core/utils";
import {
  getpostDetails,
  getUserComments,
  addUserComment,
  postLike,
  postDisLike,
  viewPostCount,
  commentDelete,
} from "redux/actions/postDetailsAction";

import {
  Grid,
  Typography,
  Box,
  Button,
  List,
  ListItemText,
  ListItem,
  ListItemAvatar,
  Avatar,
  TextField,
  Container,
  IconButton,
  // Card,
  // CardContent
} from "@material-ui/core";
import "./post.scss";

export const PostPage = (props) => {
  const {
    location: { state },
  } = props;

  const [isEnableReplay, setEnableReply] = useState(0);
  const [parentComment, setParentComment] = useState({
    parentComment: "",
    isDisable: false,
    childComment: "",
  });

  const dispatch = useDispatch();

  let postPreview = useRef();

  const { isValidApiToken } = useSelector((state) => state.apiAccess);

  // const {
  //   location: { state:{ postId } },
  // } = props;
   //  get postid form props
  let { id: postId } = useParams();

  // let postId = pathname.substring(pathname.lastIndexOf("/") + 1);

  const { postContent, userComments } = useSelector(
    (state) => state.postDetails
  );
  const { userProfile } = useSelector((state) => state.userProfile);
  const {
    postContent: postData,
    // eslint-disable-next-line no-unused-vars
    userId: userIdOfPost,
    postTitle,
    tagName,
    postUserName,
    postViews,
    isBookmarked,
    isUserLiked,
    isUserDisliked,
    postLikes,
    postDisLikes,
    createdAt,
  } = postContent || {};

  // state to toggle bookmark icon
  const [BookmarToggle, setBookmarkToggle] = useState(false);

  // stste to toggle like and dislike icon
  const [toggleLike, setToggleLike] = useState(false);
  const [toggleDislike, setToggleDislike] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [dislikeCount, setDislikeCount] = useState(0);

  // to report
  const [open, setOpen] = useState(false);
  const [otherOtionEnable, setotherOptionEnable] = useState(false);
  const [otherOptionValue, setOtherOptionValue] = useState("");

  // Destructuring user information
  const { userProfile: profileInfo } = userProfile ? userProfile : {};
  const { userInfo } = profileInfo || {};
  const { profilePic, userId, firstName, lastName, role } = userInfo || "";
  const isUserLoggedIn = isValidToken();

  // is admin
  const isAdmin = Role.ADMIN === getRole(role);

  // view count to increase
  useEffect(() => {
    if (isValidApiToken) {
      dispatch(viewPostCount(postId, userId));
      dispatch(getPostAttachment());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidApiToken]);

  useEffect(() => {
    // Get post id as props
    if (isValidApiToken) {
      // Method to get post contents
      dispatch(getpostDetails(postId, userId));
      // Method to get user comments
      dispatch(getUserComments(postId, userId));
      // Method to get all user like dislike count
    }
    window.scrollTo(0, 0);
  }, [dispatch, isValidApiToken, postId, userId]);

  useEffect(() => {
    postPreview.current.innerHTML = postData
      ? unescape(postData)
      : "Loading...";
  }, [postData]);

  // Like or dislike button will be enabled if user like  or dislike the post
  useEffect(() => {
    userId && setToggleLike(isUserLiked);
    userId && setToggleDislike(isUserDisliked);
    setBookmarkToggle(isBookmarked);
    setLikeCount(postLikes);
    setDislikeCount(postDisLikes);
  }, [
    dispatch,
    isBookmarked,
    isUserDisliked,
    isUserLiked,
    postLikes,
    postDisLikes,
    userId,
  ]);

  //   post details ui render
  const renderPostContents = () => {
    //  method for like
    const handleLikes = async (likValue) => {
      if (toggleLike) {
        setLikeCount((prev) => prev - 1);
      } else {
        setLikeCount((prev) => prev + 1);
        toggleDislike
          ? setDislikeCount((prev) => prev - 1)
          : setDislikeCount((prev) => prev);
      }
      setToggleLike(likValue);
      setToggleDislike(false);

      const postLikeSchema = {
        postId: postId,
        userAvatar: profilePic,
        userId: userId,
        userName: firstName + " " + lastName,
      };
      await dispatch(postLike(postLikeSchema));
      // await dispatch(getpostDetails(postId, userId));
      // dispatch(getAllLikeDislikeCount(postId, userId));
    };

    const handleDislike = async (dislikeVlaue) => {
      if (toggleDislike) {
        setDislikeCount((prev) => prev - 1);
      } else {
        setDislikeCount((prev) => prev + 1);
        toggleLike
          ? setLikeCount((prev) => prev - 1)
          : setLikeCount((prev) => prev);
      }
      setToggleLike(false);
      setToggleDislike(dislikeVlaue);

      const postdisLikeSchema = {
        postId: postId,
        userAvatar: profilePic,
        userId: userId,
        userName: firstName + " " + lastName,
      };
      await dispatch(postDisLike(postdisLikeSchema));
      // await dispatch(getpostDetails(postId, userId));
      // dispatch(getAllLikeDislikeCount(postId, userId))
    };

    const handleBookmark = () => {
      dispatch(postBookMark(postId, userId));
      dispatch(getpostDetails(postId, userId));
      setBookmarkToggle(true);
      dispatch(getUserProfile(userId));
    };

    const dltBookmark = async () => {
      await dispatch(deleteBookMark(userId, postId));
      setBookmarkToggle(false);
      dispatch(getUserProfile(userId));
    };

    //  Report User on selected option
    const handleReportPost = (selectedOption) => {
      if (selectedOption === "Other") {
        setotherOptionEnable(true);
        return;
      } else if (selectedOption === "Other comment") {
        // console.log(postId,otherOptionValue);
        otherOptionValue.trim() &&
          dispatch(reportPost(postId, otherOptionValue));
        otherOptionValue.trim() && modalClose();
        otherOptionValue.trim() && setOtherOptionValue("");
      } else if (selectedOption !== "Other") {
        // console.log(postId,selectedOption);
        dispatch(reportPost(postId, selectedOption));
        setOtherOptionValue("");
        modalClose();
      }
    };

    const modalOpen = () => {
      setOpen(true);
    };

    const modalClose = () => {
      setOpen(false);
      setotherOptionEnable(false);
    };
    const btnEdit = userId === parseInt(userIdOfPost)
    return (
      <Grid container className="post-preview">
        <Grid container>
          <Grid xs={12} sm={9} md={9}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={`MuiContainer-root ${!isMobile && 'fixed-container'}`}>
                <Box mt={10} >
                  <Typography
                    gutterBottom
                    variant="caption"
                    component="div"
                    className="text-secondary"
                  >
                    {createdAt && (
                      <strong>
                        Created on {getLocalDateFormate(createdAt)}
                      </strong>
                    )}
                  </Typography>
                </Box>

                <Typography variant="h4" component="span">
                  <strong>{postTitle && unescape(postTitle)}</strong>
                </Typography>
                
                  <React.Fragment>
                    <Box display="flex" alignItems="center" flexWrap="wrap">
                      {isUserLoggedIn && <Box>
                        {toggleLike ? (
                        <IconButton
                          aria-label="like"
                          size="medium"
                          onClick={() => handleLikes(false)}
                        >
                          <img
                            src={Iconsimport["like-fill.svg"]}
                            width="20px"
                            alt="like"
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="like"
                          size="medium"
                          onClick={() => handleLikes(true)}
                        >
                          <img
                            src={Iconsimport["like-line.svg"]}
                            width="20px"
                            alt="like"
                          />
                        </IconButton>
                      )}
                        </Box>}
                      <Typography
                        component="span"
                        variant="subtitle1"
                        color="inherit"
                      >
                        {likeCount} Likes |
                      </Typography>
                      {isUserLoggedIn && <Box>
                      {toggleDislike ? (
                        <IconButton
                          aria-label="like"
                          size="medium"
                          onClick={() => handleDislike(false)}
                        >
                          <img
                            src={Iconsimport["dislike-fill.svg"]}
                            width="20px"
                            alt="dislike"
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="like"
                          size="medium"
                          onClick={() => handleDislike(true)}
                        >
                          <img
                            src={Iconsimport["dislike.svg"]}
                            width="20px"
                            alt="dislike"
                          />
                        </IconButton>
                      )}
                      </Box>}
                      <Typography
                        component="span"
                        variant="subtitle1"
                        color="inherit"
                      >
                        &nbsp; {dislikeCount} Dislike | 
                      </Typography>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        color="inherit"
                      >
                        &nbsp; {postViews} views |
                      </Typography>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        color="inherit"
                      >
                        &nbsp; {tagName} | &nbsp;
                      </Typography>
                      {isUserLoggedIn ? 
                       <Typography component="span" variant="subtitle1">
                       <Link className="user" to={`/profile/${userIdOfPost}`}>
                         &nbsp;{postUserName}&nbsp;
                       </Link>
                     </Typography>:postUserName}
                     
                      {/* <Button color="primary" variant="text">
                      Follow
                    </Button> */}
                    {isUserLoggedIn && <Box>
                      {state?.from === "/feedback" ? null : (
                        <Button
                          color="primary"
                          size="large"
                          startIcon={
                            BookmarToggle ? (
                              <BookmarkIcon fontSize="large" />
                            ) : (
                              <BookmarkBorderIcon fontSize="large" />
                            )
                          }
                          onClick={BookmarToggle ? dltBookmark : handleBookmark}
                        >
                          {BookmarToggle ? "Bookmarked" : "Bookmark"}
                        </Button>
                      )}
                      {userId !== userIdOfPost && (
                        <Button
                          color="primary"
                          size="large"
                          startIcon={<ReportOutlined fontSize="large" />}
                          onClick={modalOpen}
                        >
                          Report
                        </Button>
                      )}
                      </Box>}
                      {btnEdit && (
                        <Link
                          to={{
                            pathname: "/addpost",
                            state: {
                              isPostEdiatble: true,
                              postId: postId,
                              isfromUserFlow: true,
                            },
                          }}
                          style={{ textDecoration: "none" }}
                        >
                          <Button
                            color="primary"
                            size="large"
                            startIcon={<Edit fontSize="large" />}
                          >
                            Edit Post
                          </Button>
                        </Link>
                      )}
                
                      <CustomModal
                        title="Report User"
                        open={open}
                        modalSubmit={(e) => handleReportPost("Other comment")}
                        modalClose={modalClose}
                        btnConfirmHide={!otherOtionEnable}
                        btnCloseHide={!otherOtionEnable}
                      >
                        {!otherOtionEnable && (
                          <List component="nav" aria-label="" dense={true}>
                            {staticReportOption.map((elem) => (
                              <ListItem
                                button
                                key={elem}
                                onClick={() => handleReportPost(elem)}
                              >
                                <ListItemText primary={elem} />
                              </ListItem>
                            ))}
                          </List>
                        )}
                        {otherOtionEnable && (
                          <TextField
                            id="outlined-multiline-static"
                            label="Other Comment"
                            multiline
                            rows={4}
                            onChange={(e) =>
                              setOtherOptionValue(e.target.value)
                            }
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </CustomModal>
                    </Box>
                  </React.Fragment>
              </div>
              <div className={!isMobile && "margin-top-14"}>
                <Container maxWidth="lg">
                  <Box className="ql-editor">
                    <div ref={postPreview}></div>
                  </Box>
                </Container>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {/* <Divider /> */}
              {renderPostComments()}
            </Grid>
          </Grid>
          <Grid xs={12} sm={3} md={3}>
            {state?.isAdmin && (
              <LogAndCommentSection
                btnDelete={state.btnDelete}
                btnVerify={state.btnVerify}
                btnUnverify={state.btnUnverify}
                postId={postId}
                userId={userId}
              />
            )}
            {/* {attachments.length >= 1 &&
            <Card className={hideSpace && 'margin-top-14'} variant="outlined">
              <CardContent>
                Download Attachment
                {attachments?.map((item)=>(
                  <Box mt={1} key={item.id}>
                  <Card variant="outlined">
                    <Box p={2}>
                    {item.fileName}
                    &nbsp;({item.size})
                    </Box>
                  </Card>
                  </Box>
                ))}
              </CardContent>
            </Card>
            } */}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  // comment ui render
  const renderPostComments = () => {
    const classes = userCommentStyle();
    const { count, users } = (userComments && userComments) || [];

    // post user comment
    const handleComment = async (replayCommentId = "") => {
      // add comment json schema
      const pushComment = {
        commentData:
          replayCommentId === ""
            ? escape(parentComment.parentComment.trim())
            : escape(parentComment.childComment.trim()),
        commentId: replayCommentId,
        postId: postId,
        userAvatar: profilePic,
        userId: userId.toString(),
        userName: firstName + " " + lastName,
      };
      setParentComment({ isDisable: true });
      setParentComment({
        parentComment: "",
        isDisable: false,
        childComment: "",
      });
      await dispatch(addUserComment(pushComment));

      await dispatch(getUserComments(postId));
      setEnableReply(0);
    };

    // Delete comment
    const deleteComment = async (commentId) => {
      await dispatch(commentDelete(commentId, userId));
      await dispatch(getUserComments(postId));
    };

    // cancel or reply button click handler
    const handleReplyOrCancel = (commentId = '') =>{
      commentId?setEnableReply(commentId):setEnableReply(0)
      setParentComment((pre)=>({...pre, childComment: ""}))
    }

    return (
      <Container>
        <Box
          p={4}
          pt={0}
          pb={6}
          mt={3}
          component="span"
          className="comment-section"
        >
          <Typography variant="h6" component="span">
            <strong>{count !== 0 && count && count + " Comments"}</strong>
          </Typography>
          {isUserLoggedIn && (
            <List className={classes.root}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar component="span">
                  <Avatar alt="profile pic" src={profilePic} />
                </ListItemAvatar>
                <TextField
                  id="standard-textarea"
                  label="Add a comment…."
                  multiline
                  fullWidth
                  inputProps={{ maxLength: 3000 }}
                  value={parentComment.parentComment}
                  onChange={(e) =>
                    setParentComment({ parentComment: e.target.value })
                  }
                />
                <Box component="div">
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={parentComment.isDisable}
                    onClick={() => handleComment()}
                  >
                    Comment
                  </Button>
                </Box>
              </ListItem>
            </List>
          )}
          {users?.map((comment) => (
            <List className={classes.root} key={comment.id} component="span">
              <ListItem alignItems="flex-start" component="span">
                <ListItemAvatar component="span">
                  <Link to={`/profile/${comment.userId}`}>
                    <Avatar alt="profile pic" src={comment.userAvatar} />
                  </Link>
                </ListItemAvatar>
                <ListItemText
                  component="span"
                  primary={
                    <React.Fragment>
                      <Link to={`/profile/${comment.userId}`}>
                        {comment.userName} &nbsp;
                      </Link>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textSecondary"
                      >
                        {ephocDateCovert(comment.createTime)}
                      </Typography>
                    </React.Fragment>
                  }
                  secondary={
                    // nested comments starts
                    <React.Fragment>
                      <Box>{unescape(comment.commentData)}</Box>
                      {/* <Divider /> */}
                      {isUserLoggedIn && (
                        <Box textAlign="right" component="div">
                          {userId === parseInt(comment.userId) && !isAdmin ? (
                            <Box textAlign="right" component="span">
                              {userId === parseInt(comment.userId) && (
                                <Button
                                  variant="text"
                                  onClick={() => {
                                    deleteComment(comment.commentId);
                                  }}
                                >
                                  Delete
                                </Button>
                              )}
                            </Box>
                          ) : (
                            <Box textAlign="right" component="span">
                              <Button
                                variant="text"
                                color="primary"
                                onClick={() => {
                                  handleReplyOrCancel(comment.commentId)
                                }}
                              >
                                Reply
                              </Button>
                              {isAdmin && (
                                <Button
                                  variant="text"
                                  onClick={() => {
                                    deleteComment(comment.commentId);
                                  }}
                                >
                                  Delete
                                </Button>
                              )}
                            </Box>
                          )}
                        </Box>
                      )}
                      {/* Replay comment */}
                      {isUserLoggedIn && isEnableReplay === comment.commentId && (
                        <React.Fragment>
                          <TextField
                            id="textarea"
                            label="Add a comment…."
                            value={parentComment.childComment}
                            multiline
                            autoFocus
                            fullWidth
                            inputProps={{ maxLength: 3000 }}
                            onChange={(e) =>
                              setParentComment({ childComment: e.target.value })
                            }
                          />
                          <Box textAlign="right" component="div" mt={3}>
                            <Button
                              variant="contained"
                              onClick={() => setEnableReply() }
                            >
                              Cancel
                            </Button>
                            &nbsp;
                            <Button
                              variant="contained"
                              color="secondary"
                              disabled={parentComment.isDisable}
                              onClick={() => handleComment(comment.commentId)}
                            >
                              Reply
                            </Button>
                          </Box>
                        </React.Fragment>
                      )}
                      {comment.users?.map((nestedComment) => (
                        <React.Fragment key={nestedComment.commentId}>
                          <List className={classes.root} component="span">
                            <ListItem alignItems="flex-start" component="span">
                              <ListItemAvatar component="span">
                                <Link to={`/profile/${nestedComment.userId}`}>
                                  {" "}
                                  <Avatar
                                    alt="profile pic"
                                    component="span"
                                    src={nestedComment.userAvatar}
                                  />
                                </Link>
                              </ListItemAvatar>
                              <ListItemText
                                component={"span"}
                                primary={
                                  <React.Fragment>
                                    <Link
                                      to={`/profile/${nestedComment.userId}`}
                                    >
                                      {" "}
                                      {nestedComment.userName} &nbsp;
                                    </Link>
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      className={classes.inline}
                                      color="textSecondary"
                                    >
                                      {ephocDateCovert(
                                        nestedComment.createTime
                                      )}
                                    </Typography>
                                  </React.Fragment>
                                }
                                secondary={unescape(nestedComment.commentData)}
                              />
                            </ListItem>
                            {(isAdmin ||
                              userId === parseInt(nestedComment.userId)) && (
                              <Box textAlign="right" component="div">
                                <Button
                                  variant="text"
                                  onClick={() => {
                                    deleteComment(nestedComment.commentId);
                                  }}
                                >
                                  Delete
                                </Button>
                              </Box>
                            )}
                          </List>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          ))}
        </Box>
      </Container>
    );
  };

  return <React.Fragment>{renderPostContents()}</React.Fragment>;
};
