import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { AddPost } from "components/elements/addPost";
import { PocoCard, TittleCard } from "components/elements/cards";
import { ephocDateCovert, getRole, Role } from "core/utils";
import { ShowMore } from "components/elements/showMore";
// Matrial element
import {
  Grid,
  Box,
  Container,
  Typography,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";

import { deletPost, getAllposts } from "redux/actions/allPostAction";
import "./index.scss";
import { showMorePostsAction } from "redux/actions/globalAction";

export default function FeedbackPage() {
  const [value, setValue] = useState(0);

  const { showMorePosts } = useSelector((state) => state.global);
  const { showFeedbackPost } = showMorePosts;

  // const [postCount, setpostCount] = useState(10);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  const { isValidApiToken } = useSelector((state) => state.apiAccess);

  // Destructure login user to differentiate b/w login user and profile viewer
  const { userProfile } = useSelector((state) => state.userProfile);
  const { userInfo: loginUser } = userProfile ? userProfile.userProfile : {};
  const { userId } = loginUser || 0;
  const { role } = loginUser || "";

  // is Admin
  const isAdmin = Role.ADMIN === getRole(role);

  useEffect(() => {
    if (isValidApiToken) {
      dispatch(getAllposts(1, showFeedbackPost, 0, "Suggestion", userId));
      dispatch(getAllposts(1, showFeedbackPost, 0, "Feedback", userId));
    }
  }, [dispatch, isValidApiToken, showFeedbackPost, userId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const postCountUpdate = () => {
    dispatch(showMorePostsAction({ showFeedbackPost: showFeedbackPost + 10 }));
    // setpostCount(postCount + 10);
  };

  // gobal store selctor
  const { suggesionPost, feedbackPost } = useSelector((state) => state.allPost);
  const { tagData, totalCount } = suggesionPost || [];
  const { tagData: feedbackPostList } = feedbackPost || [];

  // Delete post for admin
  const postAction = async (postId, category) => {
    await dispatch(deletPost(postId, userId));
    switch (category) {
      case "Feedback":
        dispatch(getAllposts(1, showFeedbackPost, 0, category, userId));
        break;
      case "Suggestion":
        dispatch(getAllposts(1, showFeedbackPost, 0, category, userId));
        break;
      default:
    }
  };

  // Suggesion category
  const renderSuggesionCategory = () => {
    return (
      <React.Fragment>
        {tagData?.map((items) => (
          <Grid item xs={12} sm={6} md={12} key={items.id}>
            <PocoCard
              Img={items.postThumbnail}
              Tittle={items.postTitle}
              postid={items.id}
              ProfilePic={items.postAvatar}
              UserName={items.postUserName}
              postCreatedOn={ephocDateCovert(items.createdAt)}
              tag={items.tagName}
              view={items.postViews}
              like={items.postLikes}
              profileId={items.userId}
              isBookmarked={items.isBookmarked}
              isUserliked={items.isUserLiked}
              action={{
                isdeletebtnEnable: isAdmin,
                postAction: () => postAction(items.id, items.category),
              }}
            />
          </Grid>
        ))}
      </React.Fragment>
    );
  };

  const renderFeedbackCategory = () => {
    return (
      <React.Fragment>
        {feedbackPostList?.map((items) => (
          <Grid item xs={12} sm={6} md={12} key={items.id}>
            <PocoCard
              Img={items.postThumbnail}
              Tittle={items.postTitle}
              postid={items.id}
              ProfilePic={items.postAvatar}
              UserName={items.postUserName}
              postCreatedOn={ephocDateCovert(items.createdAt)}
              tag={items.tagName}
              view={items.postViews}
              like={items.postLikes}
              profileId={items.userId}
              isBookmarked={items.isBookmarked}
              isUserliked={items.isUserLiked}
              action={{
                isdeletebtnEnable: isAdmin,
                postAction: () => postAction(items.id, items.category),
              }}
            />
          </Grid>
        ))}
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <Container maxWidth="lg" className="software-feedback-section">
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Box mt={12} mb={4}>
              <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="tabs">
                  <Tab label="Feature Suggestion" {...a11yProps(0)} />
                  <Tab label="Report Bug & Feedback" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <TittleCard
                  title="Feature Suggestion"
                  path="/suggestion"
                  btnName="Create"
                />
                <Grid container spacing={3}>
                  {renderSuggesionCategory()}
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box m={4}>
                      <ShowMore
                        data={tagData}
                        totalCount={totalCount}
                        propFunction={postCountUpdate}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <TittleCard
                  title="Report Bug & Feedback"
                  path="/reportbug"
                  btnName="Create"
                />
                <Grid container spacing={3}>
                  {renderFeedbackCategory()}
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box m={4}>
                      <ShowMore
                        data={tagData}
                        totalCount={totalCount}
                        propFunction={postCountUpdate}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
            {/* {renderNaviagteButtons()} */}
          </Grid>
          {/* <Grid item xs={12} sm={10} md={4}>
            <Box mt={12}>
              <AddPost />
            </Box>
          </Grid> */}
        </Grid>
      </Container>
    </React.Fragment>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
