import * as actions from "redux/constant/types";

const intialState = {
  isValidApiToken: false,
};

// Home reducer start
export const apiAccess = (state = intialState, action) => {
    switch (action.type) {
        case actions.ACCESS_TOKEN_VERIFY:
          return {
            isValidApiToken: action.payload
          };
        default:
          return state;
    }
};
