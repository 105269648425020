import * as actions from "redux/constant/types";
import { toastOpen, isPageLoad } from "redux/actions/globalAction";
import { getCookies, $axios } from "core/utils";
import { getLoggedInuser } from "redux/actions/userAction";
import { getEncrypt } from "core/utils";

//  user infromation action start
export const userProfileLoad = () => ({
  type: actions.USER_PROFILE_LOAD,
});

export const userProfileSuccess = (res) => ({
  type: actions.USER_PROFILE_SUCCESS,
  payload: res,
});

export const userOtherProfileSuccess = (res) => ({
  type: actions.USER_OTHER_PROFILE_SUCCESS,
  payload: res,
});

// user prfile details
export const getUserProfile = (userId = 0) => {
  return async (dispatch) => {
    dispatch(userProfileLoad());
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = await getCookies();
    const { apiToken, userId } = cookies ? cookies : "";
    const daaEncryp = userId + "&" + userId;

    await $axios
      .get(`/api/poco/user/profile/userId/${getEncrypt(daaEncryp)}`, {
        headers: { Authorization: `Bearer ${apiToken}`,"JSessionID": userId },
      })
      .then((res) => {
        if (res) {
          dispatch(userProfileSuccess(res.data));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(getLoggedInuser(false));
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(getLoggedInuser(false));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};
//  same api with diffrent state for profile view
export const getOtherUserProfile = (otherUser = 0, loginUserId = 0) => {
  return async (dispatch) => {
    dispatch(userProfileLoad());
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = await getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";
    // fist value will be other user and login id
    const daaEncryp = otherUser + "&" + loginUserId;

    await $axios
      .get(`/api/poco/user/profile/userId/${getEncrypt(daaEncryp)}`, {
        headers: { Authorization: `Bearer ${apiToken}`, "JSessionID": userId },
      })
      .then((res) => {
        if (res) {
          dispatch(userOtherProfileSuccess(res.data));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(getLoggedInuser(false));
        if (err.response) {
          dispatch(getLoggedInuser(false));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
          if (err.response.data.status === 500) {
            let url = window.location.origin;
            url = url + "/notfound";
            window.location.href = url;
          } else {
            dispatch(
              toastOpen({
                isToastOpen: true,
                toastMessage: err.response.data.message,
                toastVariant: "error",
              })
            );
          }
        }
      });
  };
};

//  trending users action start
export const rakingUsersLoad = () => ({
  type: actions.RANKING_USERS_LOAD,
});

export const rakingUsersSuccess = (res) => ({
  type: actions.RANKING_USERS_SUCCESS,
  payload: res,
});

export const getTopRakingUsers = (userId = 0) => {
  return async (dispatch) => {
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId:loginId = 0 } = cookies ? cookies : "";
    const dataEncrypt = userId + '&' + userId
    await $axios
      .get(`/api/poco/v1/post/trending/user?userId=${getEncrypt(dataEncrypt)}`, {
        headers: { Authorization: `Bearer ${apiToken}`, "JSessionID": loginId },
      })
      .then((res) => {
        if (res) {
          dispatch(rakingUsersSuccess(res.data));
        }
      })
      .catch((err) => {
        dispatch(
          toastOpen({
            isToastOpen: true,
            toastMessage: err.message,
            toastVariant: "error",
          })
        );
      });
  };
};

//  User setting related action start

export const updateUserProfile = (userId = 0, userProfileData = {}) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId:loginId = 0 } = cookies ? cookies : "";
    // userId is a login user Id
    const daaEncryp = userId + "&" + userId;
    await $axios
      .put(
        `/api/poco/user/profile/update/${getEncrypt(daaEncryp)}`,
        getEncrypt(JSON.stringify(userProfileData)),
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
            "Content-Type": "text/json",
            "JSessionID": loginId
          },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
          dispatch(getUserProfile(userId));
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: "Profile Updated",
              toastVariant: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

//  user profile and cover image upload action
export const updateUserPic = (userId = 0, uploadedImg, imageType = "") => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId:loginId = 0 } = cookies ? cookies : "";

    // Image upload for user profile based on image type profilePic / coverImage
    let formData = new FormData();
    formData.append("file", uploadedImg);
    formData.append("type", imageType);
    const daaEncryp = userId + "&" + userId;
    await $axios
      .post(
        `/api/poco/user/profile/upload/${getEncrypt(daaEncryp)}`,
        formData,
        {
          headers: { Authorization: `Bearer ${apiToken}`, "JSessionID": loginId  },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
          dispatch(getUserProfile(userId));
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: "Profile Updated",
              toastVariant: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};
export const deleteUserPic = (userId = 0, imgCategory = "") => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId:loginId = 0 } = cookies ? cookies : "";
    const daaEncryp = userId + "&" + userId;
    await $axios
      .delete(
        `/api/poco/user/profile/remove/image/${getEncrypt(
          daaEncryp
        )}?type=${imgCategory}`,
        {
          headers: { Authorization: `Bearer ${apiToken}`, "JSessionID": loginId  },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
          dispatch(getUserProfile(userId));
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: "Successfully Deleted",
              toastVariant: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

export const getStateAndCityByPincode = async(pincode = 0,callback,dispatch) => {
  //  method will get token to access api
  const cookies = getCookies();
  const { apiToken, userId:loginId = 0 } = cookies ? cookies : "";
  dispatch(isPageLoad({ isPageLoaderOpen: true }));
  // userId is a login user Id
  // const daaEncryp = userId + "&" + userId;
  await $axios
    .get(
      `/api/poco/user/basic/getPinCodeMapping?pinCode=${pincode}`,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "Content-Type": "text/json",
          "JSessionID": loginId
        },
      }
    )
    .then((res) => {
      dispatch(isPageLoad({ isPageLoaderOpen: false }));
      callback(res.data)
    })
    .catch((err) => {
      dispatch(isPageLoad({ isPageLoaderOpen: false }));
    });
};