import React from "react";
import { Drawer } from "@material-ui/core";

export function HoverMenu(props) {
  const {hover,close, anchor} = props
  return (
    <React.Fragment>
      <Drawer
        anchor={anchor}
        open={hover}
        onMouseLeave={close}
        className="poco-community-drawer"
        transitionDuration= {800}
      >
        {props.children}
      </Drawer>
    </React.Fragment>
  );
}
