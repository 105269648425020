import React, { useEffect } from "react";
import { Grid, Container, Box } from "@material-ui/core";

export const Aboutus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Container maxWidth="lg" >
        <Grid container >
          <Grid item>
            <Box mt={4} mb={4} p={2}  >
              <div className="abouttext">
                <h3>About Us - POCO India</h3>
                <p>
                  POCO is a consumer technology brand on a mission to deliver
                  the highest quality products to its fans and consumers. Ranked
                  as India’s third largest online smartphone brand, POCO
                  approaches every product with a philosophy of 'Everything you
                  need and nothing you don't’ and aims to fulfil POCO
                  enthusiast’ requirements to each extent. POCO is also the
                  fastest growing smartphone brand in 2021, as per IDC Worldwide
                  Quarterly Mobile Phone Tracker, May 2021.
                </p>
                <p>
                  With a zeal to inspire gen Z, POCO aspires to challenge the
                  status quo and represents every individual who is looking to
                  pursue perfection and greater alternatives to the mainstream.
                  Building on the madness and eccentricity of Gen Z, POCO
                  imbibes the ‘Made of Mad’ communication tonality not afraid of
                  any challenges. POCO strongly believes in building on the
                  relentless pursuit of technology, democratizing decision
                  making to ensure the highest levels of user satisfaction,
                  through its cutting edge devices.
                </p>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <div className="clearboth">&nbsp;</div>
    </React.Fragment>
  );
};
