import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Tranding } from "../TabComponents/trending";
import { Feature } from "../TabComponents/feature";
import { Allpost } from "../TabComponents/allPost";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function tabProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    top: -4 + "rem",
  },
}));

export default function NavTabs(props) {
  const classes = useStyles();
  const [value, setValue] = useState(2);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const {  userRole } = props;

  // useEffect(() => {
  //   tabIndex && setValue(parseInt(tabIndex));
  // }, [tabIndex]);

  const { userProfile } = useSelector((state) => state.userProfile);
  const { userInfo: profileInfo } = userProfile ? userProfile.userProfile : {};
  const { userId } = profileInfo || "";

  const renderTabComponents = () => {
    return (
      <React.Fragment>
        <AppBar position="static" color="default">
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="nav tabs"
          >
            <LinkTab label="Trending" {...tabProps()} />
            <LinkTab label="Feature" {...tabProps()} />
            <LinkTab label="All Post" {...tabProps()} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} className="community-home-page">
          <Tranding userId={userId} />
        </TabPanel>
        <TabPanel value={value} index={1} className="community-home-page">
          <Feature userId={userId} />
        </TabPanel>
        <TabPanel value={value} index={2} className="community-home-page">
          <Allpost userId={userId} userRole={userRole} />
        </TabPanel>
      </React.Fragment>
    );
  };

  return <div className={classes.root}>{renderTabComponents()}</div>;
}
