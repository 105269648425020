import React, { useEffect, useState } from "react";
import { AppBar, Box, Typography } from "@material-ui/core";
import { HoverMenu } from "components/elements/hoverMenu";
import { getLandscapeOrientation, Iconsimport } from "core/utils";
import { UserProfile } from "../userProfile";
import { useSelector } from "react-redux";
import { CustomLink } from "components/elements/customLink";


export default function MobileAppBar(props) {
  const [hoverMenu, setHoverMenu] = useState(false);
  const [hoverNav, setHoverNav] = useState(false);
  const { mobileNav } = props;

  useEffect(()=>{
    window.addEventListener("resize",function(){
      if(getLandscapeOrientation()){
        hoverAndNavClose()
      }
    })
  })

  // Hover menu close
  const hoverMenuClose = () => {
    setHoverMenu(false);
  };
  const hoverMenuOpen = () => {
    setHoverNav(false);
    setHoverMenu(!hoverMenu);
  };
  // Hover menu close
  const hoverNavClose = () => {
    setHoverNav(false);
  };
  const hoverNavOpen = () => {
    setHoverMenu(false);
    setHoverNav(!hoverNav);
  };
  const hoverAndNavClose=()=>{
    hoverMenuClose();
    hoverNavClose();
  }

  const { isLoggedinUser } = useSelector((state) => state.userProfile);

  const renderMobileAppBar = (
    <Box
      display={{
        xs: "block",
        sm: "none",
        md: "none",
        lg: "none",
        xl: "none",
      }}
    >
      {/* User profile for mobile view */}
      <HoverMenu anchor="bottom" hover={hoverMenu} close={hoverMenuClose}>
        <div className="user-land-height"> 
        <UserProfile {...props} />
        </div>
      </HoverMenu>
      {/* navigation for mobile view */}

      <HoverMenu anchor="bottom" hover={hoverNav} close={hoverNavClose}>
        {mobileNav}
      </HoverMenu>
      <AppBar
        position="fixed"
        color="primary"
        style={{ top: "auto", bottom: "0" }}
        className="poco-community-header"
      >
        <Box
          display="flex"
          p={2}
          justifyContent="space-around"
          alignItems="center"
          className="appbar-icons"
        >
          <Box textAlign="center" onClick={hoverAndNavClose}>
            <CustomLink loggedIn={isLoggedinUser} path={"/task"}>
              <img src={Iconsimport["tasks.svg"]} alt="taks" />
              <Typography variant="body2">Tasks</Typography>
            </CustomLink>
          </Box>
          <Box textAlign="center" onClick={hoverAndNavClose}>
            <CustomLink loggedIn={isLoggedinUser} path={"/calendar"}>
              <img src={Iconsimport["calendar.svg"]} alt="calendar" />
              <Typography variant="body2">Check In</Typography>
            </CustomLink>
          </Box>
          <Box textAlign="center" onClick={hoverAndNavClose}>
            <CustomLink loggedIn={isLoggedinUser} path={"/addpost"}>
              <img src={Iconsimport["post.svg"]} alt="post" />
              <Typography variant="body2">Post</Typography>
            </CustomLink>
          </Box>
          <Box textAlign="center" onClick={hoverMenuOpen}>
            <img src={Iconsimport["user.svg"]} alt="user" />
            <Typography variant="body2">User</Typography>
          </Box>
          <Box textAlign="center" onClick={hoverNavOpen}>
            <img src={Iconsimport["menu.svg"]} alt="menu" />
            <Typography variant="body2">Menu</Typography>
          </Box>
        </Box>
      </AppBar>
    </Box>
  );
  return <React.Fragment>{renderMobileAppBar}</React.Fragment>;
}
