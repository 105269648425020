import * as actions from "redux/constant/types";

const intialState = {
  allPostLoad: true,
  allPostList: {},
  featurePostList: {},
  newsPostList: undefined,
  eventPostList: undefined,
  photographyPostList: undefined,
  pocoXseries: undefined,
  pocoMseries: undefined,
  pocoCseries: undefined,
  pocoFseries: undefined,
  suggesionPost: undefined,
  feedbackPost: undefined,
  myPosts: {},
  techPosts: [],
  resourcesPost: []
};

// Home reducer start
export const allPostReducer = (state = intialState, action) => {
  switch (action.type) {
    case actions.ALLPOST_LOAD:
      return {
        ...state,
        allPostLoad: action.payload,
      };
    case actions.ALLPOST_SUCCESS:
      const { allPostList } = state;
      const { tagData } = action.payload;

      if (allPostList?.tagData) {
        let ids = allPostList.tagData.map((item) => item.id);
        let result = tagData.filter((item) => !ids.includes(item.id));
        if (result.length === 1) {
          allPostList.tagData.splice(0, 0, result);
          action.payload.tagData = allPostList.tagData.flat();
        } else {
          action.payload.tagData = allPostList.tagData.concat(result);
        }
      }
      return {
        ...state,
        allPostLoad: false,
        allPostList: action.payload,
      };
    case actions.FEATURE_SUCCESS:
      const { featurePostList } = state;
      const { tagData: List } = action.payload;
      if (featurePostList?.tagData) {
        let ids = featurePostList.tagData.map((item) => item.id);
        let result = List.filter((item) => !ids.includes(item.id));
        action.payload.tagData = featurePostList.tagData.concat(result);
      }
      return {
        ...state,
        featurePostList: action.payload,
      };
      case actions.MY_POST_SUCCESS:
        return {
          ...state,
          allPostLoad: false,
          myPosts: action.payload,
          allPostList:{}
        };
    case actions.NEWS_POST_SUCCESS:
      return {
        ...state,
        newsPostList: action.payload,
      };
    case actions.EVENT_POST_SUCCESS:
      return {
        ...state,
        eventPostList: action.payload,
      };
    case actions.PHOTOGRAPHY_POST_SUCCESS:
      return {
        ...state,
        photographyPostList: action.payload,
      };
    case actions.POCOXSERIES_POST_SUCCESS:
      return {
        ...state,
        pocoXseries: action.payload,
      };
    case actions.POCOMSERIES_POST_SUCCESS:
      return {
        ...state,
        pocoMseries: action.payload,
      };
    case actions.POCOCSERIES_POST_SUCCESS:
      return {
        ...state,
        pocoCseries: action.payload,
      };
    case actions.POCOFSERIES_POST_SUCCESS:
      return {
        ...state,
        pocoFseries: action.payload,
      };
    case actions.SUGGESION_POST_SUCCESS:
      return {
        ...state,
        suggesionPost: action.payload,
      };
    case actions.FEEDBACK_POST_SUCCESS:
      return {
        ...state,
        feedbackPost: action.payload,
      };
      case actions.TECH_POST_SUCCESS:
        return {
          ...state,
          techPosts: action.payload,
        };
      case actions.RESOURCES_POST_SUCCESS:
        return {
          ...state,
          resourcesPost: action.payload,
        };
    default:
      return state;
  }
};
