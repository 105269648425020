import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import {
  Card,
  Avatar,
  Typography,
  CardMedia,
  CardContent,
  CardActions,
  CardActionArea,
  Box,
  Tooltip,
  IconButton,
} from "@material-ui/core";

import "./index.scss";
import CustomModal from "../customModal";

export function MyyDraftCard(props) {
  const {
    Img,
    Tittle,
    ProfilePic,
    UserName,
    postCreatedOn,
    tag,
    view,
    like,
    height,
    isDark,
    postid,
    profileId,
    isBanner = false,
    action = {
      isdeletebtnEnable: false,
      isEditEnable: false,
      postAction: () => { },
    },
  } = props;


  const [open,setOpen] = useState(false);

  const cardsStyle = makeStyles((theme) => ({
    root: {},
    media: {
      height: height || 170,
      [theme.breakpoints.up("sm")]: {
        height: height || 200,
      },
    },
  }));

  const classes = cardsStyle();

  const userAction = like !== undefined || view !== undefined;
  const cardConent = Tittle !== undefined;
  const cardAction =
    ProfilePic !== undefined ||
    UserName !== undefined ||
    postCreatedOn !== undefined ||
    tag !== undefined ||
    isBanner !== undefined;

  // post actions
  const handleAction = async (e) => {
    e.preventDefault();
    action.postAction();
    setOpen(false)
  };
  // passing current location
  const location = useLocation();


  return (
    <Card
      className={`${isDark && isDark ? "my-draft-card-dark" : "my-draft-card"} ${classes.root
        }`}
    >
      {isBanner ? (
        <CardActionArea>
          <CardMedia
            className={`${classes.media} poco-banners`}
            component="img"
            alt="post thumbnail"
            src={Img}
          />
        </CardActionArea>
      ) : (
        <React.Fragment>
          <Link
            to={{
              pathname: `/addPost`,
              state: { isPostEdiatble: true, postId: postid, isfromUserFlow: true, isfromDraft: true }
            }}
          >
            <CardActionArea>
              {userAction && (
              <CardMedia
                className={classes.media}
                component="img"
                alt="post thumbnail"
                src={Img}
              />)}
              {cardConent && (
                <CardContent>
                  <Typography gutterBottom variant="h6">
                    {unescape(Tittle).length > 80 ? unescape(Tittle).substring(0, 80) + '...' : unescape(Tittle)}
                  </Typography>
                </CardContent>
              )}
            </CardActionArea>
          </Link>
          {cardAction && (
            <CardActions>
              <Link to={`/profile/${profileId}`}>
                <Avatar src={ProfilePic} component="span" />
              </Link>
              <Box flexGrow={1} p={1} alignItems="center" component="span">
                <Link to={`/profile/${profileId}`}>
                  <Typography
                    gutterBottom
                    variant="body1"
                    component="span"
                    className="text-secondary"
                    display="block"
                  >
                    {UserName}
                  </Typography>
                </Link>
                <Link
                  to={{
                    pathname: `/addpost`,
                    state: { isPostEdiatble: false, postId: postid, isfromUserFlow: true, isfromDraft: true }
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="body1"
                    component="span"
                    className="text-secondary"
                  >
                    {postCreatedOn}
                  </Typography>
                </Link>
              </Box>
              <Link
                to={{
                  pathname: `/post/${postid}`,
                  state: { from: location.pathname, postId:postid },
                }}
              >
                <Box p={1} component="span">
                  <Typography
                    gutterBottom
                    variant="h6"
                    className="text-secondary"
                    component="span"
                  >
                    <strong>{tag}</strong>
                  </Typography>
                </Box>
              </Link>
            </CardActions>
          )}
          {Tittle !== undefined && action.isEditEnable && (
            <Link
              to={{
                pathname: "/addpost",
                state: { isPostEdiatble: false, postId: postid, isfromUserFlow: true, isfromDraft: true },
              }}
            >
              <Tooltip title="Edit" placement="top" arrow>
                <IconButton
                  aria-label="edit"
                  size="small"
                  className="edit-post"
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Link>
          )}
          {Tittle !== undefined && action.isdeletebtnEnable && (
            <Tooltip title="Delete" placement="top" arrow>
              <IconButton
                aria-label="delete"
                onClick={(e) => setOpen(true)}
                size="small"
                className="delete-post"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>

          )}
          <CustomModal
            title="Are you sure you want to delete this post?"
            open={open}
            modalSubmit={(e) => handleAction(e) }
            modalClose={e => setOpen(false)}
            btnConfirmHide={false}
            btnCloseHide={false}
            btnConfirm = "Delete"
            btnClose = "Cancel"
          />
        </React.Fragment>
      )}
    </Card>
  );
}
