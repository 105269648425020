import * as actions from "redux/constant/types";
import { toastOpen, isPageLoad } from "./globalAction";
import { getCookies, $axios, getEncrypt } from "core/utils";

export const postLoad = (payload) => ({
  type: actions.ALLPOST_LOAD,
  payload,
});

export const postSuccess = (res) => ({
  type: actions.ALLPOST_SUCCESS,
  payload: res,
});

export const featureSuccess = (res) => ({
  type: actions.FEATURE_SUCCESS,
  payload: res,
});

export const newsSuccess = (res) => ({
  type: actions.NEWS_POST_SUCCESS,
  payload: res,
});

export const eventSuccess = (res) => ({
  type: actions.EVENT_POST_SUCCESS,
  payload: res,
});

export const photographySuccess = (res) => ({
  type: actions.PHOTOGRAPHY_POST_SUCCESS,
  payload: res,
});

export const pocoXseriesSuccess = (res) => ({
  type: actions.POCOXSERIES_POST_SUCCESS,
  payload: res,
});

export const pocoMseriesSuccess = (res) => ({
  type: actions.POCOMSERIES_POST_SUCCESS,
  payload: res,
});

export const pocoCseriesSuccess = (res) => ({
  type: actions.POCOCSERIES_POST_SUCCESS,
  payload: res,
});

export const pocoFseriesSuccess = (res) => ({
  type: actions.POCOFSERIES_POST_SUCCESS,
  payload: res,
});

export const suggestionsSuccess = (res) => ({
  type: actions.SUGGESION_POST_SUCCESS,
  payload: res,
});

export const feedbackSuccess = (res) => ({
  type: actions.FEEDBACK_POST_SUCCESS,
  payload: res,
});

export const myPostSuccess = (res) => ({
  type: actions.MY_POST_SUCCESS,
  payload: res,
});

export const resourcesSuccess = (res) => ({
  type: actions.RESOURCES_POST_SUCCESS,
  payload: res,
});

export const techSuccess = (res) => ({
  type: actions.TECH_POST_SUCCESS,
  payload: res,
});

export const getAllposts = (
  page = 1,
  size = 10,
  userId = 0,
  category = "all",
  // current user to get users like and bookmark
  current_userid = 0
) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId:loignId = 0 } = cookies ? cookies : "";
    const dataEncrypt = userId + "&" + current_userid;

    await $axios
      .get(
        `/api/poco/v1/post/all?page=${page}&size=${size}&query=userId:eq:${getEncrypt(
          dataEncrypt
        )}&query=category:eq:${category}`,
        { headers: { Authorization: `Bearer ${apiToken}`, "JSessionID": loignId  } }
      )
      .then((res) => {
        if (res) {
          switch (category) {
            case "all":
              if (userId !== 0) {
                dispatch(myPostSuccess(res.data));
                dispatch(isPageLoad({ isPageLoaderOpen: false }));
              } else {
                dispatch(postSuccess(res.data));
                dispatch(isPageLoad({ isPageLoaderOpen: false }));
              }
              break;
            case "Feature":
              dispatch(featureSuccess(res.data));
              dispatch(isPageLoad({ isPageLoaderOpen: false }));
              break;
            case "News":
              dispatch(newsSuccess(res.data));
              dispatch(isPageLoad({ isPageLoaderOpen: false }));
              break;
            case "Events":
              dispatch(eventSuccess(res.data));
              dispatch(isPageLoad({ isPageLoaderOpen: false }));
              break;
            case "Photography":
              dispatch(photographySuccess(res.data));
              dispatch(isPageLoad({ isPageLoaderOpen: false }));
              break;
            case "POCO X Series":
              dispatch(pocoXseriesSuccess(res.data));
              dispatch(isPageLoad({ isPageLoaderOpen: false }));
              break;
            case "POCO M Series":
              dispatch(pocoMseriesSuccess(res.data));
              dispatch(isPageLoad({ isPageLoaderOpen: false }));
              break;
            case "POCO C Series":
              dispatch(pocoCseriesSuccess(res.data));
              dispatch(isPageLoad({ isPageLoaderOpen: false }));
              break;
            case "POCO F Series":
              dispatch(pocoFseriesSuccess(res.data));
              dispatch(isPageLoad({ isPageLoaderOpen: false }));
              break;
            case "Suggestion":
              dispatch(suggestionsSuccess(res.data));
              dispatch(isPageLoad({ isPageLoaderOpen: false }));
              break;
            case "Feedback":
              dispatch(feedbackSuccess(res.data));
              dispatch(isPageLoad({ isPageLoaderOpen: false }));
              break;
            case "Tech":
              dispatch(techSuccess(res.data));
              dispatch(isPageLoad({ isPageLoaderOpen: false }));
              break;
              case "Resources":
              dispatch(resourcesSuccess(res.data));
              dispatch(isPageLoad({ isPageLoaderOpen: false }));
              break;
            default:
              return dispatch(postSuccess(res.data));
          }
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};


export const deletPost = (postId = 0, userId = 0) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api

    const cookies = getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";
    const dataEncrypt = postId + "&" + userId;
    await $axios
      .delete(`/api/poco/v1/post/${getEncrypt(dataEncrypt)}`, {
        headers: { Authorization: `Bearer ${apiToken}`, "JSessionID": userId },
      })
      .then((res) => {
        if (res) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: "Post Deleted Successfully",
              toastVariant: "success",
            })
          );
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};
