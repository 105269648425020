import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  ephocDateCovert,
  debounce,
  getRole,
  Role,
  staticCategory,
} from "core/utils";
import {
  getcmsPostlist,
  PostSectionEnum,
} from "redux/actions/cmsPostManagement";
import { Paper, Button, Box, Popover } from "@material-ui/core";
import { tableStyle } from "core/theme/makeStyle";
import SearchFilter from "components/cmsAdmin/components/searchFilter";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

export default function DeletedPost(props) {
  const [value, setValue] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [imgeSrc, setImgeSrc] = useState("");

  const classes = tableStyle();

  const { userProfile = {} } = useSelector(
    (state) => state.userProfile?.userProfile || {}
  );

  const isModerator = Role.MODETATOR === getRole(userProfile?.userInfo?.role);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userProfile?.userInfo && value[0]?.category !== undefined) {
      dispatch(getcmsPostlist(null, 0, "", value, PostSectionEnum.DELETED));
    }
  }, [dispatch, userProfile, value]);

  const { cmsDeletedPostList: { tagData = [], totalCount = 0 } = {} } =
    useSelector((state) => state.cmsAdminUserModul);

  const rowCreate = (
    postName,
    postThumbnail,
    UserName,
    category,
    createdTime
  ) => {
    return {
      postName,
      postThumbnail,
      UserName,
      category,
      createdTime,
    };
  };

  const repotedColumns = [
    { id: "postName", label: "Post Name", minWidth: 170 },
    { id: "postThumbnail", label: "Post Thumbnail", minWidth: 100 },
    {
      id: "UserName",
      label: "Username",
      minWidth: 170,
      align: "left",
    },
    {
      id: "category",
      label: "Category",
      minWidth: 170,
      align: "left",
    },
    {
      id: "createdTime",
      label: "Post Created Time",
      minWidth: 170,
      align: "left",
    },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setImgeSrc(event.currentTarget.src);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getSearchValue = (searcValue) => {
    const getSearchValue = debounce(() =>
      dispatch(
        getcmsPostlist(null, 0, searcValue, value, PostSectionEnum.DELETED)
      )
    );
    if (searcValue.length > 1 || searcValue.length < 1) getSearchValue();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    const pageCount = newPage === 0 ? 1 : newPage + 1;
    dispatch(
      getcmsPostlist(null, 0, "", value, PostSectionEnum.DELETED, pageCount)
    );
    setPage(newPage);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const deletedData = tagData?.map((ele) =>
    rowCreate(
      <Link
        to={{
          pathname: `/admin/post/${ele.id}`,
          state: { isAdmin: true, btnDelete: false, postId: ele.id },
        }}
      >
        <Box width={150}>{unescape(ele.postTitle)}</Box>
      </Link>,
      <Link>
       <Box p={1}>
        <img
          src={ele.postThumbnail}
          width="50"
          alt={ele.postThumbnail}
          onClick={handleClick}
        />
      </Box>
      </Link>,
      ele.postUserName,
      ele.category,
      ephocDateCovert(ele.createdAt)
    )
  );

  const staticCategoryForAdmin = [];
  const moderatorCategory = [];
  const profileData = userProfile?.userInfo?.roleCategory || [];
  profileData.forEach((ele) => moderatorCategory.push([{ category: ele }]));
  staticCategory.forEach((ele) =>
    staticCategoryForAdmin.push([{ category: ele }])
  );

  const structureCategory = isModerator
    ? moderatorCategory.flat()
    : staticCategoryForAdmin.flat();

  useEffect(() => {
    setValue([structureCategory[0]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModerator]);

  return (
    <React.Fragment>
      <SearchFilter
        setSearchValue={(val) => getSearchValue(val)}
        filterValue={value}
        filtersetValue={setValue}
        data={structureCategory}
      />
      <Box mt={5}>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="a table" size="small">
              <TableHead>
                <TableRow>
                  {repotedColumns.map((column) => (
                    <TableCell key={column.id} align={column.align}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {deletedData.map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      {repotedColumns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
          />
        </Paper>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box display="Flex" flexDirection="column">
            <Box>
              <img
                src={imgeSrc}
                alt=""
                flexGrow={1}
                width="200"
                hegight="200"
              />
            </Box>
            <Button
              onClick={handleClose}
              color="primary"
              variant="contained"
              size="small"
            >
              Close
            </Button>
          </Box>
        </Popover>
      </Box>
    </React.Fragment>
  );
}
