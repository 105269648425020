import React, { useEffect, useState } from "react";
import {
  getMyDevices,
  removeMydevices,
  registerIMEINumber,
} from "redux/actions/userPageAction";
import { useDispatch, useSelector } from "react-redux";
import { userCommentStyle } from "core/theme/makeStyle";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import CustomModal from "components/elements/customModal";
import { Iconsimport, ephocDateCovert } from  'core/utils'

import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Button,
  Box,
  TextField,
  CardContent,
  Card
} from "@material-ui/core";
import "components/userpage/index.scss"

export default function MyDevices(props) {
  const { userId } = props;
  const [openModal, setOpenModal] = useState(false);
  const [imeiNumber, setImeiNumber] = useState(null);
  const classes = userCommentStyle();

  const dispatch = useDispatch();
  const { userDevices } = useSelector((state) => state.userDetails);
  const { isValidApiToken } = useSelector((state) => state.apiAccess);
  useEffect(() => {
    if (isValidApiToken) {
      dispatch(getMyDevices())
    }
  }, [dispatch,isValidApiToken]);
  
  const handleRemove = (imei1) => {
    dispatch(removeMydevices(imei1));
  };

  const submitValue = () => {
      if(imeiNumber){
          dispatch(registerIMEINumber(imeiNumber, userId));
          setOpenModal(false);
      }
  };
  

  const renderUserInformation = () => {
    return (
      <React.Fragment>
        <Typography variant="h6">
          <Box mb={2}>
            <strong>My Devices</strong>
          </Box>
        </Typography>
        <Grid container spacing={1} className="my-devices-container">
          <Grid item md={12}>
            {userDevices.length > 0 ? 
             <List className={classes.root}>
             {userDevices?.map((devices) => (
               <React.Fragment>
                 <ListItem>
                   <ListItemAvatar>
                     <Avatar>
                       <PhoneAndroidIcon />
                     </Avatar>
                   </ListItemAvatar>
                   <ListItemText
                     primary={devices.modelName}
                     secondary={`IMEI: ${devices.imei1}  Activation Date: ${ephocDateCovert(devices.activationDate)}`}
                   />
                   <Button
                     variant="outlined"
                     className="btn-spacing"
                     onClick={() => handleRemove(devices.imei1)}
                   >
                     Remove
                   </Button>
                 </ListItem>
                 <Divider variant="inset" component="li" />
               </React.Fragment>
             ))}
           </List> :
           <Box>
             <Card variant="outlined">
               <CardContent>
                 <Box display='flex' alignItems="center">
                 <img src={Iconsimport['no-device-ico.png']} width={60} alt=""/>
                 <Typography variant="body2">&nbsp; No Device Added</Typography>
                 </Box>
               </CardContent>
             </Card>
             <Box mt={3} mb={2}>
               <Typography variant="body2">Add your device to win 1000 points and avail exclusive benefits</Typography>
               </Box>
               </Box>
           }
           
          </Grid>
          <Grid item md={12}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                setOpenModal(true)
                setImeiNumber(null)
              }}
            >
              Add new device
            </Button>
          </Grid>
        </Grid>
        <CustomModal
          title="Register IMEI"
          open={openModal}
          modalClose={() => setOpenModal(false)}
          modalSubmit={submitValue}
        >
          <TextField
            value={imeiNumber}
            label="Enter IMEI Number"
            type="Number"
            variant="outlined"
            onChange={(e) => setImeiNumber(e.target.value)}
            onInput = {(e) =>{
              e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,15)
             }}
            fullWidth
          />
          <Box mt={1}>
            <Typography variant="caption">Note: Reward points are applicable once per IMEI.</Typography>
          </Box>
        </CustomModal>
      </React.Fragment>
    );
  };
  return <div className="my-post-container">{renderUserInformation()}</div>;
}
