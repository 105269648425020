import * as actions from "redux/constant/types";

const intialState = {
  userFollowersLoad: false,
  userFollowers: undefined,
  userFollowingLoad: false,
  userFollowing: undefined,
  userBookmarkedLoad: true,
  userBookmarkedPostList: undefined,
  userDevices: [],
  myDraftList: []
};

// User reducer start
export const userItemReducer = (state = intialState, action) => {
  switch (action.type) {
    case actions.USER_FOLLOWERS_LOAD:
      return {
        ...state,
        userFollowersLoad: action.payload,
      };
    case actions.USER_FOLLOWERS_SCCESS:
      return {
        ...state,
        userFollowersLoad: false,
        userFollowers: action.payload,
      };
      case actions.USER_FOLLOWING_LOAD:
        return {
          ...state,
          userFollowingLoad: action.payload,
        };
      case actions.USER_FOLLOWING_SCCESS:
        return {
          ...state,
          userFollowingLoad: false,
          userFollowing: action.payload,
        };
        case actions.USER_BOOKMARKED_SCCESS:
        return {
          ...state,
          userBookmarkedLoad: false,
          userBookmarkedPostList: action.payload,
        };
        case actions.MY_DEVICES_SUCCESS:
          const { userDevices } = state
          if(typeof action.payload === 'string'){
            let filterDevices = userDevices.filter((item)=> item.imei1 !== action.payload)
            action.payload = filterDevices;
          }
          return {
          ...state,
          userDevices: action.payload,
        };
        case actions.MY_DRAFT_SUCCESS:
          return {
            ...state,
            myDraftList: action.payload,
          };
    default:
      return state;
  }
};

