import React from 'react'
import {useSelector} from 'react-redux'
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { pageLoaderStyle } from "core/theme/makeStyle";

export default function Loader (props){
    const { isPageLoaderOpen } = useSelector(
      (state) => state.global
    );
    const classes = pageLoaderStyle();
    return (
        <Backdrop className={classes.backdrop} style={{"zIndex":"999","backgroundColor":"#000000c9"}} open={isPageLoaderOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>
    )
}