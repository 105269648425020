import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Container,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Box,
  Typography,
  CardActions,
  Card,
  CardContent,
  Breadcrumbs,
  Badge,
} from "@material-ui/core";
import {
  getDownloadCenter,
  postKudosCount,
} from "redux/actions/headerFooterAction";
import { BreadcrumbStyle } from "core/theme/makeStyle";
import SearchIcon from "@material-ui/icons/Search";
import GetAppIcon from "@material-ui/icons/GetApp";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import BookIcon from "@material-ui/icons/Book";
import Alert from "@material-ui/lab/Alert";
import { CustomLink } from "../customLink";

export const DownloadCenter = () => {
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  // redux store data
  const downloadCenterlist = useSelector(
    (state) => state.downloadCenter.downloadCenterList
  );
  //  get user id
  const { userProfile: { userProfile: { userId = 0 } = "" } = "", isLoggedinUser } =
    useSelector((state) => state.userProfile);

  const { isValidApiToken } = useSelector((state) => state.apiAccess);

  // Search filter by phone name
  const phoneNameFilter = downloadCenterlist
    ? downloadCenterlist.filter((filter) => {
        return (
          filter.phoneName.toLowerCase().indexOf(searchValue.toLowerCase()) !==
          -1
        );
      })
    : [];

  useEffect(() => {
    isValidApiToken && dispatch(getDownloadCenter(userId));
    window.scrollTo(0, 0);
  }, [dispatch, isValidApiToken, userId]);

  // Kudos methods
  const handleKudos = (cardId) => {
    dispatch(postKudosCount(cardId, userId));
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg" className="download-center-section">
        <Box mt={12} mb={2} textAlign="left">
          <Alert severity="info">
            The ROMs available on the Download Centre are custom built by
            developers for testing. Download and usage of these ROMs are at user
            discretion and risk, POCO will not be held responsible for any
            issues or damages caused. Refer to device specific groups in case of
            any issue/queries.
          </Alert>
        </Box>
        <Box mt={3} mb={2} textAlign="left">
          <Typography variant="h5" component="h2">
            Download Center
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment">Search</InputLabel>
              <OutlinedInput
                id="outlined-adornment"
                type="text"
                onChange={handleSearch}
                size="small"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle " edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
          </Grid>
          <Grid container item xs={12} sm={12} md={6} lg={6} justify="flex-end">
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/instruction">
                <BreadcrumbStyle
                  component="a"
                  label="Instruction"
                  icon={<BookIcon fontSize="inherit" />}
                />
              </Link>
              <Link to="/faq">
                <BreadcrumbStyle
                  component="a"
                  label="FAQ"
                  icon={<LiveHelpIcon fontSize="inherit" />}
                />
              </Link>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Box mb={5} mt={5}>
          <Grid container spacing={3}>
            {phoneNameFilter &&
              phoneNameFilter.map((list) => (
                <Grid item xs={12} sm={12} md={4} lg={4} key={list.id}>
                  <Card className="glitch-card">
                    <CardContent>
                      <Box height={100} overflow="visible">
                        <Typography variant="h6" component="h2">
                          {list.phoneName}
                        </Typography>
                        <Typography variant="body2" component="p">
                          {list.description}
                        </Typography>
                      </Box>
                    </CardContent>
                    <CardActions disableSpacing>
                      <Box width="100%">
                        <CustomLink
                          loggedIn={isLoggedinUser}
                          path={"/downloadcenter"}
                        >
                          <IconButton
                            size="medium"
                            color="secondary"
                            className="bubbly-button"
                            onClick={() => handleKudos(list.id)}
                            disabled={list.isThanks}
                          >
                            <Badge
                              color="default"
                              badgeContent={list.thanksCount}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                            >
                              <FavoriteIcon fontSize="inherit" />
                            </Badge>
                          </IconButton>
                        </CustomLink>
                      </Box>
                      <Box
                        width="100%"
                        justifyContent="flex-end"
                        textAlign="right"
                      >
                        <CustomLink>
                          <IconButton
                            href={list.downloadUrl}
                            download
                            size="medium"
                            color="secondary"
                          >
                            <GetAppIcon fontSize="inherit" />
                          </IconButton>
                        </CustomLink>
                      </Box>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
};
