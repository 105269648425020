import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// Material ui icons
import { Imagesimport } from "core/utils";
// Redux action
import { getUserProfile } from "redux/actions/userProfileAction";
import { getApitoken } from "redux/actions/apiTokenAction";
import { getLoggedInuser } from "redux/actions/userAction";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { getCookies, getRole, Role, isMobile } from "core/utils";
import CmsMobileAppBar from "../mobileFooter";

// Material ui embaded style
import { HeaderStyle } from "core/theme/makeStyle";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
//Component Css
import "./index.scss";

export default function AdminHeader(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = HeaderStyle();
  const dispatch = useDispatch();

  let history = useHistory();
  const { isValidApiToken } = useSelector((state) => state.apiAccess);

  useEffect(() => {
    dispatch(getApitoken());
  }, [dispatch]);

  useEffect(() => {
    if (isValidApiToken) {
      const cookies = getCookies();
      const { userId } = cookies;
      // Get user profle data
      userId !== undefined && dispatch(getUserProfile(userId));
    }
  }, [dispatch, isValidApiToken]);

  const { userInfo } =
    useSelector((state) => state.userProfile?.userProfile?.userProfile) || {};
  // //  Page redirection only admin and moderator users are allowed
  if (userInfo?.role) {
    const isAccessable =
      Role.ADMIN === getRole(userInfo?.role) ||
      Role.MODETATOR === getRole(userInfo?.role);
    isAccessable === false && history.push("/Home");
  }

  const clearToken = () => {
    document.cookie =
      // eslint-disable-next-line no-useless-concat
      "token" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    dispatch(getLoggedInuser(false));
    window.location.reload();
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <AppBar position="fixed" className="poco-admin-community-header">
      <Toolbar>
        <Box display="flex">
          <Typography className={classes.title} variant="h6" noWrap>
            <Link to="/admin">
              <img
                src={Imagesimport["logo.png"]}
                alt="logo"
                className="header-logo"
              />
            </Link>
          </Typography>
        </Box>
        {!isMobile && (
          <Box
            flexGrow={1}
            display="flex"
            justifyContent="start"
            className="content-space d-none-xs"
            textOverflow="ellipsis"
          >
            <Link to="/admin">
              <Typography variant="body2" color="inherit">
                Post Management
              </Typography>
            </Link>
            <Link to="/admin/user">
              <Typography variant="body2" color="inherit">
                User Management
              </Typography>
            </Link>
          </Box>
        )}
        <div className={classes.sectionDesktop}>
          <IconButton
            edge="end"
            color="inherit"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <PersonRoundedIcon />
          </IconButton>
          <Box display="block" ml={2}>
            <Typography variant="subtitle2" color="inherit">
              {userInfo?.firstName}&nbsp;{userInfo?.lastName}
            </Typography>
            <Typography variant="subtitle2" color="inherit">
              {userInfo?.role.substring(5)}
            </Typography>
          </Box>
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={clearToken}
        >
          <MenuItem onClick={clearToken}>Logout</MenuItem>
        </Menu>
      </Toolbar>
      <CmsMobileAppBar logOut={clearToken} />
    </AppBar>
  );
}
