import React, { useRef, useEffect } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
// Reusable eles
import { PocoCard } from "components/elements/cards";
import { useDispatch, useSelector } from "react-redux";
import { getAllposts } from "redux/actions/allPostAction";
import { ephocDateCovert } from "core/utils";
import { ShowMore } from "components/elements/showMore";
import { showMorePostsAction } from "redux/actions/globalAction";

export default function PocoCSeries(props) {
  const dispatch = useDispatch();
  const { isValidApiToken } = useSelector((state) => state.apiAccess);
  // const [postCount, setpostCount] = useState(10);

  const { showMorePosts } = useSelector((state) => state.global);
  const { pocoCseriesPost } = showMorePosts;

  useEffect(() => {
    if (isValidApiToken) {
      dispatch(getAllposts(1, pocoCseriesPost, 0, "POCO C Series"));
    }
  }, [dispatch, isValidApiToken, pocoCseriesPost]);

  // Get product object from redux state
  const { pocoCseries } = useSelector((state) => state.allPost);
  const { tagData, totalCount } = pocoCseries || [];
  const scrollView = useRef(null);

  const postCountUpdate = () => {
    // setpostCount(postCount + 10);
    dispatch(showMorePostsAction({ pocoCseriesPost: pocoCseriesPost + 10 }));
  };

  const renderProductSeriesPost = () => {
    return (
      <React.Fragment>
        <React.Fragment>
          <Box
            width="100%"
            p={3}
            pb={2}
            pt={9}
            srolltoview={scrollView}
            id="CSeries"
          >
            <Box
              mb={3}
              display={{ xs: "none", sm: "block", md: "block", lg: "block" }}
            >
              <PocoCard
                Img="https://awsind0-fusion.fds.api.xiaomi.com/india-it-poco-cms-bucket/poco-community-files/C-Series_PC.jpg?GalaxyAccessKeyId=5151729087601&Expires=9223372036854775807&Signature=jy/qnlWnxffWTDd68Bt/e82qgEI="
                height={300}
                isBanner={true}
              />
            </Box>
            <Box
              mb={3}
              display={{ xs: "block", sm: "none", md: "none", lg: "none" }}
            >
              <PocoCard
                Img="https://awsind0-fusion.fds.api.xiaomi.com/india-it-poco-cms-bucket/poco-community-files/C-Series_M.jpg?GalaxyAccessKeyId=5151729087601&Expires=9223372036854775807&Signature=jUzu0bVyBacJ1mPHF6ogn4RauRc="
                height={300}
                isBanner={true}
              />
            </Box>
            <Typography variant="h5" display="block">
              <strong>C Series</strong>
            </Typography>
          </Box>
          {tagData?.map((ele) => (
            <Grid item xs={12} sm={6} md={6} component="span" key={ele.id}>
              <PocoCard
                postid={ele.id}
                Img={ele.postThumbnail}
                Tittle={ele.postTitle}
                ProfilePic={ele.postAvatar}
                UserName={ele.postUserName}
                postCreatedOn={ephocDateCovert(ele.createdAt)}
                tag={ele.tagName}
                view={ele.postViews}
                like={ele.postLikes}
                profileId={ele.userId}
              />
            </Grid>
          ))}
        </React.Fragment>
        <ShowMore
          data={tagData}
          totalCount={totalCount}
          propFunction={postCountUpdate}
        />
      </React.Fragment>
    );
  };
  return renderProductSeriesPost();
}
