import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Button,
  Tab,
  Tabs,
  Box,
  AppBar,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Container } from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import {
  followUser,
  getFollowings,
  reportUser,
  unfollowUser,
} from "redux/actions/userPageAction";
import { getOtherUserProfile } from "redux/actions/userProfileAction";
import { isMobile, staticReportOption } from "core/utils";
import UserPosts from "./userProfileDetails/userAllpost";
import UserBookmarked from "./userProfileDetails/userBookmarked";
import CustomModal from "components/elements/customModal";
import Images from "static/images/fdsImages";
import "./index.scss";

function TabPanel(tabProps) {
  const { children, value, index, ...other } = tabProps;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Profile(props) {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [otherOtionEnable, setotherOptionEnable] = useState(false);
  const [otherOptionValue, setOtherOptionValue] = useState("");

  let history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Destructure login user to differentiate b/w login user and profile viewer
  const { userProfile } = useSelector((state) => state.userProfile);
  const { userInfo: loginUser } = userProfile ? userProfile.userProfile : {};
  const {
    userId: loginUserId,
    firstName,
    lastName,
    city,
    country,
    rewardPoints,
    followersCount,
    followingCount,
  } = loginUser || "";

  // Destructure profile viewer
  const { otherUserProfile } = useSelector((state) => state.userProfile);
  const { userInfo: profileInfo } = otherUserProfile
    ? otherUserProfile.userProfile
    : {};
  const {
    profilePic,
    coverImage,
    userId: viewerUserId,
    firstName: viewerfirstName,
    lastName: viewerlastName,
    city: viewercity,
    country: viewercountry,
    rewardPoints: viewerScore,
    followersCount: viewerFollwers,
    followingCount: viewerFollowing,
  } = profileInfo || "";

  //  select following user to hide button

  const { userFollowing } = useSelector((state) => state.userDetails);

  const dispatch = useDispatch();
  const pathNameUserId = history.location.pathname.substring(9);
  // differentiate b/w login user and profile viewer
  const isOtherUser = parseInt(loginUserId) !== parseInt(pathNameUserId);

  const { isValidApiToken } = useSelector((state) => state.apiAccess);

  useEffect(() => {
    if (isValidApiToken) {
      dispatch(getOtherUserProfile(pathNameUserId, loginUserId));
      dispatch(getFollowings(loginUserId));
    }
    if (loginUserId !== pathNameUserId) {
      setValue(0);
    }
    window.scrollTo(0, 0);
  }, [dispatch, isValidApiToken, loginUserId, pathNameUserId]);

  const renderUserProfile = () => {
    // user follow
    const handleFollow = () => {
      dispatch(followUser(loginUserId, viewerUserId));
      dispatch(getFollowings(loginUserId));
    };

    const handleUnfollow = () => {
      dispatch(unfollowUser(loginUserId, viewerUserId, 1));
      dispatch(getFollowings(loginUserId));
    };

    //  Report User on selected option
    const handleReportUser = (selectedOption) => {
      if (selectedOption === "Other") {
        setotherOptionEnable(true);
        return;
      }else if (selectedOption === "Other comment") {
        // console.log(otherOptionValue, pathNameUserId);
        otherOptionValue.trim() && dispatch(reportUser(pathNameUserId,otherOptionValue))
        otherOptionValue.trim() && modalClose();
        otherOptionValue.trim() && setOtherOptionValue("")
      } else if (selectedOption !== "Other") {
        // console.log(selectedOption, pathNameUserId);
        dispatch(reportUser(pathNameUserId,selectedOption))
        setOtherOptionValue("")
        modalClose();
      }
      
    };

    const modalOpen = () => {
      setOpen(true);
    };

    const modalClose = () => {
      setOpen(false);
      setotherOptionEnable(false);
    };

    // Based on isFollowingUser follow button will be enabled
    const isFollowingUser =
      userFollowing &&
      userFollowing.some((item) => item.userId === viewerUserId);
    // Default cover fix
    const defaultCover =
      coverImage?.indexOf("PocoCommunity_Default_CoverImage.png") > -1
        ? Images.pcDefaultCover
        : coverImage;
    const defaultMobilecover =
      coverImage?.indexOf("PocoCommunity_Default_CoverImage.png") > -1
        ? Images.mobileDefaultCover
        : coverImage;
    const updatedCover = isMobile ? defaultMobilecover : defaultCover;
    return (
      <React.Fragment>
        <Box
          mt={7}
          display="flex"
          component="span"
          alignItems="flex-end"
          justifyContent="center"
          className="profile-section"
          style={{
            backgroundImage: `url(${updatedCover})`,
            backgroundSize: "cover",
          }}
          height={200}
        >
          <Avatar src={profilePic} />
        </Box>
        <Box mt={10} display="flex" justifyContent="center">
          {loginUserId === undefined ||
          !isOtherUser ? null : !isFollowingUser ? (
            <React.Fragment>
              <Button variant="outlined" color="primary" onClick={handleFollow}>
                Follow
              </Button>
              &nbsp;
              <Button variant="outlined" color="primary" onClick={modalOpen}>
                Report
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleUnfollow}
              >
                Unfollow
              </Button>
              &nbsp;
              <Button variant="outlined" color="primary" onClick={modalOpen}>
                Report
              </Button>
            </React.Fragment>
          )}
        </Box>
        <CustomModal
          title="Report User"
          open={open}
          modalSubmit={(e)=>handleReportUser("Other comment")}
          modalClose={modalClose}
          btnConfirmHide={!otherOtionEnable}
          btnCloseHide={!otherOtionEnable}
        >
          {!otherOtionEnable && (
            <List component="nav" aria-label="" dense={true}>
              {staticReportOption.map((elem) => (
                <ListItem
                  button
                  key={elem}
                  onClick={() => handleReportUser(elem)}
                >
                  <ListItemText primary={elem} />
                </ListItem>
              ))}
            </List>
          )}
          {otherOtionEnable && (
            <TextField
              id="outlined-multiline-static"
              label="Other Comment"
              multiline
              rows={4}
              onChange={(e) => setOtherOptionValue(e.target.value)}
              variant="outlined"
              fullWidth
            />
          )}
        </CustomModal>
      </React.Fragment>
    );
  };
  const profileuserName = () => {
    return (
      <React.Fragment>
        {!isOtherUser ? (
          <React.Fragment>
            <Box display="flex" justifyContent="center" textAlign="center">
              <Typography variant="h6">
                <strong>
                  {firstName}&nbsp;{lastName}
                </strong>
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography variant="subtitle2">{city} ,&nbsp; </Typography>
              <Typography variant="subtitle2"> {country} </Typography>
            </Box>
            <Box display="flex" justifyContent="center" mt={1}>
              <Typography variant="subtitle2">
                {"Score " + rewardPoints} | &nbsp;
              </Typography>
              <Typography className="text-black" variant="subtitle2">
              <Link to={{
                  pathname: `/settings/${loginUserId}`,
                  state: { activeTab: 4 }
                }}> 
                {followersCount + " Followers | "}
              </Link>
              </Typography>
              <Typography className="text-black" variant="subtitle2">
              <Link to={{
                  pathname: `/settings/${loginUserId}`,
                  state: { activeTab: 3 }
                }}>  
                &nbsp; {followingCount + " Following"}
              </Link>
              </Typography>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box display="flex" justifyContent="center" mt={3} textAlign="center">
              <Typography variant="h6">
                <strong>
                  {viewerfirstName}&nbsp;{viewerlastName}
                </strong>
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              {viewercity && (
                <Typography variant="subtitle2">{viewercity}, </Typography>
              )}
              {viewercountry && (
                <Typography variant="subtitle2"> {viewercountry} </Typography>
              )}
            </Box>
            <Box display="flex" justifyContent="center" mt={1}>
              {viewerScore !== undefined && (
                <Typography variant="subtitle2">
                  {"Score " + viewerScore} | &nbsp;
                </Typography>
              )}
              {viewerFollwers !== undefined && (
                <Typography variant="subtitle2">
                  {viewerFollwers + " Followers | "}
                </Typography>
              )}
              {viewerFollowing !== undefined && (
                <Typography variant="subtitle2">
                  &nbsp; {viewerFollowing} Following
                </Typography>
              )}
            </Box>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      {renderUserProfile()}
      <Container maxWidth="xl">
        {profileuserName()}
        <Box mt={2} className="user-tab">
          <AppBar position="relative" color="transparent" elevation={0}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="tabs"
              indicatorColor="primary"
              variant="standard"
              scrollButtons="auto"
              centered
            >
              <Tab label="All" {...tabProps(0)} disableRipple />
              {!isOtherUser && (
                <Tab label="Bookmarked" {...tabProps(1)} disableRipple />
              )}
              {/* {!isOtherUser && (
                <Tab label="Recently Activity" {...tabProps(2)} disableRipple />
              )} */}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <UserPosts userId={viewerUserId} isOtherUser={isOtherUser} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UserBookmarked userId={loginUserId} isOtherUser={isOtherUser} />
          </TabPanel>
          {/* <TabPanel value={value} index={2}>
            Item Three
          </TabPanel> */}
        </Box>
      </Container>
    </React.Fragment>
  );
}
