import * as actions from "redux/constant/types";

const intialState = {
  allcalenderLoad: false,
  allcalender: undefined,
};

// Home reducer start
export const calenderReducer = (state = intialState, action) => {
  switch (action.type) {
    case actions.ALLCALENDER_ITEMS_LOAD:
      return {
        ...state,
        allcalenderLoad: true,
      };

    case actions.ALLCALENDER_ITEMS_SUCCESS:
      return {
        ...state,
        allcalenderLoad: false,
        allcalender: action.payload,
      };
    default:
      return state;
  }
};
