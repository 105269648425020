import React from "react";
import { Link } from "react-router-dom";
import { Link as Anchor } from "@material-ui/core";

export function CustomLink(props) {
  const { loggedIn, path, isfromUserFlow } = props;

  const clientToken = process.env.REACT_APP_CLIENT_SOCIAL_LOGIN_TOKEN;
  const handleRedirection = (event) => {
    event.preventDefault();
    window.open(
      `${process.env.REACT_APP_SSO_URL}?callbackUrl=${window.location.href}&clientToken=${clientToken}`,
      "_self"
    );
  };
  return (
    <React.Fragment>
      {loggedIn ? (
        <Link to={{ pathname: path, state: { isfromUserFlow } }}>
          {props.children}
        </Link>
      ) : (
        <Anchor href="#" onClick={handleRedirection} alt="">
          {props.children}
        </Anchor>
      )}
    </React.Fragment>
  );
}
