import React, { useEffect } from "react";
// Redux selector
// Matrial element
import { Grid, Box } from "@material-ui/core";
// reusable elements
import { AddPost } from "components/elements/addPost";
// child component
import News from "./discoverList/news";
import Events from "./discoverList/events";
import Photography from "./discoverList/photography";
import Technologies from "./discoverList/tech";
import Resources from "./discoverList/resources";

import "./index.scss";

export default function DiscoverPage() {
  // const scrollView = useRef(null);

  useEffect(() => {
    // window.scrollTo(0, 0);
    // get url product url id to scroll into a section
    let url = window.location.pathname;
    let productSeriesId = url.substring(url.lastIndexOf("/") + 1);
    const productScroll = document.getElementById(productSeriesId);
    setTimeout(() => {
      productScroll &&
        productScroll.scrollIntoView({
          top: 100,
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      });

    // productScroll.style.marginTop = "6rem"
  });
  return (
    <div className="product-page">
      <Box mt={10} mb={2} p={3}>
        <Grid container component="span" spacing={5}>
          <Grid item xs={12} sm={12} md={8} component="span">
            <Grid container spacing={5} component="span">
              <News />
              <Events />
              <Photography />
              <Technologies />
              <Resources />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} component="span">
            <Box mt={5}>
              <AddPost />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
