import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function CustomModal(props) {
  const {
    title = "Title",
    btnConfirm = "Submit",
    btnClose = "Close",
    open = false,
    modalClose,
    btnConfirmHide = false,
    btnCloseHide = false,
    modalSubmit,
  } = props;
  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={modalClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title"> {title}</DialogTitle>
        <DialogContent>{props.children}</DialogContent>
        <DialogActions>
          {!btnConfirmHide && (
            <Button onClick={modalSubmit} variant="contained" color="secondary">
              {btnConfirm}
            </Button>
          )}
          {!btnCloseHide && (
            <Button onClick={modalClose} variant="contained" color="primary">
              {btnClose}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
