import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PocoCard } from "components/elements/cards";
import { AddPost } from "components/elements/addPost";
import { Grid } from "@material-ui/core";
import { ephocDateCovert } from "core/utils";
import { getAllposts } from "redux/actions/allPostAction";
import { ShowMore } from "components/elements/showMore";
import { deletPost } from "redux/actions/allPostAction";
import { showMorePostsAction } from "redux/actions/globalAction";

export const Allpost = (props) => {
  const { userId } = props;

  const { showMorePosts } = useSelector((state) => state.global);
  const { showAllPost } = showMorePosts;

  // const [postCount, setpostCount] = useState(10);

  const { isValidApiToken } = useSelector((state) => state.apiAccess);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isValidApiToken) {
      dispatch(getAllposts(showAllPost, 10, 0, "all", userId));
    }
  }, [dispatch, isValidApiToken, showAllPost, userId]);

  // gobal store selctor
  const { allPostList } = useSelector((state) => state.allPost);
  const { tagData, totalCount } = allPostList || [];

  const showMorePost = () => {
    dispatch(showMorePostsAction({ showAllPost: showAllPost + 1 }));
  };

  // delete post for admin
  const postAction = async (postId) => {
    await dispatch(deletPost(postId, userId));
    dispatch(getAllposts(1, showAllPost, 0, "all", userId));
  };

  const renderAllpost = () => {
    return (
      <React.Fragment>
        {tagData?.map((ele) => (
          <Grid item xs={12} sm={12} md={6} key={ele.id}>
            <PocoCard
              postid={ele.id}
              Img={ele.postThumbnail}
              Tittle={ele.postTitle}
              ProfilePic={ele.postAvatar}
              UserName={ele.postUserName}
              postCreatedOn={ephocDateCovert(ele.createdAt)}
              tag={ele.tagName}
              view={ele.postViews}
              like={ele.postLikes}
              profileId={ele.userId}
              isBookmarked={ele.isBookmarked}
              isUserliked={ele.isUserLiked}
              action={{
                postAction: () => postAction(ele.id),
              }}
            />
          </Grid>
        ))}
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8} md={8}>
          <Grid container spacing={3}>
            {renderAllpost()}
          </Grid>
          <ShowMore
            data={tagData}
            totalCount={totalCount}
            propFunction={showMorePost}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <AddPost />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
