import * as actions from "redux/constant/types";
import { toastOpen, isPageLoad } from "redux/actions/globalAction";
import { getCookies, $axios, getEncrypt } from "core/utils";

//  User page action start
export const allcalenderLoad = () => ({
  type: actions.ALLCALENDER_ITEMS_LOAD,
});

export const allcalenderSucces = (res) => ({
  type: actions.ALLCALENDER_ITEMS_SUCCESS,
  payload: res,
});

export const getallCalenderItems = (userId = 0) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";
    const dataEncrypt = userId + "&" + userId;
    await $axios
      .get(`api/poco/user/user-task/checkIn/${getEncrypt(dataEncrypt)}`, {
        headers: { Authorization: `Bearer ${apiToken}`, "JSessionID": userId  },
      })
      .then((res) => {
        if (res) {
          dispatch(allcalenderSucces(res.data));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        dispatch(
          toastOpen({
            isToastOpen: true,
            toastMessage: err.message,
            toastVariant: "error",
          })
        );
      });
  };
};

export const calendarCheckIn = (userId = 0) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId: loginId = 0 } = cookies ? cookies : "";
    const calendarData = {
      userId,
    };
    await $axios
      .post(
        `api/poco/user/user-task/checkIn`,
        getEncrypt(JSON.stringify(calendarData)),
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
             'content-type': 'text/json',
            "JSessionID": loginId
          },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(getallCalenderItems(userId));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: "Checked In",
              toastVariant: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err) {
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response?.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};
