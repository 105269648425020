
import * as actions from "redux/constant/types";

export const triggerToast = (res) => ({
  type: actions.TOAST_OPEN,
  payload: res,
});

export const toastOpen = (payload) => {
  return (dispatch) => {
    dispatch(triggerToast(payload));
  };
};


export const isPageLoader = (res) => ({
  type: actions.PAGE_LOAD,
  payload: res,
});

export const isPageLoad = (payload) => {
  return (dispatch) => {
    dispatch(isPageLoader(payload));
  };
};

export const showMorePostsAction=(res)=>{
  return {
    type : actions.SHOW_MORE_POSTS,
    payload : res
  }
}
