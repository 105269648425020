import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CreatePost, fileUpload } from "redux/actions/createPostAction";
import { toastOpen } from "redux/actions/globalAction";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import {
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  Grid,
  Container,
} from "@material-ui/core";

export default function ReportBug() {
  const [comment, setComment] = useState("");
  const [subject, setSubject] = useState("");
  const [bugReportFile, setbugReportFile] = useState("");
  const [attachFileName, setAttachFileName] = useState("");

  const dispatch = useDispatch();

  let history = useHistory();

  // Global data selector
  const { userProfile } = useSelector((state) => state.userProfile);
  const { userInfo: profileInfo } = userProfile ? userProfile.userProfile : {};
  const { profilePic, userId, firstName, lastName } = profileInfo || "";

  const uploadBugReport = async ({ target }) => {

    // file size should be less than 50 mb
    
    if (/^.*\.(jpg|JPG|JPEG|jpeg|png|PNG|zip|ZIP|rar|RAR|7ZIP|7zip)$/.test(target.files[0].name)) {
      if (target.files[0].size <= 52428800) {
        // get file name
        setAttachFileName(target.files[0].name);
        var file = target.files[0];
        const bugReport = await fileUpload(file);

        // upload file response adding to state
        setbugReportFile(bugReport ? bugReport[0]?.url : "");
      } else {
        dispatch(
          toastOpen({
            isToastOpen: true,
            toastMessage: "File size exceed!",
            toastVariant: "error",
          })
        )
      }

    } else {
      dispatch(
        toastOpen({
          isToastOpen: true,
          toastMessage: "Invalid file format!",
          toastVariant: "error",
        })
      )
    }
    
  };

  const handleFeedback = async () => {
    if (subject.trim() === "" || comment.trim() === "") {
      dispatch(
        toastOpen({
          isToastOpen: true,
          toastMessage: "Please provide a valid input",
          toastVariant: "error",
        })
      )
    } else {
      const feedbackData = {
        postThumbnail: "",
        postTitle: subject,
        postAvatar: profilePic,
        postUserName: firstName + lastName,
        tagName: "Feedback",
        postContent: comment,
        category: "Feedback",
        userId: userId,
        bugResource: bugReportFile,
      };

      await dispatch(CreatePost(feedbackData));
      history.push("/feedback");
    }
  };

  const attachment = attachFileName && (
    <Box display="inline">
      <AttachFileIcon fontSize="small" />
      {attachFileName}
    </Box>
  );
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container justify="center" className="fedback-page" spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Box bgcolor="text.primary" p={3} mt={11}>
              <Typography variant="h4" color="secondary">
                <strong>
                  Report Bug & <br />
                  Feedback
                </strong>
              </Typography>
            </Box>
            <Paper>
              <Box className="section-container" mb={3}>
                <form autoComplete="off">
                  <Box>
                    <Typography variant="subtitle2">
                      <strong>Subject</strong>
                    </Typography>
                    <TextField
                      id="Subject"
                      variant="outlined"
                      onChange={(e) => setSubject(e.target.value)}
                      fullWidth
                    />
                  </Box>
                  <Box mt={2}>
                    <Typography variant="subtitle2">
                      <strong>Comment</strong>
                    </Typography>
                    <TextField
                      id="Comment"
                      variant="outlined"
                      multiline
                      rows={4}
                      onChange={(e) => setComment(e.target.value)}
                      fullWidth
                    />
                  </Box>
                  <Box mt={2} className="feedback-upload">
                    <Typography variant="subtitle2">
                      <strong>Resource: Image or Zip max 50MB</strong>
                      <br></br>
                      <strong>Attachment: {attachment}</strong>
                    </Typography>
                    <input
                      accept="image/*,.zip,.rar,.7zip"
                      id="contained-button-file"
                      type="file"
                      value=""
                      style={{ display: "none" }}
                      onChange={(e) => uploadBugReport(e)}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        color="default"
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </Box>
                  <Box
                    mt={5}
                    width="100%"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleFeedback}
                    >
                      Send
                    </Button>
                  </Box>
                </form>
              </Box>
            </Paper>
          </Grid>
          {/* <Grid item xs={2} sm={4} md={4}>
            <Box mt={11}>
              <AddPost />
            </Box>
          </Grid> */}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
