import * as actions from "redux/constant/types";
import { getCookies, $axios, getEncrypt } from "core/utils";
import { toastOpen, isPageLoad } from "redux/actions/globalAction";

//  User page action start
export const postContentLoad = () => ({
  type: actions.ALLPOSTDETAILS_ITEMS_LOAD,
});

export const postContentSucces = (res) => ({
  type: actions.ALLPOSTDETAILS_ITEMS_SUCCESS,
  payload: res,
});

export const clearPostContent = (res) => ({
  type: actions.CLEAR_POSTDETAILS_CONTENT,
});

export const getAttachments =(res)=>({
  type: actions.GET_ATTACHMENTS,
  payload: res
})

// method to get post content
export const getpostDetails = (postId = 0, userId = 0) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    dispatch(postContentSucces());
    // cookies  will get token to access api
    const cookies = getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";
    const dataEncrypt = postId + "&" + userId;
    // current_userid param to get the user bookmared or not
    await $axios
      .get(`/api/poco/v1/post/${getEncrypt(dataEncrypt)}`, {
        headers: { Authorization: `Bearer ${apiToken}`, JSessionID: userId },
      })
      .then((res) => {
        if (res) {
          dispatch(postContentSucces(res.data));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        dispatch(postContentSucces());
        if (err.response) {
          if (err.response.data.status === 500) {
            let url = window.location.origin;
            url = url + "/notfound";
            window.location.href = url;
          } else {
            dispatch(
              toastOpen({
                isToastOpen: true,
                toastMessage: err.message,
                toastVariant: "error",
              })
            );
          }
        }
      });
  };
};

//  User page action start
export const userCommentLoad = () => ({
  type: actions.USER_COMMENTS_LOAD,
});

export const userCommentSuccess = (res) => ({
  type: actions.USER_COMMENTS_SUCCESS,
  payload: res,
});
// method to get user comments
export const getUserComments = (postId, userId = 0) => {
  return async (dispatch) => {
    // cookies  will get token to access api
    const cookies = getCookies();
    const { apiToken, userId: loginId = 0 } = cookies ? cookies : "";
    const dataEncrypt = postId + "&" + userId;
    dispatch(userCommentLoad());
    await $axios
      .get(
        `/xm/poco/community/api/v1/comments/all/${getEncrypt(dataEncrypt)}`,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
             'content-type': 'text/json',
            JSessionID: loginId,
          },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(userCommentSuccess(res.data));
        }
      })
      .catch((err) => {
        dispatch(
          toastOpen({
            isToastOpen: true,
            toastMessage: err.message,
            toastVariant: "error",
          })
        );
      });
  };
};

// method to add comment
export const addUserComment = (obj) => {
  return async (dispatch) => {
    // cookies  will get token to access api
    const cookies = getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";
    await $axios
      .post(
        `/xm/poco/community/api/v1/comment`,
        getEncrypt(JSON.stringify(obj)),
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
            "Content-Type": "text/json",
            JSessionID: userId,
          },
        }
      )
      .then((res) => {
        if (res) {
          // dispatch(userCommentSuccess(res.data));
        }
      })
      .catch((err) => {
        dispatch(
          toastOpen({
            isToastOpen: true,
            toastMessage: err.message,
            toastVariant: "error",
          })
        );
      });
  };
};

// Delete comment

export const commentDelete = (commentId = 0, userId = 0) => {
  return async (dispatch) => {
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId: loginId = 0 } = cookies ? cookies : "";
    const dataEncrypt = commentId + "&" + userId;
    await $axios
      .delete(`/xm/poco/community/api/v1/comment/${getEncrypt(dataEncrypt)}`, {
        headers: { Authorization: `Bearer ${apiToken}`, JSessionID: loginId },
      })
      .then((res) => {
        dispatch(
          toastOpen({
            isToastOpen: true,
            toastMessage: "Comment Deleted Successfully",
            toastVariant: "success",
          })
        );
      })
      .catch((err) => {
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

//  User like action start
export const likePost = (res) => ({
  type: actions.POST_LIKE_DISLIKE_COUNT_LOAD,
  payload: res,
});

export const likePostCount = (res) => ({
  type: actions.POST_LIKE_DISLIKE_COUNT_SUCCESS,
  payload: res,
});

// method to get all posts like dislike count
export const getAllLikeDislikeCount = (posId = 0, userId = 0) => {
  return async (dispatch) => {
    dispatch(likePost(true));
    // cookies  will get token to access api
    const cookies = getCookies();
    const { apiToken, userId: loginId = 0 } = cookies ? cookies : "";
    const dataEncrypt = posId + "&" + userId;
    await $axios
      .get(`/xm/poco/community/api/v1/like/status/${getEncrypt(dataEncrypt)}`, {
        headers: { Authorization: `Bearer ${apiToken}`, JSessionID: loginId },
      })
      .then((res) => {
        if (res) {
          dispatch(likePost(false));
          dispatch(likePostCount(res.data));
        }
      })
      .catch((err) => {
        dispatch(likePost(false));
        dispatch(
          toastOpen({
            isToastOpen: true,
            toastMessage: err.message,
            toastVariant: "error",
          })
        );
      });
  };
};

// method to like posts
export const postLike = (postLike) => {
  return async (dispatch) => {
    // cookies  will get token to access api
    const cookies = getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";
    const config = {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'content-type': 'text/json',
        JSessionID: userId,
      },
    };
    const data = getEncrypt(JSON.stringify(postLike));
    await $axios
      .post("/xm/poco/community/api/v1/like", data, config)
      .then((res) => {
        if (res) {
          // const { postId = 0, userId = 0 } = res.data;
          // dispatch(getAllLikeDislikeCount(postId, userId));
        }
      })
      .catch((err) => {
        dispatch(
          toastOpen({
            isToastOpen: true,
            toastMessage: err.message,
            toastVariant: "error",
          })
        );
      });
  };
};

// method to dislike posts
export const postDisLike = (postDisLikes) => {
  return async (dispatch) => {
    // cookies  will get token to access api
    const cookies = getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";
    await $axios
      .post(
        `/xm/poco/community/api/v1/dislike`,
        getEncrypt(JSON.stringify(postDisLikes)),
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
             'content-type': 'text/json',
            JSessionID: userId,
          },
        }
      )
      .then((res) => {
        if (res) {
          // console.log(res);
          // const { postId = 0, userId = 0 } = res.data;
          // dispatch(getAllLikeDislikeCount(postId, userId));
        }
      })
      .catch((err) => {
        dispatch(
          toastOpen({
            isToastOpen: true,
            toastMessage: err.message,
            toastVariant: "error",
          })
        );
      });
  };
};

//View
export const viewPostCount = (postId = 0, userId = 0) => {
  return async (dispatch) => {
    // cookies  will get token to access api
    const cookies = getCookies();
    const { apiToken, userId: loginId = 0 } = cookies ? cookies : "";
    const reqData = { postId, userId };
    await $axios
      .post(
        `/xm/poco/community/api/v1/view`,
        getEncrypt(JSON.stringify(reqData)),
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
             'content-type': 'text/json',
            JSessionID: loginId,
          },
        }
      )
      .then((res) => {
        if (res) {
        }
      })
      .catch((err) => {
        dispatch(
          toastOpen({
            isToastOpen: true,
            toastMessage: err.message,
            toastVariant: "error",
          })
        );
      });
  };
};

// get Post attachments
export const getPostAttachment = () => {
  return async (dispatch) => {
    dispatch(getAttachments([{'id':'1','fileName':'poco x2.pdf','size':'10 mb'},{'id':'2','fileName':'poco x2.ppt','size':'20 mb'}]))
    //  method will get token to access api
    // const cookies = getCookies();
    // const { apiToken, userId = 0 } = cookies ? cookies : "";
    // await $axios
    //   .post(``, {
    //     headers: {
    //       Authorization: `Bearer ${apiToken}`,
    //        'content-type': 'text/json',
    //       "JSessionID": userId 
    //     },
    //   })
    //   .then((response) => {
    //     if(response){
    //       dispatch(getAttachments())
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.response) {
    //      dispatch(
    //         toastOpen({
    //           isToastOpen: true,
    //           toastMessage: err.response.data.message,
    //           toastVariant: "error",
    //         })
    //       );
    //     }
    //   });

  };
};