import React from "react";
import { Typography, Container, Grid } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import UnverifedPost from "./unverifedPost";
import VerifedPost from "./verifiedPost";
import ReportedPost from "./reportedPost";
import DeletedPost from "./deletedPosts";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { handleIndexChange } from "redux/actions/cmsPostManagement";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    width: 100 + "%",
    [theme.breakpoints.down("xs")]: {
      marginTop: 56,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 56,
    },
    "@media (min-width: 1280px)": {
      marginTop: 69,
    },
  },
  appbar: {
    backgroundColor: "rgba(0, 0, 0, 0.9)",
  },
}));

export default function PostManagement() {
  const classes = useStyles();
  const theme = useTheme();
  // const [value, setValue] = useState(0);
  const {tabIndex} = useSelector(state => state.cmsAdminUserModul);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    dispatch(handleIndexChange(newValue));
  };

  const handleChangeIndex = (index) => {
    dispatch(handleIndexChange(index));
    // setValue(index);
  };

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} md={12}>
        <div className={classes.root}>
          <Paper
            position="static"
            color="default"
            className={classes.appbar}
            square
          >
            <Container>
              <Tabs
                value={tabIndex}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="secondary"
                aria-label="full width tabs example"
                disableRipple={true}
                disableFocusRipple={true}
              >
                <Tab label="Unverifed" {...a11yProps(0)} />
                <Tab label="Verified" {...a11yProps(1)} />
                <Tab label="Reported" {...a11yProps(2)} />
                <Tab label="Deleted" {...a11yProps(3)} />
              </Tabs>
            </Container>
          </Paper>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={tabIndex}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={tabIndex} index={0} dir={theme.direction}>
              <UnverifedPost />
            </TabPanel>
            <TabPanel value={tabIndex} index={1} dir={theme.direction}>
              <VerifedPost />
            </TabPanel>
            <TabPanel value={tabIndex} index={2} dir={theme.direction}>
              <ReportedPost />
            </TabPanel>
            <TabPanel value={tabIndex} index={3} dir={theme.direction}>
              <DeletedPost />
            </TabPanel>
          </SwipeableViews>
        </div>
      </Grid>
    </React.Fragment>
  );
}
