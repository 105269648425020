import * as actions from "redux/constant/types";


export const isLoggedInUser = (param) => ({
  type: actions.USER_IS_LOGGEDIN,
  payload: param,
});

export const getLoggedInuser = (param) =>{
  return async (dispatch) => {
    dispatch(isLoggedInUser(param));
  }
}