export default {
    'mobileDefaultCover':'https://awsind0-fusion.fds.api.xiaomi.com/india-it-poco-cms-bucket/poco-community-files/m_default_cover.png?GalaxyAccessKeyId=5151729087601&Expires=9223372036854775807&Signature=qSHuTSd9HrMw7POIvuiGU74blGU=',
    'thumbnailPlaceHolder': "https://awsind0-fusion.fds.api.xiaomi.com/india-it-poco-cms-bucket/poco-community-files/Default-Thumbnail.jpg?GalaxyAccessKeyId=5151729087601&Expires=9223372036854775807&Signature=9Am+YgYxAxqjcYBTIknjyk5ufNQ=",
    'pcDefaultCover':"https://awsind0-fusion.fds.api.xiaomi.com/india-it-poco-cms-bucket/PocoCommunity_Default_CoverImage.png?GalaxyAccessKeyId=5151729087601&Expires=9223372036854775807&Signature=Gg2TcMsUVr8KFL9oojVYYj6e650=",
    'eventBannerPc': "https://awsind0-fusion.fds.api.xiaomi.com/india-it-poco-cms-bucket/poco-community-files/Event-PC.jpg?GalaxyAccessKeyId=5151729087601&Expires=9223372036854775807&Signature=uUIHbmN3ETgDTGFyggsWF8ZmqAk=",
    'eventBannerMob': "https://awsind0-fusion.fds.api.xiaomi.com/india-it-poco-cms-bucket/poco-community-files/Event-M.jpg?GalaxyAccessKeyId=5151729087601&Expires=9223372036854775807&Signature=Lr0RzLhdkc9/a0l7J6DuGgHeJKQ=",
    'newsBannerPc': "https://awsind0-fusion.fds.api.xiaomi.com/india-it-poco-cms-bucket/poco-community-files/News-%20PC.jpg?GalaxyAccessKeyId=5151729087601&Expires=9223372036854775807&Signature=4Zl+EfYd8El62fP5idGv6a1vx98=",
    'newsBannerMob':"https://awsind0-fusion.fds.api.xiaomi.com/india-it-poco-cms-bucket/poco-community-files/News-M.jpg?GalaxyAccessKeyId=5151729087601&Expires=9223372036854775807&Signature=IxZe6LSyQAV8jIpIG5HapQ5+WB0=",
    'photographyPc': "https://awsind0-fusion.fds.api.xiaomi.com/india-it-poco-cms-bucket/poco-community-files/Photo-PC.jpg?GalaxyAccessKeyId=5151729087601&Expires=9223372036854775807&Signature=6eqI8os55v7zc/P8pTMrcdSnP44=",
    'photographyMob': "https://awsind0-fusion.fds.api.xiaomi.com/india-it-poco-cms-bucket/poco-community-files/Photography-M.jpg?GalaxyAccessKeyId=5151729087601&Expires=9223372036854775807&Signature=ldqkuUr1UmNlXt04JMirrmlTX1s=",
    'resourceBannerPc':"https://awsind0-fusion.fds.api.xiaomi.com/india-it-poco-cms-bucket/poco-community-files/resource-banner-PC.jpg?GalaxyAccessKeyId=5151729087601&Expires=9223372036854775807&Signature=FdEqFtWL1BErr5Z+PBwGVcwENRY=",
    'resourceBannerMob':"https://awsind0-fusion.fds.api.xiaomi.com/india-it-poco-cms-bucket/poco-community-files/ResourcesMobile.jpg?GalaxyAccessKeyId=5151729087601&Expires=9223372036854775807&Signature=ySEK8/MWULcQQUqWIakM/5tmlYs=",
    'techBannerPc': "https://awsind0-fusion.fds.api.xiaomi.com/india-it-poco-cms-bucket/poco-community-files/tech-banner-PC.png?GalaxyAccessKeyId=5151729087601&Expires=9223372036854775807&Signature=BJTycYh+YdWWmz9K6Z//iuP1QLM=",
    'techBannerMob': "https://awsind0-fusion.fds.api.xiaomi.com/india-it-poco-cms-bucket/poco-community-files/TechMobile.png?GalaxyAccessKeyId=5151729087601&Expires=9223372036854775807&Signature=6KlhEu/teH+BaUKolv/EofCI6MQ="
}