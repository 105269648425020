import * as actions from "redux/constant/types";

const intialState = {
  discoverItemsLoad: false,
  discoverItems: undefined
};

// Home reducer start
export const discoverItemReducer = (state = intialState, action) => {
  switch (action.type) {
    case actions.DISCOVER_ITEMS_LOAD:
      return {
        ...state,
        discoverItemsLoad: true,
      };

    case actions.DISCOVER_ITEMS_SUCCESS:
      return {
        ...state,
        discoverItemsLoad: false,
        discoverItems: action.payload,
      };
    default:
      return state;
  }
};
