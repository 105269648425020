import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Avatar,
  Button,
} from "@material-ui/core";
import { CustomLink } from "components/elements/customLink";
import { getLoggedInuser } from "redux/actions/userAction";
import { useSelector, useDispatch } from "react-redux";
import { followUser, unfollowUser } from "redux/actions/userPageAction";

export function UserProfile(props) {
  const { topRankingUsers, userProfile } = props;
  const { userInfo, activity, userId } = userProfile || {};
  const { isLoggedinUser } = useSelector((state) => state.userProfile);

  const dispatch = useDispatch();
  // let history = useHistory();
  // user prfoile information
  const renderProfile = () => {
    return (
      <Paper className="profile-card">
        {userInfo && (
          <Box display="flex" flexDirection="row">
            <Box flexGrow={1} pl={5} p={3} className="card-lhs">
              <Avatar src={userInfo.profilePic} alt="profile pic" />
              <Typography variant="subtitle1" color="secondary" mt={2}>
                <strong>{userInfo.pocoTitle}</strong>
              </Typography>
              <Typography
                variant="subtitle1"
                color="inherit"
                className="text-white elipsis"
              >
                <strong>{userInfo.firstName + " " + userInfo.lastName}</strong>
              </Typography>
            </Box>
            <Box flexGrow={1} p={4} className="card-rhs">
              {activity?.map((items, index) => (
                <React.Fragment key={index}>
                  <Typography variant="subtitle1">
                    <strong>{items.numberOfPost} Posted</strong>
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>
                      {items.numberOfTaskCompleted} Task Completed
                    </strong>
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong> {items.numberOfBookMark} Bookmarked</strong>
                  </Typography>
                </React.Fragment>
              ))}
            </Box>
          </Box>
        )}
      </Paper>
    );
  };
  const clearToken = () => {
    document.cookie =
      // eslint-disable-next-line no-useless-concat
      "token" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    document.cookie =
      // eslint-disable-next-line no-useless-concat
      "userId" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    dispatch(getLoggedInuser(false));
    window.location.reload();
  };

  //   Static profile menu
  const renderProfileMenu = () => {
    return (
      <React.Fragment>
        {isLoggedinUser ? (
          <Box
            display="flex"
            alignItems="flex-end"
            flexDirection="column"
            className="profile-navigation"
          >
            {userInfo && (
              <Typography variant="subtitle1" className="elipsis text-end ">
                {userInfo.firstName + " " + userInfo.lastName}
              </Typography>
            )}
            <Typography variant="h6">
              <Link to={`/profile/${userId}`}>
                <strong>Profile</strong>
              </Link>
            </Typography>
            <Typography variant="h6">
              <Link to={{
                  pathname: `/settings/${userId}`,
                  state: { activeTab: 0 }
                }}>
                <strong>Settings</strong>
              </Link>
            </Typography>
            <Typography variant="subtitle1">
              <Link onClick={clearToken}>Logout</Link>
            </Typography>
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="flex-end"
            flexDirection="column"
            className="profile-navigation"
          >
            <Typography variant="subtitle1">Welcome</Typography>
            <Typography variant="h6">
              <CustomLink loggedIn={isLoggedinUser} path="">
                {/* <strong>Sign up</strong> */}
              </CustomLink>
            </Typography>
            <Typography variant="subtitle1">
              <CustomLink loggedIn={isLoggedinUser} path="">
                Login
              </CustomLink>
            </Typography>
          </Box>
        )}
      </React.Fragment>
    );
  };
  // top raking user section
  const renderTopRankingUsers = () => {
    const handleRakingUserFollow = (e, followerId = 0) => {
      e.preventDefault();
      dispatch(followUser(userId, followerId));
    };
    const handleRakingUserUnFollow = (e, followerId = 0) => {
      e.preventDefault();
      dispatch(unfollowUser(userId, followerId, 1));
    };
    return (
      <div className="top-ranking-users-section">
        {topRankingUsers?.map((users) => (
          <Paper key={users.userId}>
            <Box display="flex" alignItems="center" mb={1}>
              <Box
                display="flex"
                flexGrow={1}
                p={1}
                className="users-lhs"
                alignItems="center"
              >
                <Typography variant="body1">#{users.ranking}</Typography>
                <Link to={`/profile/${users.userId}`}>
                  <Avatar src={users.profilePic} alt="trending user pic" />
                  <Typography variant="subtitle1" className="elipsis">
                    {users.firstName} {users.lastName && users.lastName}
                  </Typography>
                  {/* <Typography variant="subtitle1">{users.lastName}</Typography> */}
                </Link>
              </Box>
              <Box className="follow-rhs">
                {!users.isFollowed ? (
                  <Button
                    onClick={(e) => handleRakingUserFollow(e, users.userId)}
                  >
                    Follow
                  </Button>
                ) : (
                  userId !== users.userId && (
                    <Button
                      onClick={(e) => handleRakingUserUnFollow(e, users.userId)}
                    >
                      Unfollow
                    </Button>
                  )
                )}
              </Box>
            </Box>
          </Paper>
        ))}
      </div>
    );
  };
  return (
    <div className="">
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} md={12}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={10}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  {isLoggedinUser && renderTopRankingUsers()}
                </Grid>
                <Grid item xs={12} md={6}>
                  {isLoggedinUser && renderProfile()}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2}>
              {renderProfileMenu()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
