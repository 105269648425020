import React from "react";
import { Grid, Container, Box } from "@material-ui/core";
import { Imagesimport } from "core/utils";

export const FAQ = () => {
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={10} sm={12} md={12} lg={12}>
          <Grid item>
            <Box mt={12}>
              <div className="faq_section">
                <p>
                  So you guys may think that our device is very limited to what
                  we get in it, but what you guys don't know about it is, we can
                  do a lot to our devices by rooting and flashing custom roms
                  and tweaking it up to some extent. So now you guys are excited
                  about it? Let's flash what we need the best for our device.
                  What is a Custom Android ROM? A Custom Android ROM denotes a
                  phone’s firmware, grounded on Google’s Android platform.
                  Android is an open-source project so any developer can take
                  the code and can oversee it by tailoring according to his
                  predilection or as the way end-user wanted it to be, with many
                  add-ons, enhancements and skins and other mumbo-jumbo stuff
                  and then recompile it, and reproduce it for a varied
                  variability device. Users can install (flash – to be
                  technical) ROMs to amend the device's appearance and deeds.
                  ROMs are urbanized by the Android community, by a group of
                  core developers who do this morally out of lust towards
                  mod-ing. Hence you find Custom ROMs for free elsewhere. In my
                  familiarity, Custom ROMs are just as stable if not more than
                  OEM abounding ROMs, reliant on the ROM, of course, you have to
                  tolerate the bugs whether trivial or foremost if you find
                  them, or you can possibly report the bug to the developer for
                  fixing it. Generally, Android Tablets and Smartphones use
                  these ROMs to store the operating system (OS). But, in overall
                  deliberations and in the sequence of this article, ROM refers
                  to a custom-built OS.ROM has normally denoted nothing but your
                  Phone’s Whole Software (Software that contains all the
                  default/factory settings, apps, data). Stock ROM can provide
                  the user information but you can’t customize or make changes
                  to it until you install a Custom ROM.
                </p>
                <p className="purlple text-center">
                  <strong>
                    You might have come across the word Firmware which refers to
                    The applications and operating systems that govern how a
                    phone functions. It is called firmware rather than software
                    to acme that it is very meticulously tied to the precise
                    hardware apparatuses of a device
                  </strong>
                </p>
                <h4>
                  The following Custom ROMs are the most prevalent in the
                  Android Community
                </h4>
                <ul>
                  <li className="green">Lineage OS</li>
                  <li className="blue">Pixel Experience</li>
                  <li className="red">Resurrection Remix</li>
                  <li>Pixel Experience</li>
                  <li className="green">AOSP Extended</li>
                  <li className="blue">Mokee</li>
                </ul>
                <h4 className="green">
                  And the list goes on! You can read about each one briefly.
                </h4>
                <img src={Imagesimport["faq_ling.gif"]} alt="" />
                <img src={Imagesimport["faq1.gif"]} alt="" width="70%" />
                <p>
                  Lineage OS is a free, community manufactured, aftermarket
                  firmware dissemination of Android, which is premeditated to
                  upsurge performance and reliability over stock Android for
                  your device.
                  <span className="green">
                    {" "}
                    Lineage OS has very rudimentary{" "}
                  </span>{" "}
                  customization settings inherent like status bar settings where
                  we can amend the clock position and battery icon style and
                  things like that, and we have button settings also where we
                  can change the deed of the buttons or we can use on-screen
                  keys.
                  <span className="green">
                    Lineage OS have tremendous performance and massive
                    experience in day-to-day usage scenarios.
                  </span>{" "}
                  Lineage OS is based on the Android Open Source Project with
                  additional hand-outs from many people within the Android
                  community. It can be used without any need to have any Google
                  application installed.
                </p>
                <img src={Imagesimport["faq_(9).gif"]} alt="" />
                <img src={Imagesimport["faq_(4).gif"]} alt="" width="70%" />
                <p>
                  Pixel experience roms are made with all Google experience
                  including all google apps with excellent boot animation, and a
                  lot more customization, an excellent feature in Pixel Roms is
                  3D touch we can get some options for apps when we long-press
                  them for 1 or 2 seconds for example instead of opening the
                  contact app and create contact we can long-press on contact
                  app and we will get create contact option. Yes, you got me
                  right, I’m talking about that magnificent software inside.{" "}
                  <span className="blue">
                    As Pixel devices are made officially and maintained by
                    Google
                  </span>
                  , it has the Pure Android experience with few customizations
                  and add-ons which bring out great stability. The same story
                  repeats in your devices by flashing Pixel Experience ROM. The
                  Pixel Experience ROMs are baked with all the official Google
                  applications like Google Phone, Calendar, Messaging, and the
                  Google Camera port which improved the camera quality overall
                  with Google’s AI image processing tech. According to a recent
                  survey conducted, Pixel Experience ROM is proved to be users'
                  favorite Custom ROM.
                </p>
                <img src={Imagesimport["faq_(17).gif"]} alt="" />
                <img src={Imagesimport["faq _(10).gif"]} alt="" width="70%" />
                <p>
                  {" "}
                  Resurrection Remix ROM is eventually fully contained,
                  unwavering, and pooled with the best features of Open source
                  ROMs. Users get overwhelming Resurrection Remix ROM add-ons in
                  it, comprising best in the enactment, customization, and
                  power, and the newest features to your devices! Even with the
                  proportion of customization options and features, the ROM is
                  still battery-friendly, it is one of the most customizable
                  ROMs out there! The Resurrection Remix ROM is open source and
                  tremendous build updates are available. Everyone can
                  contribute towards its development and still there is no
                  competition to this ROM if the customization part is
                  concerned.
                </p>
                <img src={Imagesimport["faq_(16).gif"]} alt="" />
                <img src={Imagesimport["faq_(3).gif"]} alt="" width="70%" />
                <p>
                  AOSP is an Android open source project, and it refers to
                  unmodified ROMs and codes from Google. The name custom ROMs
                  are very close to the original AOSP ROMs and they are fine to
                  get the things done but, yes there is always a but,{" "}
                  <span className="blue">
                    AOSP ROMs are much of vanilla sort.
                  </span>{" "}
                  Here comes the extended part! As the name itself suggests,
                  extending or maximizing the AOSP with a bucket ton of features
                  refers. AOSP Extended is an AOSP based ROM that provides stock
                  UX along with innumerable customization topographies along
                  with the Substratum support. The project has been made by
                  choosing several obligations from various other projects.
                </p>
                <img src={Imagesimport["faq_(2).gif"]} alt="" />
                <img src={Imagesimport["faq_(14).gif"]} alt="" width="70%" />
                <p>
                  {" "}
                  MoKee OpenSource is based on Google AOSP. they will update
                  their source code frequently to keep up with the latest
                  development, and not forgetting to include special features at
                  the same time. A small group of people from around the world
                  who are interested in Android launched this project on the
                  12th of December 2012, and all are committed to making this
                  ROM better and better. Like most contributors to open source
                  projects (Omni, LineageOs, Slim AOSPA, etc),
                  <span className="blue">
                    they are doing all these in their free time as their
                    passion. At the same time,{" "}
                  </span>{" "}
                  we can have some basic customization in this Mokee Roms like
                  status bar settings and button settings, last but not least
                  Mokee provides daily nightly updates for the devices which are
                  having current development.
                </p>
                <h4>DOT Os</h4>
                <img src={Imagesimport["faq_(13).gif"]} alt="" width="70%" />
                <p>
                  DOT Os(DroidOnTime) - is a custom Android firmware. It was
                  launched with an aim to provide a Unique user interface and
                  Optimum performance that too keeping in mind the balance
                  between performance and battery life. dotOS is based on
                  Google's Android Open Source Project with Hand-picked goodies,
                  innovative ideas, and creative things which are added in the
                  room to enhance user experience!
                </p>
                <p>
                  Not just these, there are a lot more. A new project comes up
                  every month. I personally feel the above-listed ROMs stand out
                  among all of the ROMs. There are many show-stealers like
                  <span className="blue"> Syberia Os,</span> Viper OS, Havoc OS,
                  and Bootleggers and there are a lot more. Damn! Each and every
                  project comes up with different ideas and concepts that
                  delight user experience and never get boring.
                </p>
                <img src={Imagesimport["faq_(7).gif"]} alt="" className="align-center" />
                <img src={Imagesimport["faq_(11).gif"]} alt="" className="align-center"/>
                <p>
                  {" "}
                  <strong>Your Choice --</strong> Provisional on the development
                  of your device you are free to indicate from tons of ROMs
                  bestowing to your choice right from AOSP to Lineage OS,
                  Resurrection Remix to Xiaomi’s MIUI, Flyme OS and so on.2.
                  <br />
                  <br />
                  <strong>Bug Fixes and Updates – </strong>
                  ROM Developers generally provide timely Updates and Bug Fixes
                  if they are Active Developers in turn along with the user’s
                  interaction with them for Feedbacks.
                  <br />
                  <br />
                  <strong>Underclocking and Overclocking -- </strong>Certain
                  Custom ROMs allow Underclocking and Overclocking which helps
                  for increasing Battery Life and Device’s Performance
                  respectively. <br />
                  <br />
                  <strong>Customization --</strong> Tons of customization is
                  available in custom ROMs as per your need to make a way for a
                  different kind of look for your beloved custom rom.
                </p>
                <img src={Imagesimport["faq_(5).gif"]} alt="" className="align-center"/>
                <p>
                  <strong> Buggy ROM at times –</strong> The lack of Testers for
                  a particular Device can result in buggy and un-optimized ROMs
                  at first which can be disappointing if you are fresher.
                  <br />
                  <br />
                  <strong>Loss/Bricking of Phone –</strong> At times the files
                  can get corrupted while downloading or some issues while
                  flashing can lead to loss of Data along with Bricking of the
                  phone which can be fixed but at times is difficult.
                  <br />
                  <br />
                  <strong>Unlocked Bootloader – </strong> To Install a Custom
                  ROM you need to unlock your device bootloader by the official
                  way method if available.
                  <br />
                  <br />
                  <strong>Official Support –</strong>
                  There is no Official Support for a Custom ROM from Carrier,
                  Vendor as well as Manufacturer. So you need to be careful and
                  responsible.
                </p>
                <img src={Imagesimport["faq_(12).gif"]} alt="" className="align-center"/>
                <p>
                  {" "}
                  If you're on XDA, you've probably already heard of kernels.
                  The awesome custom kernel offers a ton of features including
                  but not limited to, more battery life, a smoother experience,
                  exFAT or OTG support maybe, custom color controls, wake
                  gestures, etc. So,{" "}
                  <span className="red">
                    {" "}
                    what exactly are custom kernels, and where did the entire
                    picture of a kernel come into Android?{" "}
                  </span>
                  The kernel is the piece of software that bridges the gap
                  between the operating system (and installed apps) and the
                  hardware in the device. Everything you are doing on your
                  device involves the kernel. Increasing the volume while
                  playing a video etc. Android doesn’t directly tell the
                  speakers to extend output. Instead, it tells the kernel that
                  it wants to increase the volume, and the kernel talks to the
                  speaker to increase its output. The kernel, in this regard, is
                  extremely customizable. Not solely will the manufacturer have
                  to connect the required drivers to urge all of the hardware to
                  figure properly, however, they square measure loads of
                  variables that they need to set. As you can see, the kernel
                  comes at the second tier either way. This means that if there
                  was any interaction between the operating system and the
                  hardware (like a volume increase trigger), or between the
                  hardware and the operating system (like a USB device
                  insertion), the kernel is the middleman. So, the next time you
                  increase the volume on your Android device, what actually
                  happens is that Android sends a request to the kernel stating
                  that the volume needs to be increased, and since the kernel
                  handles the hardware, it increases the voltage for the speaker
                  (which loosely translates to sending a 'volume increase'
                  request to the speaker); or the time when you plug in a USB
                  device in you OTG-enabled smartphone (or insert/remove a
                  memory card), the kernel tells the operating system that a USB
                  device has been plugged in (or a memory card inserted/removed)
                  and Android can do the necessary things like mounting the
                  SDCard, etc. We have some of the popular kernels for our
                  device like Franco kernel & Derp kernel, some others like Agni
                  kernel, Sphinx kernel, extreme revolt kernel, Kat kernel.
                </p>
                <img src={Imagesimport["faq_(6).gif"]} alt="" className="align-center"/>
                <p>
                  Franco Kernel is one of the more popular kernels used by
                  Android users. A kernel tells the hardware in your Android
                  device how to behave, kind of like your mommy tells you how to
                  behave. You can modify how fast your processor runs by over or
                  underclocking, how your screen behaves, or optimize your
                  system for battery life. Now that you have a good
                  understanding of what a kernel does, let’s go over some of the
                  features of Franco Kernel and see what it can do. Franco
                  Kernel has some features like auto flash updates, CPU
                  governor, backup, and restores kernels, system monitor. The
                  Franco kernel is supported by a lot of devices.
                </p>
                <img src={Imagesimport["faq_(8).gif"]} alt="" className="align-center"/>
                <strong>
                  <span className="green">
                    Now since you know about Custom ROM and custom Kernels so a
                    questions would Pop-up in your heads asking{" "}
                  </span>
                </strong>
                <h4>
                  <span className="green">
                    1. Should I root my phone and install a Custom ROM or stay
                    on the Official one?
                  </span>
                </h4>
                <p>
                  <span className="red">Ans:</span> Think about what you do on
                  your phone: Are you able to do everything on your phone
                  easily? Do you care about your warranty? Do you want to take
                  the risk? Let's see what u guys got in your mind is it yes or
                  no.
                </p>
                <p>
                  If you get the answer is – Yes. I can do what I want with my
                  phone easily and worry about the warranty, don’t want to take
                  a risk, and be in danger then I would say be on a Stock ROM
                  and enjoy the Stock Features. The Choice is Yours!
                </p>
                <p>
                  If you get the answer is – No. I can’t do what I want with my
                  phone easily along with no worries about your warranty. Ready
                  for a risk and consequences that you may get in the future.
                  You can install a Custom ROM and make your decision like a
                  Free Bird! The choice is yours!
                </p>
                <h4>
                  {" "}
                  <span className="green">
                    2. How to Choose a Right Custom ROM?
                  </span>
                </h4>
                <p>
                  <span className="red">Ans:</span> If you decided to install a
                  Custom ROM on your phone you might be wondering - Which ROM
                  should I choose from the tons of ROM available?? My advice to
                  you would be to Read the Reviews and Feedbacks of the Users of
                  the Same ROM for the Same Device. A ROM would be great on a
                  particular device but not the same on the other device. Go
                  over the ROM release post, read the features, and decide for
                  yourself. The Most Popular Developer Community is on XDA you
                  can check out there for the same.
                </p>
                <h4>
                  <span className="green">3. What is Bootloader?</span>
                </h4>
                <p>
                  <span className="red">Ans:</span> A Bootloader is the first
                  checkpoint in the boot of the OS. It is responsible for
                  checking the security of both the hardware and the software
                  while a device boots up. The bootloader in Android is like
                  BIOS in PCs. It includes a set of instructions and information
                  to boot the OS. Most of the manufacturers select to sell their
                  devices with a locked bootloader, which makes it impossible to
                  run custom ROMs. Users have to unlock the bootloader in order
                  to flash and use custom ROMs.
                </p>
                <h4>
                  <span className="green">4. What is Recovery?</span>
                </h4>
                <p>
                  <span className="red">Ans:</span> A Bootloader is the first
                  checkpoint in the boot of the OS. It is responsible for
                  checking the security of both the hardware and the software
                  while a device boots up. The bootloader in Android is like
                  BIOS in PCs. It includes a set of instructions and information
                  to boot the OS. Most of the manufacturers select to sell their
                  devices with a locked bootloader, which makes it impossible to
                  run custom ROMs. Users have to unlock the bootloader in order
                  to flash and use custom ROMs.Ans: Recovery, or Recovery Mode,
                  in Android refers to a bootable partition on the device that
                  has the recovery console. It does what its name says:
                  recovery. In case of system failure, or lockout, it helps the
                  user regain access to the OS by various methods. Like ROMs,
                  there are two types of recoveries: stock and custom. Stock
                  Recoveries come pre-installed with the stock ROM, and custom
                  ones, you guessed it, are made by 3rd party developers, just
                  like custom ROMs.
                </p>
                <h4>
                  <span className="green">5. What is Brick?</span>
                </h4>
                <p>
                  <span className="red">Ans:</span> A brick is a cuboidal-shaped
                  material used in the construction of buildings and walls.
                  Well, this is, however, not the definition of brick in
                  Android’s dictionary. A bricked Android device means a device
                  that does not boot properly. There are two kinds of bricks-
                  soft brick and hard brick. Failure of boot because of
                  failure/errors in the software (or OS) is called a soft brick,
                  whereas failure of boot due to hardware errors/failure in the
                  hardware (motherboard, buttons, etc.) is called a hard brick.
                </p>
                <h4>
                  <span className="green">
                    6. Are Custom ROMs better than Stock ROMs?
                  </span>
                </h4>
                <p>
                  <span className="red">Ans:</span> In most cases, yes. People
                  flash custom ROMs only because they’re better than the stock
                  ones. They often have more features, or better battery life,
                  or better update process, or better memory management, or and
                  more. That said though, we can’t be sure how good a custom ROM
                  is unless we try it out. For this, we have introduced ROM
                  reviews, where a regular user can share his review of a
                  particular custom ROM, to help other users. You can check ROM
                  reviews on a particular device’s XDA page or some Youtube
                  channels.
                </p>
                <h4>
                  <span className="green">
                    {" "}
                    7. I have seen Lineage Os with an official tag means it is
                    maintained by xiaomi officially?
                  </span>
                </h4>
                <p>
                  <span className="red">Ans:</span> If a custom rom has an
                  official tag it doesn't mean it is maintained by xiaomi
                  officially, a group of people who maintains several devices of
                  a particular rom for example RR, Pixel experience, and Aex,
                  etc all are maintained by a group of people with a website. So
                  they will keep the official tag for custom rom that's it.
                </p>
                <h4>
                  <span className="green">
                    {" "}
                    8. I want to flash a custom kernel in my device. Where can I
                    find one?
                  </span>
                </h4>
                <p>
                  <span className="red">Ans:</span> You can find your device
                  compatible custom kernel in XDA on your device-specific
                  section.
                </p>
                <h4>
                  Cheers everyone. This is me signing off now. I will catch you
                  guys in the next one. Until then, Stay Creative!!!!!!
                </h4>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <div className="clearboth">&nbsp;</div>
    </React.Fragment>
  );
};
