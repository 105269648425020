import * as actions from "redux/constant/types";

const intialState = {
  TeamsInformation: undefined,
  userList: undefined,
  userStatusList: {},
  userLogs: undefined,
  cmsUnverifedPostList: undefined,
  cmsVerifedPostList: undefined,
  cmsReportedPostList: undefined,
  cmsDeletedPostList: undefined,
  postLogs: [],
  tabIndex:0
};

export const cmsAdminModuleReducer = (state = intialState, action) => {
  switch (action.type) {
    case actions.GET_TEAMS_INFO:
      return {
        ...state,
        TeamsInformation: action.payload,
      };
    case actions.GET_USER_LIST:
      return {
        ...state,
        userList: action.payload,
      };
    case actions.GET_USER_STATUS:
      const { userStatusList } = state;

      if (action.payload.isFilter) {
        let data = userStatusList;
        let filterData = userStatusList.userList.filter(
          (item) => item.userId !== action.payload.userData
        );
        data.userList = filterData;
        action.payload.userData = data;
      }
      return {
        ...state,
        userStatusList: action.payload.userData,
      };
    case actions.CLEAR_LIST_STATE:
      return {
        ...state,
        userStatusList: {},
      };

    case actions.GET_USER_LOGS:
      return {
        ...state,
        userLogs: action.payload,
      };
    case actions.GET_CMS_UNVERIFED_POSTLIST:
      return {
        ...state,
        cmsUnverifedPostList: action.payload,
      };
    case actions.GET_CMS_VERIFED_POSTLIST:
      return {
        ...state,
        cmsVerifedPostList: action.payload,
      };
    case actions.GET_CMS_REPORT_POSTLIST:
      return {
        ...state,
        cmsReportedPostList: action.payload,
      };
    case actions.GET_CMS_DELETED_POSTLIST:
      return {
        ...state,
        cmsDeletedPostList: action.payload,
      };
    case actions.CLEAR_POST_LIST:
      return {
        ...state,
        cmsUnverifedPostList: {},
        cmsVerifedPostList: {},
        cmsReportedPostList: {},
        cmsDeletedPostList: {},
      };

    case actions.GET_POST_LOGS:
      return {
        ...state,
        postLogs: action.payload,
      };
      case actions.GET_TAB_INDEX:
        return {
          ...state,
          tabIndex: action.payload,
        };
    default:
      return state;
  }
};
