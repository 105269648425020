import * as actions from "redux/constant/types";

const intialState = {
  productLoad: false,
  product: undefined,
};

// Home reducer start
export const productReducer = (state = intialState, action) => {
  switch (action.type) {
    case actions.PRODUCT_SERIES_LOAD:
      return {
        ...state,
        productLoad: true,
      };

    case actions.PRODUCT_SERIES_SUCCESS:
      return {
        ...state,
        productLoad: false,
        product: action.payload,
      };
    default:
      return state;
  }
};
