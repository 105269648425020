export const CREATE_CONTACT = "CREATE_CONTACT";
export const GET_CONTACT = "GET_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const SELECT_CONTACT = "SELECT_CONTACT";
export const CLEAR_CONTACT = "CLEAR_CONTACT";
export const DELETE_SELECTED_CONTACT = "DELETE_SELECTED_CONTACT";

// Global element types
export const TOAST_OPEN = "TOAST_OPEN";
export const PAGE_LOAD = "PAGE_LOAD";
export const SHOW_MORE_POSTS="SHOW_MORE_POSTS";

// Api access token
export const ACCESS_TOKEN_VERIFY = "ACCESS_TOKEN_VERIFY";

// Header menu items types
export const MENU_ITEMS_LOAD = "MENU_ITEMS_LOAD";
export const MENU_ITEMS_SUCCESS = "MENU_ITEMS_SUCESS";

// Footer items types
export const FOOTER_ITEMS_LOAD = "FOOTER_ITEMS_LOAD";
export const FOOTER_ITEMS_SUCCESS = "FOOTER_ITEMS_SUCESS";

// Home page items types
export const HOME_ITEMS_LOAD = "HOME_ITEMS_LOAD";
export const HOME_ITEMS_SUCCESS = "HOME_ITEMS_SUCCESS";

// User infromation items types
export const USER_PROFILE_LOAD = "USER_PROFILE_LOAD";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_OTHER_PROFILE_SUCCESS = "USER_OTHER_PROFILE_SUCCESS";


// User followers items types
export const USER_FOLLOWERS_LOAD = "USER_FOLLOWERS_LOAD";
export const USER_FOLLOWERS_SCCESS = "USER_FOLLOWERS_SCCESS";

// User followings items types
export const USER_FOLLOWING_LOAD = "USER_FOLLOWING_LOAD";
export const USER_FOLLOWING_SCCESS = "USER_FOLLOWING_SCCESS";

// User settings items types
export const USER_SETTINGS_LOAD = "USER_SETTINGS_LOAD";
export const USER_SETTINGS_SUCCESS = "USER_SETTINGS_SUCCESS";

// User settings items types
export const MY_DEVICES_SUCCESS = "MY_DEVICES_SUCCESS";
export const MY_DRAFT_SUCCESS = "MY_DRAFT_SUCCESS";

// Top ranking users types
export const RANKING_USERS_LOAD = "RANKING_USERS_LOAD";
export const RANKING_USERS_SUCCESS = "RANKING_USERS_SUCCESS";

// Prodcut page types
export const PRODUCT_SERIES_LOAD = "PRODUCT_SERIES_LOAD";
export const PRODUCT_SERIES_SUCCESS = "PRODUCT_SERIES_SUCCESS";

// Discover page types
export const DISCOVER_ITEMS_LOAD = "DISCOVER_ITEMS_LOAD";
export const DISCOVER_ITEMS_SUCCESS = "DISCOVER_ITEMS_SUCCESS";

// trending section types
export const TRENDING_ITEMS_LOAD = "TRENDING_ITEMS_LOAD";
export const TRENDING_ITEMS_SUCCESS = "TRENDING_ITEMS_SUCCESS";

// User section types
export const USER_ITEMS_LOAD = "USER_ITEMS_LOAD";
export const USER_ITEMS_SUCCESS = "USER_ITEMS_SUCCESS";

// User profile details section
export const USER_DETAILS_LOAD = "USER_DETAILS_LOAD";
export const USER_IS_LOGGEDIN = "USER_IS_LOGGEDIN";
export const GET_USER_ID = "GET_USER_ID";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";

// All post Items 
export const ALLPOST_ITEMS_LOAD = "ALLPOST_ITEMS_LOAD";
export const ALLPOST_ITEMS_SUCCESS = "ALLPOST_ITEMS_SUCCESS";

// Task section types
export const ALLTASK_ITEMS_LOAD = "ALLTASK_ITEMS_LOAD";
export const ALLTASK_ITEMS_SUCCESS = "ALLTASK_ITEMS_SUCCESS";

// PostDetails section types
export const ALLPOSTDETAILS_ITEMS_LOAD = "ALLPOSTDETAILS_ITEMS_LOAD";
export const ALLPOSTDETAILS_ITEMS_SUCCESS = "ALLPOSTDETAILS_ITEMS_SUCCESS";
export const CLEAR_POSTDETAILS_CONTENT = "CLEAR_POSTDETAILS_CONTENT";

// User comment section types
export const USER_COMMENTS_LOAD = "USER_COMMENTS_LOAD";
export const USER_COMMENTS_SUCCESS = "USER_COMMENTS_SUCCESS";
 
// All post Items 
export const ALLPOST_LOAD = "ALLPOST_LOAD";
export const ALLPOST_SUCCESS = "ALLPOST_SUCCESS";
export const MY_POST_SUCCESS = "MY_POST_SUCCESS";

// Feature post Items 
export const FEATURE_LOAD = "FEATURE_LOAD";
export const FEATURE_SUCCESS = "FEATURE_SUCCESS";

// News componnent posts  
export const NEWS_POST_LOAD = "NEWS_POST_LOAD";
export const NEWS_POST_SUCCESS = "NEWS_POST_SUCCESS";

// Event componnent posts 
export const EVENT_POST_SUCCESS = "EVENT_POST_SUCCESS";

// PHOTOGRAPHY componnent posts 
export const PHOTOGRAPHY_POST_SUCCESS = "PHOTOGRAPHY_POST_SUCCESS";

// Tech posts 
export const TECH_POST_SUCCESS = "TECh_POST_SUCCESS";

// Resources posts 
export const RESOURCES_POST_SUCCESS = "RESOURCES_POST_SUCCESS";

// Product page serires type
export const POCOXSERIES_POST_SUCCESS = "POCOXSERIES_POST_SUCCESS";
export const POCOMSERIES_POST_SUCCESS = "POCOMSERIES_POST_SUCCESS";
export const POCOCSERIES_POST_SUCCESS = "POCOCSERIES_POST_SUCCESS";
export const POCOFSERIES_POST_SUCCESS = "POCOFSERIES_POST_SUCCESS";

// feedback page items
export const SUGGESION_POST_SUCCESS = "SUGGESION_POST_SUCCESS";
export const FEEDBACK_POST_SUCCESS = "FEEDBACK_POST_SUCCESS";

// Search Items 
export const SEARCH_ITEM_LOAD = "SEARCH_ITEM_LOAD";
export const SEARCH_ITEM_SUCCESS = "SEARCH_ITEM_SUCCESS";

// Post like dislike counts 
export const POST_LIKE_DISLIKE_COUNT_LOAD = "POST_LIKE_DISLIKE_COUNT_LOAD";
export const POST_LIKE_DISLIKE_COUNT_SUCCESS = "POST_LIKE_DISLIKE_COUNT_SUCCESS";

//  calendar types
export const ALLCALENDER_ITEMS_LOAD = "ALLCALENDER_ITEMS_LOAD";
export const ALLCALENDER_ITEMS_SUCCESS = "ALLCALENDER_ITEMS_SUCCESS";

// user bookmarked types
export const USER_BOOKMARKED_LOAD = "USER_BOOKMARKED_LOAD";
export const USER_BOOKMARKED_SCCESS = "USER_BOOKMARKED_SCCESS";

// Download center types
export const DOWNLOAD_CENTER_LOAD  = "DOWNLOAD_CENTER_LOAD";
export const DOWNLOAD_CENTER_SCCESS = "DOWNLOAD_CENTER_LOAD_SCCESS";

// Download center types
export const KUDOS_COUNT_LOAD  = "KUDOS_COUNT_LOAD";
export const KUDOS_COUNT_SUCCESS = "KUDOS_COUNT_SUCCESS";

//  Notification Types
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";

//  Notification Types
export const SET_AUTOSAVE_POSTID = "SET_AUTOSAVE_POSTID";

//  Notification Types
export const GET_ATTACHMENTS = "GET_ATTACHMENTS";

// Admin Panel types
// User Management
export const GET_TEAMS_INFO = "GET_TEAMS_INFO";
export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_STATUS = "GET_USER_STATUS";
export const GET_USER_LOGS = "GET_USER_LOGS";
export const CLEAR_LIST_STATE = "CLEAR_LIST_STATE"

// Post Management
export const GET_CMS_UNVERIFED_POSTLIST = "GET_CMS_UNVERIFED_POSTLIST";
export const GET_CMS_VERIFED_POSTLIST = "GET_CMS_VERIFED_POSTLIST";
export const GET_CMS_REPORT_POSTLIST = "GET_CMS_REPORT_POSTLIST";
export const GET_CMS_DELETED_POSTLIST = "GET_CMS_DELETED_POSTLIST";
export const GET_POST_LOGS = "GET_POST_LOGS";
export const CLEAR_POST_LIST = "CLEAR_POST_LIST";
export const GET_TAB_INDEX = "GET_TAB_INDEX"


