import React from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function SearchFilter(props) {

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-amount">
                Search title
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                onChange={(e) => props.setSearchValue(escape(e.target.value))}
                endAdornment={<SearchIcon position="end" />}
                labelWidth={150}
              />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} md={6}></Grid>
        <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              multiple
              id="fixed-tags-demo"
              value={props.filterValue}
              onChange={(event, newValue) => {
                const unique = [...new Map(newValue.map(item => [item["category"], item])).values()]
                props.filtersetValue([...unique]);
              }}
              options={props.data}
              getOptionLabel={(option) => option.category}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip label={option.category} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Category"
                  variant="outlined"
                  placeholder="categories "
                />
              )}
            />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
