import React, { useEffect } from "react";
import { Grid, Container, Box } from "@material-ui/core";
import Icon from '@material-ui/core/Icon';

export const Termsuse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container >
          <Grid item >
            <Box  mt={8} mb={4} p={2}>
              <div >
                <h4>Content Policy in POCO Community</h4>
                <p>
                  While you guys are having fun here in POCO Community, do
                  remember to show your utmost respect to the fellow POCO Fans
                  around you. We sincerely hope that this will be an online
                  platform where everyone can have fun and feel safe as we share
                  and learn from each other! Every home has its own rules and
                  here in POCO Community, we have just a few too.
                </p>
                <h4>Content will be prohibited and removed if:</h4>
                <h4>
                  1. It belongs to another person and to which the user does not
                  have any right;
                </h4>
                <h4>2. Profanity is used</h4>
                <p>
                  While we encourage our fans to be open and share their
                  opinions, usage of profanity is not tolerated. Users found to
                  be using profanity will be banned from our community.
                </p>
                <h4>
                  3. It is defamatory, libellous, discriminatory, insulting or
                  harassing on the basis of gender, race or ethnicity, or is
                  threatening, harassing or constitutes a personal attack
                </h4>
                <p>
                  Courtesy is the best policy. We understand that there are
                  times you don’t agree with someone or perhaps had a bad day,
                  but this does not justify any use of discrimination, threats
                  or personal attacks. Any form of impolite posting etiquette
                  will result in the user being banned from our community. Let’s
                  all work together to make our community a friendly and
                  welcoming one!
                </p>
                <h4>
                  4. It contains obscene, pornographic, paedophilic content, or
                  content which is invasive of another’s privacy, including
                  bodily privacy
                </h4>
                <p>
                  We do not condone posting of photographs, videos, digital
                  images or any material which exposes the private areas of an
                  individual, shows an individual in a state of partial or full
                  nudity or engaged in any act of sexual conduct, taken or
                  posted with or without their permission. Other prohibited
                  content includes child sexual abuse imagery, artificially
                  morphed images, content that encourages or promotes
                  paedophilia, content that is invasive of another’s privacy
                  including bodily privacy, as well as content that is obscene,
                  or glorifies or promotes rape or non-consensual sexual
                  violence.
                </p>
                <h4>5. Personal information of others are posted</h4>
                <p>
                  It is not nice to post someone's personal information, or post
                  links to personal information. This includes links to public
                  Facebook pages and screenshots of Facebook pages with the
                  names still legible. When posting screenshots, be sure to edit
                  out any personally identifiable information to avoid running
                  afoul of this rule.
                </p>
                <h4>6. Impersonation occurs</h4>
                <p>
                  Impersonating someone in a misleading or deceptive manner is
                  not allowed. However, satire and parody is acceptable.
                </p>
                <h4>
                  7. It relates to or encourages money laundering or gambling
                </h4>
                <h4>8. It is harmful to children</h4>
                <h4>9. It is false or untrue, or is misleading</h4>
                <p>
                  Content which is patently false and untrue, and is written or
                  published in any form, with the intent to mislead or harass a
                  person, entity or agency for financial gain or to cause any
                  injury to any person.
                </p>
                <p>
                  Content which deceives or misleads the addressee about the
                  origin of the message or knowingly and intentionally
                  communicates any information which is patently false or
                  misleading in nature but may reasonably be perceived as a
                  fact.
                </p>
                <h4>
                  10. It contains software virus or any other computer code,
                  file or program designed to interrupt, destroy or limit the
                  functionality of POCO Community or any user or host of the
                  same
                </h4>
                <h4>
                  11. It threatens the unity, integrity, defence, security or
                  sovereignty of India, friendly relations with foreign States,
                  or public order, or causes incitement to the commission of any
                  offence or prevents investigation of any offence or is
                  insulting other nation
                </h4>
                <h4>12. It offends decency or morality</h4>
                <h4>13. It constitutes contempt of court</h4>
                <h4>
                  14. It infringes any patent, trademark, copyright or other
                  proprietary rights
                </h4>
                <h4>15. They are spams</h4>
                <p>
                  Spam is posting any content that is not within the topic of
                  discussion or does not contribute constructively to the forum.
                </p>
                <h4>16. Author begs for likes</h4>
                <p>
                  Requesting for Likes is not permitted. Good content will
                  naturally have good engagement. If you have to ask, it just
                  means that your content hasn’t met the mark yet. Don’t give up
                  - keep going!
                </p>
                <h4>17. Author advertise or sell products and services</h4>
                <p>
                  Any form of advertising or sales is strictly prohibited
                  between members in this community. Posting links of
                  third-party vendors reselling POCO products is also considered
                  as advertising.
                </p>
                <h4>18. Author engages in political discussions</h4>
                <h4>
                  19. It contains only non-substantial or one-word titled
                  threads
                </h4>
                <p>
                  POCO Community is a place for sharing, and we encourage our
                  fans to write threads to share their thoughts, but this has to
                  be done in a certain way. Each thread created has to contain
                  substantial content and a good title. As to present the
                  valuable threads to our POCO fan, threads that contain
                  non-substantial content and single-worded title (or less than
                  10 characters) will be deleted.
                </p>
                <h4>
                  20. It is illegal or violates any law for the time being in
                  force
                </h4>
                <h4>Enforcement</h4>
                <p><Icon color="primary" style={{ fontSize: 8 }}>fiber_manual_record</Icon> Such contents will be deleted immediately</p>
                <p><Icon color="primary" style={{ fontSize: 8}}>fiber_manual_record</Icon> First time violation: Author will be reminded</p>
                <p>
                <Icon color="primary" style={{ fontSize: 8 }}>fiber_manual_record</Icon> Second time violation: Author will receive a warning and
                  enter Restricted Group 1*{" "}
                </p>
                <p>
                <Icon color="primary" style={{ fontSize: 8}}>fiber_manual_record</Icon> Third time violation: Author will be banned for a week and
                  enter Restricted Group 2*
                </p>
                <p>
                <Icon color="primary" style={{ fontSize: 8}}>fiber_manual_record</Icon> Fourth time violation: Author will be banned from the
                  community totally and enter Restricted Group 3*
                </p>
                <h4>*Restricted Group</h4>1 Restricted from commenting View-only
                access, cannot comment on forum. 2 Restricted from accessing
                Denied access to forums. 3 Restricted IPs Any account accessing
                from the IP address will be banned.
                <h4>Disclaimer:</h4>
                <p>
                  The views, opinions, or anything there of discussed on POCO
                  Community are by no means the views, opinions, or anything
                  there of supported by the POCO Community Team or the POCO
                  Staff. While we will try our best to moderate and delete any
                  prohibited content, we may not be held responsible for any
                  content that is posted.
                </p>
                <p>
                  POCO Community reserves the right to use images and content
                  created by users, modify rules and guidelines that can be
                  found throughout the forum at any time without prior
                  notification, and the Administrator's word will be deemed
                  final.
                </p>
                <p>
                  We are sure these will not be applicable for most of you
                  anyway! So go out there and have fun everyone!
                </p>
                <h4>Grievance Redressal Mechanism:</h4>
                <p>
                  If you have any complaints regarding the content available on
                  POCO Community, please email the Grievance Officer at the
                  email address provided below.
                </p>
                <h4>Grievance Officer Details:</h4>
                <p>Name: Vishwanath C</p>
                <p>Email ID: grievance.officer@xiaomi.com</p>
                <p>Contact no.: 080 6885 6286, Mon-Sat: 9 AM to 6 PM</p>
                <p>Address: Xiaomi Technology India Pvt Ltd, Ground Floor,</p>
                <p>AKR Infinity, Kudlu Gate,</p>
                <p> Krishna Reddy Industrial Area,</p>
                <p> Hosapalaya, Muneshwara Nagar,</p>
                <p>Bengaluru, Karnataka 560068,</p> India
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <div className="clearboth">&nbsp;</div>
    </React.Fragment>
  );
};
