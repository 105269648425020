import React from "react";
import { Grid, Container, Box } from "@material-ui/core";
import { Imagesimport } from "core/utils";

export const Instruction = () => {
  return (
    <React.Fragment>
      <Container maxWidth="lg" className="instruction-section">
        <Grid container spacing={10} sm={12} md={12} lg={12}>
          <Grid item>
            <Box mt={12}>
              <div>
                <img src={Imagesimport["image1.png"]} alt="" width="100%" />
                <p>
                  Hello everyone Nowadays custom roms are popular and a lot of
                  users may want to flash those roms to enjoy the excellent
                  performance and customization. We all know about custom roms
                  and custom recoveries for our devices which are available out
                  there. Now here today we are going to see how to download
                  custom roms and flash them. Let’s get started………
                </p>
                <strong className="orange">
                  Your device bootloader should be unlocked to follow this
                  process{" "}
                </strong>
                <h4>Files Need to be downloaded for flashing TWRP</h4>
                <ul>
                  <li>
                    TWRP <a href="https://twrp.me/Devices/">Download Here</a>
                  </li>
                  <li>
                    Minimal ADB Fastboot{" "}
                    <a href="https://androiddatahost.com/uq6us">
                      Download Here
                    </a>
                  </li>
                  <li>Download your device rom from download center.</li>
                </ul>
                <img src={Imagesimport["image14.jpg"]} alt="" width="100%" />
                <h4>
                  <u>Flashing Steps for TWRP</u>
                </h4>
                <ul>
                  <li>
                    First, install minimal ADB Fastboot drivers in C local disk
                    location
                  </li>
                  <li>
                    Now copy the TWRP file to the Minimal ADB Fastboot folder
                  </li>
                  <img src={Imagesimport["image10.png"]} alt="" width="100%" />

                  <li>
                    Now Keep the device in fastboot mode by pressing the volume
                    down key + power key at a time for few seconds until you <br></br>see
                    fastboot mode on your screen. then connect your device to
                    your PC
                  </li>
                  <li>
                    Now Open cmd-here file from minimal adb fastboot folder
                  </li>
                  <img src={Imagesimport["image12.png"]} alt="" width="100%" />
                  <li>
                    Now enter the following command fastboot boot
                    twrp-k40pro-21.5.26.img and press enter.
                  </li>
                  <img src={Imagesimport["image5.png"]} alt="" width="100%" />
                  <img src={Imagesimport["image8.png"]} alt="" width="100%" />
                  <li>Now just wait till the TWRP boots</li>
                  <li>Here is the TWRP screen </li>
                  <img src={Imagesimport["image11.jpg"]} alt="" width="100%" />
                </ul>
                <h4>
                  <u>Flashing Steps for custom roms</u>
                </h4>
                <ul>
                  <li>Now go to wipe</li>
                  <img src={Imagesimport["image2.jpg"]} alt="" width="100%" />
                  <li>Click on advanced wipe</li>
                  <img src={Imagesimport["image9.jpg"]} alt="" width="100%" />
                  <li>
                    Mark all option like system, data, internal,cache, dalvik
                    cache and swipe to wipe.
                  </li>
                  <img src={Imagesimport["image4.jpg"]} alt="" width="100%" />
                  <li>
                    Now connect the device to your pc and copy the rom files.
                  </li>
                  <li>Now come back to main screen and go to install</li>
                  <img src={Imagesimport["image7.jpg"]} alt="" width="100%" />
                  <li>
                    Choose only rom file if you are flashing any MIUI roms
                    (Choose rom and gapps file if you are flashing custom roms
                    if needed) now swipe to flash{" "}
                  </li>
                  <img src={Imagesimport["image3.jpg"]} alt="" width="100%" />
                  <li>
                    Now wait till the flashing process is done and click on
                    reboot.
                  </li>
                  <img src={Imagesimport["image6.jpg"]} alt="" width="100%" />
                  <li>
                    Now select system, then system will start booting so wait
                    till it boots and enjoy
                  </li>
                  <img src={Imagesimport["image13.jpg"]} alt="" width="100%" />
                </ul>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <div className="clearboth">&nbsp;</div>
    </React.Fragment>
  );
};
