import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({

  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },

  palette: {
    type: 'light',
    primary: {
      main: 'rgba(0, 0, 0, 0.9)',
      light: 'rgba(47,46,46,0.72)',
      contrastText: '#ffffff',
      dark: 'rgba(24,23,23,0.9)',
      opacity: '0.95',
    },
    secondary: {
      main: '#ece70b',
    },
    background: {
      default: '#f5f7f9',
      paper: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: 'rgba(76,76,74,0.51)',
      hint: '#ece70b',
      disabled: 'rgba(78,75,75,0.38)',
      default:'#ffffff'
    }
  }
});

export default function ThemeProvide(props) {
  return (
    <ThemeProvider theme={theme}>
       {props.children}
    </ThemeProvider>
  );
}
