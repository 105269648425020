import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { postBookMark, deleteBookMark } from "redux/actions/userPageAction";
import { getUserProfile } from "redux/actions/userProfileAction";
import { getBookmarked } from "redux/actions/userPageAction";
import { Iconsimport } from "core/utils";
import { CustomLink } from "components/elements/customLink";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Zoom } from "@material-ui/core";

import {
  postLike,
  // getAllLikeDislikeCount,
} from "redux/actions/postDetailsAction";

import {
  Card,
  Avatar,
  Typography,
  CardMedia,
  CardContent,
  CardActions,
  CardActionArea,
  Box,
  GridListTileBar,
  Grid,
  Button,
  Tooltip,
  IconButton,
} from "@material-ui/core";

import PropTypes from "prop-types";
import "./index.scss";
import CustomModal from "../customModal";

export function PocoCard(props) {
  const {
    Img,
    Tittle,
    ProfilePic,
    UserName,
    postCreatedOn,
    tag,
    view,
    like,
    height,
    isDark,
    postid,
    profileId,
    isBookmarked,
    isUserliked,
    isBanner = false,
    action = {
      isdeletebtnEnable: false,
      isEditEnable: false,
      postAction: () => { },
    },
  } = props;

  const [bookmarIcoToggle, setbookmarIcoToggle] = useState(false);
  const [likeIcoToggle, setlikeIcoToggle] = useState({ userLike: false });

  const [count, setCount] = useState({ postLikecount: 0, click: false });

  const [open,setOpen] = useState(false);

  const cardsStyle = makeStyles((theme) => ({
    root: {},
    media: {
      height: height || 170,
      [theme.breakpoints.up("sm")]: {
        height: height || 200,
      },
    },
  }));

  const classes = cardsStyle();

  const userAction = like !== undefined || view !== undefined;
  const cardConent = Tittle !== undefined;
  const cardAction =
    ProfilePic !== undefined ||
    UserName !== undefined ||
    postCreatedOn !== undefined ||
    tag !== undefined ||
    isBanner !== undefined;

  const dispatch = useDispatch();

  // Destructure login user to differentiate b/w login user and profile viewer
  const { userProfile } = useSelector((state) => state.userProfile);
  const { userInfo: loginUser } = userProfile ? userProfile.userProfile : {};
  const { userId, profilePic, firstName, lastName } = loginUser || 0;

  // post like count
  // const { postLikeDisLikeCounts } = useSelector((state) => state.postDetails);
  // const { likeCount, postId } = postLikeDisLikeCounts || "";

  useEffect(() => {
    setbookmarIcoToggle(isBookmarked);
    setlikeIcoToggle({ ...likeIcoToggle, userLike: isUserliked });
    setCount({ ...count, postLikecount: like, click: isUserliked });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBookmarked, isUserliked, like]);

  const handleBookmark = async (e) => {
    e.preventDefault();
    setbookmarIcoToggle(!bookmarIcoToggle);
    await dispatch(postBookMark(postid, userId));
    dispatch(getUserProfile(userId));
  };

  const dltBookmark = async (e) => {
    e.preventDefault();
    setbookmarIcoToggle(!bookmarIcoToggle);
    await dispatch(deleteBookMark(userId, postid));
    dispatch(getUserProfile(userId));
    dispatch(getBookmarked(1, 10, userId));
  };

  //  method for like
  const handleUserLike = async (e) => {
    e.preventDefault();
    if (!userId) {
      return;
    }
    const postLikeSchema = {
      postId: postid,
      userAvatar: profilePic,
      userId: userId,
      userName: firstName + " " + lastName,
    };

    if (count.click) {
      setCount((prev) => ({
        ...prev,
        postLikecount: prev.postLikecount !== 0 ? prev.postLikecount - 1 : 0,
        click: false,
      }));
      setlikeIcoToggle((prev) => ({ ...prev, userLike: !prev.userLike }));
      await dispatch(postLike(postLikeSchema));
      // userId && dispatch(getAllLikeDislikeCount(postid, userId));
    } else {
      setCount((prev) => ({
        ...count,
        postLikecount: prev.postLikecount + 1,
        click: true,
      }));
      setlikeIcoToggle((prev) => ({ ...prev, userLike: !prev.userLike }));
      await dispatch(postLike(postLikeSchema));
      // userId && dispatch(getAllLikeDislikeCount(postid, userId));
    }
  };

  // post actions
  const handleAction = async (e) => {
    e.preventDefault();
    action.postAction();
    setOpen(false)
  };
  // passing current location
  const location = useLocation();

  const { isLoggedinUser } = useSelector((state) => state.userProfile);
  return (
    <Card
      className={`${isDark && isDark ? "poco-card-dark" : "poco-card"} ${classes.root
        }`}
    >
      {isBanner ? (
        <CardActionArea>
          <CardMedia
            className={`${classes.media} poco-banners`}
            component="img"
            alt="post thumbnail"
            src={Img}
          />
        </CardActionArea>
      ) : (
        <React.Fragment>
          <Link
            to={{
              pathname: `/post/${postid}`,
              state: { from: location.pathname, postId: postid },
            }}
          >
            <CardActionArea>
              {userAction && (
                <GridListTileBar
                  title={
                    <Box display="flex" alignItems="center" p={1}>
                      <Tooltip title="View" arrow>
                        <img src={Iconsimport["view.svg"]} alt="view" />
                      </Tooltip>
                      {view}
                    </Box>
                  }
                  subtitle={
                    <Box display="flex" alignItems="center" p={1}>
                      <React.Fragment>
                        {userId !== undefined ? (
                          <Tooltip title="Likes" arrow>
                            <IconButton
                              aria-label="like"
                              onClick={(e) => handleUserLike(e, like)}
                            >
                              {likeIcoToggle.userLike ? (
                                <ThumbUpIcon fontSize="small" />
                              ) : (
                                <ThumbUpAltOutlinedIcon fontSize="small" />
                              )}
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={
                              <React.Fragment>
                                <span>
                                  Please&nbsp;
                                  <CustomLink
                                    loggedIn={isLoggedinUser}
                                    path={window.location.pathname}
                                  >
                                    <Typography
                                      component="span"
                                      variant="inherit"
                                      color="secondary"
                                    >
                                      login
                                    </Typography>
                                  </CustomLink>
                                  &nbsp; to continue liking this post
                                </span>
                              </React.Fragment>
                            }
                            arrow
                            interactive
                            TransitionComponent={Zoom}
                            leaveDelay={300}
                            placement="bottom"
                          >
                            <IconButton
                              aria-label="like"
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <ThumbUpAltOutlinedIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}

                        <span>{count.postLikecount}</span>
                      </React.Fragment>
                    </Box>
                  }
                  actionIcon={
                    <Box display="flex" alignItems="center" p={1}>
                      {userId !== undefined && (
                        <Tooltip title="Bookmark" arrow>
                          {bookmarIcoToggle ? (
                            <IconButton
                              aria-label="bookmark-fill"
                              onClick={dltBookmark}
                              size="small"
                            >
                              <BookmarkIcon fontSize="small" />
                            </IconButton>
                          ) : (
                            <IconButton
                              aria-label="bookmark"
                              onClick={handleBookmark}
                            >
                              <BookmarkBorderIcon fontSize="small" />
                            </IconButton>
                          )}
                        </Tooltip>
                      )}
                    </Box>
                  }
                />
              )}
              <CardMedia
                className={classes.media}
                component="img"
                alt="post thumbnail"
                src={Img}
              />
              {cardConent && (
                <CardContent>
                  <Typography gutterBottom variant="h6">
                    {/* {unescape(Tittle.length > 89
                      ? Tittle.substring(0, 89) + "..."
                      : Tittle)} */}
                    {unescape(Tittle).length > 80 ? unescape(Tittle).substring(0, 80) + '...' : unescape(Tittle)}
                  </Typography>
                </CardContent>
              )}
            </CardActionArea>
          </Link>
          {cardAction && (
            <CardActions>
              <Link to={`/profile/${profileId}`}>
                <Avatar src={ProfilePic} component="span" />
              </Link>
              <Box flexGrow={1} p={1} alignItems="center" component="span">
                <Link to={`/profile/${profileId}`}>
                  <Typography
                    gutterBottom
                    variant="body1"
                    component="span"
                    className="text-secondary"
                    display="block"
                  >
                    {UserName}
                  </Typography>
                </Link>
                <Link
                  to={{
                    pathname: `/post/${postid}`,
                    state: { from: location.pathname, postId: postid },
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="body1"
                    component="span"
                    className="text-secondary"
                  >
                    {postCreatedOn}
                  </Typography>
                </Link>
              </Box>
              <Link
                to={{
                  pathname: `/post/${postid}`,
                  state: { from: location.pathname, postId:postid },
                }}
              >
                <Box p={1} component="span">
                  <Typography
                    gutterBottom
                    variant="h6"
                    className="text-secondary"
                    component="span"
                  >
                    <strong>{tag}</strong>
                  </Typography>
                </Box>
              </Link>
            </CardActions>
          )}
          {Tittle !== undefined && action.isEditEnable && (
            <Link
              to={{
                pathname: "/addpost",
                state: { isPostEdiatble: true, postId: postid, isfromUserFlow: true },
              }}
            >
              <Tooltip title="Edit" placement="top" arrow>
                <IconButton
                  aria-label="edit"
                  size="small"
                  className="edit-post"
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Link>
          )}
          {Tittle !== undefined && action.isdeletebtnEnable && (
            <Tooltip title="Delete" placement="top" arrow>
              <IconButton
                aria-label="delete"
                onClick={(e) => setOpen(true)}
                size="small"
                className="delete-post"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>

          )}
          <CustomModal
            title="Are you sure you want to delete this post?"
            open={open}
            modalSubmit={(e) => handleAction(e) }
            modalClose={e => setOpen(false)}
            btnConfirmHide={false}
            btnCloseHide={false}
            btnConfirm = "Delete"
            btnClose = "Cancel"
          />
          
        </React.Fragment>

      )}
    </Card>
  );
}

PocoCard.propTypes = {
  Img: PropTypes.string,
  Tittle: PropTypes.string,
  UserName: PropTypes.any,
  postCreatedOn: PropTypes.any,
  tag: PropTypes.string,
  view: PropTypes.any,
  like: PropTypes.any,
  height: PropTypes.number,
  isDark: PropTypes.bool,
};

export function TittleCard(props) {
  const { title, path, btnName } = props;
  const { isLoggedinUser } = useSelector((state) => state.userProfile);
  return (
    <div className="tittle-card-section">
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Box
            display="flex"
            p={6}
            bgcolor="text.primary"
            justifyContent="left"
            alignItems="center"
            height={80}
          >
            <Box flexGrow={1}>
              <Typography variant="h4" color="inherit">
                <strong>{title}</strong>
              </Typography>
            </Box>
            <CustomLink loggedIn={isLoggedinUser} path={`${path}`}>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                fullWidth
                disableRipple
                disableFocusRipple
              >
                <strong>{btnName}</strong>
              </Button>
            </CustomLink>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
