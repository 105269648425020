import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { homeTabStyle } from "core/theme/makeStyle";
import { useSelector } from "react-redux";
import HomeTabSection from "./TabSection";
// import AutoCarousel from "components/elements/autoCarousel"
import "./index.scss";

export default function HomePage(props) {
  // const {
  //   location: { index },
  // } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Destructure login user to differentiate b/w login user and profile viewer
  const { userProfile } = useSelector((state) => state.userProfile);
  const { userInfo: loginUser } = userProfile ? userProfile.userProfile : {};
  const { role } = loginUser || "";
  return (
    <div className="poco-home">
      {/* <AutoCarousel/> */}
      <Box display={{ xs: "none", sm: "block", md: "block", lg: "block" }}>
        <img
          src="https://awsind0-fusion.fds.api.xiaomi.com/india-it-poco-cms-bucket/poco-community-files/PC.jpg?GalaxyAccessKeyId=5151729087601&Expires=9223372036854775807&Signature=dz0QYq8l0ahwGQln52YpgpFjRso="
          alt="home-banner"
          width="100%"
          className="home-banner"
        />
      </Box>
      <Box display={{ xs: "block", sm: "none", md: "none", lg: "none" }}>
        <img
          src="https://awsind0-fusion.fds.api.xiaomi.com/india-it-poco-cms-bucket/poco-community-files/Home-Banner-M.jpg?GalaxyAccessKeyId=5151729087601&Expires=9223372036854775807&Signature=L57owNf6CrpoGjsdutyMh4ycaH4="
          alt="home-banner"
          width="100%"
          className="home-banner"
        />
      </Box>
      <HomeTabSection
        homeTabStyle={homeTabStyle}
        // tabIndex={index}
        userRole={role}
      />
    </div>
  );
}
