import React from "react";
import Header from "../header";
import AdminHeader from "components/cmsAdmin/components/Header";
import Footer from "../footer";
import { useLocation } from "react-router-dom";

export const MainLayout = (props) => {
  const { isLoggedIn } = props;
  const location = useLocation();
  const pathname = location && location.pathname;
  const alllinks = [
    "/admin",
    "/admin/user",
    `/admin/post/${location.pathname.substring(12)}`,
    "/admin/editpost",
  ];
  const isAdminPanelRoute = alllinks?.some((elem) => elem === pathname);

  return (
    <React.Fragment>
      {isLoggedIn && isAdminPanelRoute ? <AdminHeader /> : <Header />}
      {props.children}
      {isLoggedIn && isAdminPanelRoute ? null : <Footer />}
    </React.Fragment>
  );
};
