import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PocoCard } from "components/elements/cards";
import { AddPost } from "components/elements/addPost";
import { getAllposts } from "redux/actions/allPostAction";
import { ephocDateCovert } from "core/utils";
import { Grid } from "@material-ui/core";
import { ShowMore } from "components/elements/showMore";
import { showMorePostsAction } from "redux/actions/globalAction";

export const Feature = (props) => {
  const { showMorePosts } = useSelector((state) => state.global);
  const { featurePost } = showMorePosts;

  // const [postCount, setpostCount] = useState(10);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllposts(featurePost, 10, 0, "Feature"));
  }, [dispatch, featurePost]);

  // method to increment count when page scroll down
  const postCountUpdate = () => {
    dispatch(showMorePostsAction({ featurePost: featurePost + 1 }));
    // setpostCount(postCount + 10);
  };
  // gobal store selctor
  const { featurePostList = {} } = useSelector((state) => state.allPost);
  const { tagData, totalCount } = featurePostList || [];
  const renderFeature = () => {
    return (
      <React.Fragment>
        {tagData?.map((ele) => (
          <Grid item xs={12} sm={12} md={6} key={ele.id}>
            <PocoCard
              postid={ele.id}
              Img={ele.postThumbnail}
              Tittle={ele.postTitle}
              ProfilePic={ele.postAvatar}
              UserName={ele.postUserName}
              postCreatedOn={ephocDateCovert(ele.createdAt)}
              tag={ele.tagName}
              view={ele.postViews}
              like={ele.postLikes}
              profileId={ele.userId}
              isBookmarked={ele.isBookmarked}
              isUserliked={ele.isUserLiked}
            />
          </Grid>
        ))}
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8} md={8}>
          <Grid container spacing={3}>
            {renderFeature()}
          </Grid>
          <ShowMore
            data={tagData}
            totalCount={totalCount}
            propFunction={postCountUpdate}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <AddPost />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
