import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { verticleTabStyle } from "core/theme/makeStyle";
import { deleteUserPic, updateUserPic } from "redux/actions/userProfileAction";
import UserInfromation from "./userInfromation";
import MyPost from "./myPosts";
import MyBookMarks from "./myBookmarks";
import MyFollowings from "./myFollowings";
import MyFollowers from "./myFollowers";
import MyDevices from "./myDevices"
import MyDrafts from "./myDrafts"
import {
  Box,
  Avatar,
  Tabs,
  Tab,
  Typography,
  Grid,
  Button,
  Container,
  Fab,
  Menu,
  MenuItem,
} from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import { isMobile } from "core/utils";
import Images from "static/images/fdsImages";

import "../index.scss";

export default function UserProfileSettings(props) {
  const { location: {state: activeTab = 0}} = props
  const dispatch = useDispatch();

  // Global state call

  const [proPic, setPropicImg] = useState(null);
  const pic = Boolean(proPic);

  const [coverPic, setCoverPic] = useState(null);
  const picCover = Boolean(coverPic);

  const { userProfile } = useSelector((state) => state.userProfile);
  const { userProfile: info } = userProfile || {};
  const { userInfo } = info || {};
  const { profilePic, coverImage, userId } = userInfo || {};

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // After user post edit mypost table will be active
  useEffect(()=>{setValue(activeTab?.activeTab)
  },[activeTab])

  //  Verticle tab method start
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function TabProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const classes = verticleTabStyle();

  const renderUserProfile = () => {
    const handleClick = (event) => {
      setPropicImg(event.currentTarget);
    };

    const handleClose = () => {
      setPropicImg(null);
    };

    const handleCoverpic = (event) => {
      setCoverPic(event.currentTarget);
    };

    const handleCoverpicClose = () => {
      setCoverPic(null);
    };
    // Method to upload profile pic
    const uploadProfilePic = ({ target }) => {
      const ProPic = target.files[0];
      dispatch(updateUserPic(userId, ProPic, "profilePic"));
    };
    // Method to upload cover pic
    const uploadCoverPic = ({ target }) => {
      const CoverImage = target.files[0];
      dispatch(updateUserPic(userId, CoverImage, "coverImage"));
    };
    // Methode to delete cover pic and profile pic
    const deleteCoverPic = (e, imgCategory = "") => {
      setCoverPic(null);
      setPropicImg(null);
      e.preventDefault();
      dispatch(deleteUserPic(userId, imgCategory));
    };

    const defaultCover =
      coverImage?.indexOf("PocoCommunity_Default_CoverImage.png") > -1
        ? Images.pcDefaultCover
        : coverImage;
    const defaultMobilecover =
      coverImage?.indexOf("PocoCommunity_Default_CoverImage.png") > -1
        ? Images.mobileDefaultCover
        : coverImage;
    const updatedCover = isMobile ? defaultMobilecover : defaultCover;
    return (
      <React.Fragment>
        <Box
          mt={8}
          display="flex"
          component="span"
          alignItems="flex-end"
          justifyContent="center"
          className="profile-section"
          style={{
            backgroundImage: `url(${updatedCover})`,
            backgroundSize: "cover",
            position: "relative",
          }}
          height={200}
        >
          <Avatar src={profilePic} className="profile-picture" />
          <div className="upload-pic">
            <Fab size="small" color="secondary" onClick={handleClick}>
              <MoreVertIcon />
            </Fab>
            <Menu
              id="long-menu"
              anchorEl={proPic}
              keepMounted
              open={pic}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                <input
                  accept="image/x-png,image/jpeg"
                  className={classes.input}
                  id="propic"
                  value=""
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => uploadProfilePic(e)}
                />
                <label htmlFor="propic">
                  <Typography variant="subtitle2">Upload</Typography>
                </label>
              </MenuItem>
              {!profilePic?.includes("default-profile-pic.jpg") && (
                <MenuItem>
                  <Typography
                    variant="subtitle2"
                    onClick={(e) => deleteCoverPic(e, "profilePic")}
                  >
                    Delete
                  </Typography>
                </MenuItem>
              )}
            </Menu>
          </div>
          <div className="upload-cover-image">
            <Fab size="small" color="secondary" onClick={handleCoverpic}>
              <MoreVertIcon />
            </Fab>
            <Menu
              id="long-menu"
              anchorEl={coverPic}
              keepMounted
              open={picCover}
              onClose={handleCoverpicClose}
            >
              <MenuItem onClick={handleCoverpicClose}>
                <input
                  accept="image/x-png,image/jpeg"
                  className={classes.input}
                  id="cover"
                  type="file"
                  style={{ display: "none" }}
                  value=""
                  onChange={(e) => uploadCoverPic(e)}
                />
                <label htmlFor="cover">
                  <Typography variant="subtitle2">Upload</Typography>
                </label>
              </MenuItem>
              {!coverImage?.includes(
                "PocoCommunity_Default_CoverImage.png"
              ) && (
                <MenuItem>
                  <Typography
                    variant="subtitle2"
                    onClick={(e) => deleteCoverPic(e, "coverImage")}
                  >
                    Delete
                  </Typography>
                </MenuItem>
              )}
            </Menu>
          </div>
        </Box>
      </React.Fragment>
    );
  };

  const renderVerticaleUserTab = () => {
    return (
      <React.Fragment>
        <Container maxWidth="lg" className="profile-settings-section">
          <Grid container>
            <Grid item sm={12} md={12}>
              <Box mt={8}>
                <Link to={`/profile/${userId}`}>
                  <Button variant="contained" color="primary">
                    Back to profile
                  </Button>
                </Link>
              </Box>
              <Box mt={3} mb={12} className={classes.root}>
                <div className="tab-xs">
                  <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs"
                  >
                    <Tab
                      label="User Information"
                      {...TabProps(0)}
                      disableRipple
                    />
                    <Tab label="My Posts" {...TabProps(1)} disableRipple />
                    <Tab label="My Bookmarks" {...TabProps(2)} disableRipple />
                    <Tab label="My Followings" {...TabProps(3)} disableRipple />
                    <Tab label="My Followers" {...TabProps(4)} disableRipple />
                    <Tab label="My Devices" {...TabProps(5)} disableRipple />
                    <Tab label="My Drafts" {...TabProps(6)} disableRipple />
                  </Tabs>
                  <TabPanel value={value} index={0} className="strech-tab">
                    <UserInfromation />
                  </TabPanel>
                  <TabPanel value={value} index={1} className="strech-tab">
                    <MyPost />
                  </TabPanel>
                  <TabPanel value={value} index={2} className="strech-tab">
                    <MyBookMarks userId={userId} />
                  </TabPanel>
                  <TabPanel value={value} index={3} className="strech-tab">
                    <MyFollowings userId={userId} />
                  </TabPanel>
                  <TabPanel value={value} index={4} className="strech-tab">
                    <MyFollowers userId={userId} />
                  </TabPanel>
                  <TabPanel value={value} index={5} className="strech-tab">
                    <MyDevices userId={userId} />
                  </TabPanel>
                  <TabPanel value={value} index={6} className="strech-tab">
                    <MyDrafts userId={userId} />
                  </TabPanel>
                  
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  };
  return (
    <div className="user-settings-container">
      {renderUserProfile()}
      {renderVerticaleUserTab()}
    </div>
  );
}
