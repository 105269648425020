import React, { useEffect } from "react";
import { getFollowings, unfollowUser } from "redux/actions/userPageAction";
import { useDispatch, useSelector } from "react-redux";
import { userCommentStyle } from "core/theme/makeStyle";
import { Link } from "react-router-dom";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Button,
} from "@material-ui/core";

export default function MyFollowings(props) {
  const { userId } = props;

  const classes = userCommentStyle();
  const dispatch = useDispatch();
  const { userFollowing } = useSelector(
    (state) => state.userDetails
  );

  useEffect(() => {
    if (userId) {
      dispatch(getFollowings(userId));
    }
  }, [dispatch, userId]);

  const renderUserInformation = () => {
    const handleUnfollow = (followerId) => {
      dispatch(unfollowUser(userId, followerId, 1));
    };
    return (
      <React.Fragment>
        <Typography variant="h6">
          <strong>My Followings</strong>
        </Typography>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <List className={classes.root}>
              {userFollowing?.map((following) => (
                <React.Fragment>
                  <ListItem alignItems="flex-start" key={following.id}>
                    <ListItemAvatar>
                    <Link to={`/profile/${following.userId}`}><Avatar alt="user profile" src={following.userAvatar} /></Link>
                    </ListItemAvatar>
                    <ListItemText
                      primary={ <Link to={`/profile/${following.userId}`}>{following.userName}</Link>}
                      secondary={
                        <React.Fragment>{following.location}</React.Fragment>
                      }
                    />
                    <Button
                      variant="outlined" className="btn-spacing"
                      onClick={() => handleUnfollow(following.userId)}
                    >
                      Unfollow
                    </Button>
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              ))}
            </List>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  return <div className="my-post-container">{renderUserInformation()}</div>;
}
