import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreatePost } from "redux/actions/createPostAction";
import { useHistory } from "react-router-dom";
import { toastOpen } from "redux/actions/globalAction";

import {
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  Grid,
  Container,
} from "@material-ui/core";

export default function Suggestion() {
  const [comment, setComment] = useState("");
  const [subject, setSubject] = useState("");
  const dispatch = useDispatch();

  // Global data selector
  const { userProfile } = useSelector((state) => state.userProfile);
  const { userInfo: profileInfo } = userProfile ? userProfile.userProfile : {};
  const { profilePic, userId, firstName, lastName } = profileInfo || "";

  let history = useHistory();

  const handleSuggesions = async () => {
    if (subject.trim() === "" || comment.trim() === "") {
      dispatch(
        toastOpen({
          isToastOpen: true,
          toastMessage: "Please provide a valid input",
          toastVariant: "error",
        })
      );
    } else {
      const suggesionData = {
        postThumbnail: "",
        postTitle: subject,
        postAvatar: profilePic,
        postUserName: firstName + lastName,
        tagName: "Suggestion",
        postContent: comment,
        category: "Suggestion",
        userId: userId,
      };

      await dispatch(CreatePost(suggesionData));
      history.push("/feedback");
    }
  };
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container justify="center" className="fedback-page" spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Box bgcolor="text.primary" p={3} mt={12}>
              <Typography variant="h4" color="secondary">
                <strong>
                  Feature <br />
                  Suggestion
                </strong>
              </Typography>
            </Box>
            <Paper>
              <Box className="section-container" mb={3}>
                <form autoComplete="off">
                  <Box>
                    <Typography variant="subtitle2">
                      <strong>Subject</strong>
                    </Typography>
                    <TextField
                      id="Subject"
                      variant="outlined"
                      onChange={(e) => setSubject(e.target.value)}
                      fullWidth
                    />
                  </Box>
                  <Box mt={2}>
                    <Typography variant="subtitle2">
                      <strong>Comment</strong>
                    </Typography>
                    <TextField
                      id="Comment"
                      variant="outlined"
                      multiline
                      rows={4}
                      onChange={(e) => setComment(e.target.value)}
                      fullWidth
                    />
                  </Box>
                  <Box
                    mt={5}
                    width="100%"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleSuggesions}
                    >
                      Post
                    </Button>
                  </Box>
                </form>
              </Box>
            </Paper>
          </Grid>
          {/* <Grid item xs={2} sm={4} md={4}>
            <Box mt={12}>
              <AddPost />
            </Box>
          </Grid> */}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
