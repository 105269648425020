const intialState = {
  isToastOpen: false,
  isPageLoaderOpen: false,
  toastMessage: undefined,
  toastVariant: undefined,
  showMorePosts: {
    showAllPost : 1,
    showFeedbackPost : 10,
    featurePost : 1,
    trendingPost : 1,
    userPost : 10,
    userBookmarkPost : 10,
    newsPost: 10,
    eventsPost: 10,
    photographyPost: 10,
    pocoXseriesPost: 10,
    pocoMseriesPost: 10,
    pocoCseriesPost: 10,
    pocoFseriesPost: 10
  }
};

export const toastReducer = (state = intialState, action) => {
  switch (action.type) {
    case "TOAST_OPEN":
      return {
        ...state,...action.payload
      };
      case "PAGE_LOAD":
      return {
        ...state,...action.payload
      };
      case "SHOW_MORE_POSTS":
        return {
          ...state,showMorePosts :{...state.showMorePosts,...action.payload}
        }
    default:
      return state;
  }
};

export default toastReducer;
