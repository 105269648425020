import React from "react";

export class ErrorHandler extends React.Component {
  state = {
    hasError: false,
    error: { message: "", stack: "" },
    info: { componentStack: "" },
  };

  static getDerivedStateFromError = (error) => {
    return { hasError: true };
  };

  componentDidCatch = (error, info) => {
    this.setState({ error, info });
    console.log(`Cause: ${error}.\nStackTrace: ${info.componentStack}`);
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    return hasError ? (<h1 style={{'text-align':'center'}}>Something went wrong</h1>) : children;
  }
}
