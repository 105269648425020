import * as actions from "redux/constant/types";

const intialState = {
  searchPostLoad: true,
  searchPostList: [],
};

// Home reducer start
export const searchPostReducer = (state = intialState, action) => {
  switch (action.type) {
      case actions.SEARCH_ITEM_LOAD:
      return {
        ...state,
        searchPostLoad: action.payload,
      };
    case actions.SEARCH_ITEM_SUCCESS:
      return {
        ...state,
        searchPostLoad: false,
        searchPostList: action.payload,
      };
    default:
      return state;
  }
};
