import * as actions from "redux/constant/types";
import { $axios, getCookies, getEncrypt } from "core/utils";
import { toastOpen } from "./globalAction";

export const searchLoad = (payload) => ({
  type: actions.SEARCH_ITEM_LOAD,
  payload,
});

export const searchSucess = (res) => ({
  type: actions.SEARCH_ITEM_SUCCESS,
  payload: res,
});

export const getSearchResult = (userId=0,key = "", size = 10, page = 1) => {
  return async (dispatch) => {
    dispatch(searchLoad(true));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId:loginId = 0 } = cookies ? cookies : "";
    const dataEncrypt = userId + '&' + userId
    await $axios
      .get(`/search/post?size=${size}&page=${page}&key=${key}&userId=${getEncrypt(dataEncrypt)}`, {
        headers: { Authorization: `Bearer ${apiToken}`,
        "Content-Type": "text/json",
        "JSessionID": loginId 
      },
      })
      .then((res) => {
        if (res) {
          dispatch(searchSucess(res.data));
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch(searchLoad(false));
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};
