import axios from "axios";
import axiosRetry from "axios-retry";
import { camelCase } from "lodash";
import CryptoJS from "crypto-js";

//  Funtion to import image or icons from static folder
function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

export const Iconsimport = importAll(
  require.context("static/icons", false, /\.(png|jpe?g|svg)$/)
);

export const Imagesimport = importAll(
  require.context("static/images", false, /\.(png|jpe?g|svg|gif)$/)
);

// Method get path params id
export const getPathParam = () => {
  let url = window.location.pathname;
  let pathParam = url.substring(url.lastIndexOf("/") + 1);
  return pathParam;
};

export const getUrlQueryParam = () => {
  let container = {};
  window.location.search
    .split("&")
    .toString()
    .substr(1)
    .split(",")
    .forEach((item) => {
      container[item.split("=")[0]] = decodeURIComponent(item.split("=")[1])
        ? item.split("=")[1]
        : "No query strings available";
    });
  return container;
};

//To detect moobile device
export const isMobile = [
  "Android",
  "iPhone",
  "SymbianOS",
  "Windows Phone",
  "iPad",
  "iPod",
].some((item) =>
  navigator.userAgent.toUpperCase().includes(item.toUpperCase())
);

export const validateEmailPassword = (statevalue, formValue) => {
  if (formValue.email && formValue.email !== "") {
    //validate Email
    let emailPattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    let passwordPattern = new RegExp(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,32}$/gm
    );
    let tempState = { ...statevalue };

    if (!emailPattern.test(formValue.email)) {
      tempState.isValidEmail = true;
      tempState.errorEmailhelpertext = "Invalid email address";
    } else {
      tempState.isValidEmail = false;
      tempState.errorEmailhelpertext = "";
    }
    //validate Password
    if (!passwordPattern.test(formValue.password)) {
      tempState.isPasswordValid = true;
      tempState.errorPasswordhelpertext = "Invalid password";
    } else {
      tempState.isPasswordValid = false;
      tempState.errorPasswordhelpertext = "";
    }

    if (tempState.isValidEmail === false && tempState.isPasswordValid === false)
      return formValue;

    return tempState;
  }
  return null;
};
// ephoc date convert
export const ephocDateCovert = (epockDate) => {
  const epok = new Date(epockDate * 1000);
  const currentDate = new Date();

  let seconds = Math.floor((currentDate - epok) / 1000);

  let interval = seconds / 31536000;
  // if (interval > 1) {
  //   return Math.floor(interval) === 1
  //     ? Math.floor(interval) + " Year ago"
  //     : Math.floor(interval) + " Years ago";
  // }
  // interval = seconds / 2592000;
  // if (interval > 1) {
  //   return Math.floor(interval) === 1
  //     ? Math.floor(interval) + " Month ago"
  //     : Math.floor(interval) + " Months ago";
  // }
   //  Post 7 days date will be visible
   interval = seconds / 604800;
   if (interval > 1) {
     return getLocalDateFormate(epockDate)
   }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + " Day ago"
      : Math.floor(interval) + " Days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + " Hour ago"
      : Math.floor(interval) + " Hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + " Minute ago"
      : Math.floor(interval) + " Minutes ago";
  }
  return " Just now";
};

// method to check user is logged in or not
export const getCookies = () => {
  const cookies = document.cookie
    .split(";")
    .reduce(
      (ac, cv, i) =>
        Object.assign(ac, { [cv.split("=")[0].trim()]: cv.split("=")[1] }),
      {}
    );
  return cookies;
};

//  method can use for loggin session is active or not
export const isValidToken = () => {
  // get query param from url
  const urlParams = getUrlQueryParam();
  const { token } = urlParams;
  if (token) {
    let now = new Date();
    let minutes = 60;
    now.setTime(now.getTime() + minutes * 60 * 1000);
    const { token = "", userId = "" } = urlParams;
    document.cookie = `token=${token};path=/;expires=${now.toUTCString()}`;
    document.cookie = `userId=${userId};path=/;expires=${now.toUTCString()}`;
  }

  const cookies = getCookies();
  if (cookies) {
    const { token } = cookies;
    if (token) {
      return token.length > 0;
    }
    return false;
  }
};

// method to convert object array key to camel case
export const tocamelCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => tocamelCase(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: tocamelCase(obj[key]),
      }),
      {}
    );
  }
  return obj;
};

// let headers = {};
// const cookies = getCookies();
// const { apiToken, userId = 0 } = cookies ? cookies : "";
// if (apiToken) {
//   headers.Authorization = `Basic ${apiToken}`;
// }

// Base url confiugred in $axios
const baseURL = process.env.REACT_APP_BASE_URL;
export const $axios = axios.create({
  baseURL: baseURL,
});
//  Api fail retry 3 times
axiosRetry($axios, { retries: 3 });

// method for debouce
export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function dateFormater(date, slice) {
  let formateDate = new Date(date);
  return formateDate.toISOString().slice(0, slice);
}

// Role Enum
export const Role = Object.freeze({
  ADMIN: "isAdmin",
  MODETATOR: "isModerator",
  USER: "isUser",
  TEAMMATE: "isTeammate",
});

// get user role
export function getRole(role = "") {
  const roleValue = camelCase(role);
  // const Role = {
  //   Admin: "isAdmin",
  //   Moderator: "isModerator",
  //   User: "isUser",
  //   Teammate: "isTeammate",
  // };

  switch (roleValue) {
    case "roleAdmin":
      return Role.ADMIN;
    case "roleModerator":
      return Role.MODETATOR;
    case "roleUser":
      return Role.USER;
    case "teammates":
      return Role.TEAMMATE;
    default:
      return Role.USER;
  }
}
// method for AES encryption
export const getEncrypt = (string) => {
  
  let encrypted = CryptoJS.AES.encrypt(
    string,
    process.env.REACT_APP_SECURITY_KEY
  ).toString();
  let enc = btoa(encrypted);
  return enc.replace(/['"]+/g, '');
};

//orientation check
export const getLandscapeOrientation = () => {
  if (window.innerHeight > window.innerWidth) {
    return false;
  }
  return true;
};

export const staticCategory = [
  "News",
  "Events",
  "Feature",
  "MIUI",
  "ROM",
  "Photography",
  "Tech",
  "Resources",
  "POCO X Series",
  "POCO M Series",
  "POCO C Series",
  "POCO F Series",
];

export const staticReportOption = [
  "Spam",
  "Advertisement",
  "Intellectual property violations",
  "I just don't like it",
  "Hate Speech or Symbols",
  "False Information",
  "Scam or Fraud",
  "Racist Language or activity",
  "Bullying or Harassment",
  "Nudity or Sexual Activity",
  "Violence or dangerous organizations",
  "Suicide or Self Injury",
  "Other",
];

export const getLocalDateFormate=(date)=>{
  return new Date(date * 1000).toDateString().slice(4)
}

export const isYoutubeVideo=(url)=>{
  let match = url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) ||
  url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/) ||
  url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\\&\\?]*).*/);

  return match;
}

