import * as actions from "redux/constant/types";

const intialState = {
  userProfileLoad: false,
  userProfile: undefined,
  topRankingUsersLoad: false,
  topRankingUsers: undefined,
  isLoggedinUser: false,
  otherUserProfile: undefined,
  userId: 0,
};

// User reducer start
export const userProfileReducer = (state = intialState, action) => {
  switch (action.type) {
    // User profile case
    case actions.USER_PROFILE_LOAD:
      return {
        ...state,
        userProfileLoad: true,
      };

    case actions.USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfileLoad: false,
        userProfile: action.payload,
      };
    
    case actions.USER_OTHER_PROFILE_SUCCESS:
      return {
        ...state,
        otherUserProfile: action.payload,
      };

    // Top ranking user case
    case actions.RANKING_USERS_LOAD:
      return {
        ...state,
        topRankingUsersLoad: true,
      };

    case actions.RANKING_USERS_SUCCESS:
      return {
        ...state,
        topRankingUsersLoad: false,
        topRankingUsers: action.payload,
      };

    case actions.USER_IS_LOGGEDIN:
      return {
        ...state,
        isLoggedinUser: action.payload,
      };

    default:
      return state;
  }
};
