import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserStatus,
  getUserLogs,
  banActiveUsers,
  clearReportedUses,
} from "redux/actions/cmsAdminUserManagement";
import {
  Container,
  ButtonGroup,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { tableStyle } from "core/theme/makeStyle";
import Modal from "components/cmsAdmin/components/modal";
import ViewLog from "components/cmsAdmin/components/logModal";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

// Active tab
const columns = [
  { id: "userName", label: "Username", minWidth: 170 },
  { id: "userId", label: "User Id", minWidth: 100 },
  {
    id: "email",
    label: "Email Id",
    minWidth: 170,
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "left",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "left",
  },
  {
    id: "log",
    label: "Logs",
    minWidth: 170,
    align: "left",
  },
];

// Reported tab
const reportedColumns = [
  { id: "userName", label: "Username", minWidth: 170 },
  { id: "userId", label: "User Id", minWidth: 100 },
  {
    id: "email",
    label: "Email Id",
    minWidth: 170,
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "left",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "left",
  },
  {
    id: "log",
    label: "Logs",
    minWidth: 170,
    align: "left",
  },
];

const bannedColumns = [
  { id: "userName", label: "Username", minWidth: 170 },
  { id: "userId", label: "User Id", minWidth: 100 },
  {
    id: "email",
    label: "Email Id",
    minWidth: 170,
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "left",
  },
  {
    id: "comments",
    label: "Comments",
    minWidth: 170,
    align: "left",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "left",
  },
  {
    id: "log",
    label: "Logs",
    minWidth: 170,
    align: "left",
  },
];

// Type Enum
const Type = Object.freeze({
  BANUSER: "ban",
  CLEARREPORT: "clearReport",
  REACTIVE: "reactivate",
  BANNED: "BANNED",
  RESTORED: "RESTORED",
  CLEAR_REPORT: "CLEAR_REPORT",
});

// Type Enum
const TabEnum = Object.freeze({
  ACTIVE: "active",
  REPORTED: "reported",
  BANNED: "banned",
});

export default function UserStatus(props) {
  const classes = tableStyle();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentActiveBtn, setcurrentActiveBtn] = useState(1);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(1);
  const [banUser, setBanUser] = useState({
    type: "",
    comment: "",
    banUserId: 0,
  });
  // Get User status List
  const {
    userStatusList: { userList = [],totalCount = 0 },
    
  } = useSelector((state) => state.cmsAdminUserModul);

  // Get User status List
  const { userLogs = [] } = useSelector((state) => state.cmsAdminUserModul);
  const { userProfile: { userProfile: { userId } } = {} } = useSelector(
    (state) => state.userProfile
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserStatus(TabEnum.ACTIVE));
  }, [dispatch]);

  const rowCreateReported = (userName, userId, email, status, action, log) => {
    return { userName, userId, email, status, action, log };
  };
  const rowCreateActive = (userName, userId, email, status, action, log) => {
    return { userName, userId, email, status, action, log };
  };
  const rowCreateBanned = (
    userName,
    userId,
    email,
    status,
    comments,
    action,
    log
  ) => {
    return { userName, userId, email, status, comments, action, log };
  };

  // open modal
  const handleClickOpen = (ban, user) => {
    setOpen(true);
    setBanUser({ ...banUser, type: ban, banUserId: user });
  };

  const handleviewOpen = (userId) => {
    dispatch(getUserLogs(userId));
    setViewOpen(true);
  };

  const viewClose = () => {
    setViewOpen(false);
  };
  // modal close
  const handleClose = () => {
    setOpen(false);
    setBanUser({    
    type: "",
    comment: "",
    banUserId: 0,})
  };

  const handleChangePage = (event, newPage) => {
    const pageCount = newPage === 0 ? 1 : newPage + 1;
    if (tabIndex === 1) {
      dispatch(getUserStatus(TabEnum.ACTIVE, pageCount));
    } else if (tabIndex === 2) {
      dispatch(getUserStatus(TabEnum.REPORTED, pageCount));
    } else if (tabIndex === 3) {
      dispatch(getUserStatus(TabEnum.BANNED, pageCount));
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // call Api on tab change
  const handleBtn = (value) => {
    if (value === 1) {
      dispatch(getUserStatus(TabEnum.ACTIVE));
      setPage(0);
      setTabIndex(1);
    } else if (value === 2) {
      dispatch(getUserStatus(TabEnum.REPORTED));
      setPage(0);
      setTabIndex(2);
    } else if (value === 3) {
      dispatch(getUserStatus(TabEnum.BANNED));
      setPage(0);
      setTabIndex(3);
    }
    setcurrentActiveBtn(value);
  };

  // get value of modals
  const getValue = (value) => {
    setBanUser({ ...banUser, comment: value });
  };

  // User Ban method
  const confirmation = () => {
    if (banUser.comment.length > 1) {
      banUser.type === Type.BANUSER &&
        dispatch(
          banActiveUsers(banUser.banUserId, banUser.type, banUser.comment)
        );
      banUser.type === Type.REACTIVE &&
        dispatch(
          banActiveUsers(banUser.banUserId, banUser.type, banUser.comment)
        );
      banUser.type === Type.CLEARREPORT &&
        dispatch(clearReportedUses(banUser.banUserId, banUser.comment));
      handleClose();
    }
  };
  // Active tab data
  const activeData = userList?.map((ele) =>
    rowCreateActive(
      ele.firstName + " " + ele.lastName,
      ele.userId,
      ele.email,
      "Active",
      userId !== ele.userId && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleClickOpen(Type.BANUSER, ele.userId)}
        >
          Ban
        </Button>
      ),
      <Button
        variant="text"
        color="primary"
        onClick={() => handleviewOpen(ele.userId)}
      >
        View
      </Button>
    )
  );

  // Reported tab data
  const reportedData = userList?.map((ele) =>
    rowCreateReported(
      ele.firstName + " " + ele.lastName,
      ele.userId,
      ele.email,
      "Report",
      <React.Fragment>
        {userId !== ele.userId && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleClickOpen(Type.BANUSER, ele.userId)}
          >
            Ban
          </Button>
        )}
        &nbsp;
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleClickOpen(Type.CLEARREPORT, ele.userId)}
        >
          Clear Report
        </Button>
      </React.Fragment>,
      <Button
        variant="text"
        color="primary"
        onClick={() => handleviewOpen(ele.userId)}
      >
        View
      </Button>
    )
  );

  // Banned tab data
  const bannedRows = userList?.map((ele) =>
    rowCreateBanned(
      ele.firstName + " " + ele.lastName,
      ele.userId,
      ele.email,
      "Banned",
      ele.comment,
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleClickOpen(Type.REACTIVE, ele.userId)}
      >
        Reactive
      </Button>,
      <Button
        variant="text"
        color="primary"
        onClick={() => handleviewOpen(ele.userId)}
      >
        View
      </Button>
    )
  );

  return (
    <React.Fragment>
      <Container>
        <Box mb={3}>
          <ButtonGroup
            size="large"
            color="primary"
            aria-label="large outlined primary button group"
          >
            <Button
              variant={currentActiveBtn === 1 ? "contained" : "outlined"}
              color={currentActiveBtn === 1 ? "secondary" : "primary"}
              onClick={() => handleBtn(1)}
            >
              Active
            </Button>
            <Button
              variant={currentActiveBtn === 2 ? "contained" : "outlined"}
              color={currentActiveBtn === 2 ? "secondary" : "primary"}
              onClick={() => handleBtn(2)}
            >
              Reported
            </Button>
            <Button
              variant={currentActiveBtn === 3 ? "contained" : "outlined"}
              color={currentActiveBtn === 3 ? "secondary" : "primary"}
              onClick={() => handleBtn(3)}
            >
              Banned
            </Button>
          </ButtonGroup>
        </Box>
        {currentActiveBtn === 1 && (
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="a dense table" size="small">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activeData.map((row) => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
            />
            <Modal
              title="Ban User"
              open={open}
              handleClose={handleClose}
              getValue={(val) => getValue(val)}
              confirm={confirmation}
            />
            <ViewLog title="View Logs" open={viewOpen} close={viewClose}>
              <Box height={200}>
                {userLogs &&
                userLogs.reportLogs &&
                userLogs.reportLogs.length > 0 ? (
                  userLogs.reportLogs.map((element) => (
                    <React.Fragment>
                      <List dense>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography
                                variant="span"
                                className="text-capitalize"
                              >
                                {(element.type === Type.BANNED
                                  ? "Banned by "
                                  : element.type === Type.RESTORED
                                  ? "Restored by "
                                  : "Report cleared by ") + element.reportedBy}
                              </Typography>
                            }
                            secondary={
                              " Comments: " +
                              element.comment +
                              " Created Time: " +
                              new Date(element.updatedTime * 1000).toLocaleString()
                            }
                          />
                          <Divider />
                        </ListItem>
                      </List>
                    </React.Fragment>
                  ))
                ) : userLogs &&
                  userLogs.reportLogs &&
                  userLogs.reportLogs.length === 0 ? (
                  <Typography variant="h6" align="center">
                    {userLogs.message}
                  </Typography>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100%"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            </ViewLog>
          </Paper>
        )}
        {currentActiveBtn === 2 && (
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="a dense table" size="small">
                <TableHead>
                  <TableRow>
                    {reportedColumns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportedData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                          {reportedColumns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
            />
            <Modal
              title="Clear Report"
              open={open}
              handleClose={handleClose}
              getValue={(val) => getValue(val)}
              confirm={confirmation}
            />
            <ViewLog title="View Logs" open={viewOpen} close={viewClose}>
              <Box height={200}>
                {userLogs &&
                userLogs.reportLogs &&
                userLogs.reportLogs.length > 0 ? (
                  userLogs.reportLogs.map((element) => (
                    <React.Fragment>
                      <List dense>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography
                                variant="span"
                                className="text-capitalize"
                              >
                                {(element.type === Type.BANNED
                                  ? "Banned by "
                                  : element.type === Type.RESTORED
                                  ? "Restored by "
                                  : "Report cleared by ") + element.reportedBy}
                              </Typography>
                            }
                            secondary={
                              " Comments: " +
                              element.comment +
                              " Created Time: " +
                              new Date(element.updatedTime * 1000).toLocaleString()
                            }
                          />
                          <Divider />
                        </ListItem>
                      </List>
                    </React.Fragment>
                  ))
                ) : userLogs &&
                  userLogs.reportLogs &&
                  userLogs.reportLogs.length === 0 ? (
                  <Typography variant="h6" align="center">
                    {userLogs.message}
                  </Typography>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100%"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            </ViewLog>
          </Paper>
        )}
        {currentActiveBtn === 3 && (
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="a dense table" size="small">
                <TableHead>
                  <TableRow>
                    {bannedColumns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bannedRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                          {bannedColumns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
            />
            <Modal
              title="Reactive User"
              open={open}
              handleClose={handleClose}
              getValue={(val) => getValue(val)}
              confirm={confirmation}
            />
            <ViewLog title="View Logs" open={viewOpen} close={viewClose}>
              <Box height={200}>
                {userLogs &&
                userLogs.reportLogs &&
                userLogs.reportLogs.length > 0 ? (
                  userLogs.reportLogs.map((element) => (
                    <React.Fragment>
                      <List dense>
                        <ListItem key={element.id}>
                          <ListItemText
                            primary={
                              <Typography
                                variant="span"
                                className="text-capitalize"
                              >
                                {(element.type === Type.BANNED
                                  ? "Banned by "
                                  : element.type === Type.RESTORED
                                  ? "Restored by "
                                  : "Report cleared by ") + element.reportedBy}
                              </Typography>
                            }
                            secondary={
                              " Comments: " +
                              element.comment +
                              " Created Time: " +
                              new Date(element.updatedTime * 1000).toLocaleString()
                            }
                          />
                          <Divider />
                        </ListItem>
                      </List>
                    </React.Fragment>
                  ))
                ) : userLogs &&
                  userLogs.reportLogs &&
                  userLogs.reportLogs.length === 0 ? (
                  <Typography variant="h6" align="center">
                    {userLogs.message}
                  </Typography>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100%"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            </ViewLog>
          </Paper>
        )}
      </Container>
    </React.Fragment>
  );
}
