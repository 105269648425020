import React, { useEffect } from "react";
import { getFollowers, unfollowUser } from "redux/actions/userPageAction";
import { useDispatch, useSelector } from "react-redux";
import { userCommentStyle } from "core/theme/makeStyle";
import { Link } from "react-router-dom";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Button,
} from "@material-ui/core";

export default function MyFollowers(props) {
  const { userId } = props;
  const classes = userCommentStyle();

  const dispatch = useDispatch();
  const { userFollowers } = useSelector((state) => state.userDetails);
  useEffect(() => {
    if (userId) {
      dispatch(getFollowers(userId));
    }
  }, [dispatch, userId]);

  const renderUserInformation = () => {
    const handleRemove = (followerId) => {
      dispatch(unfollowUser(userId, followerId));
    };
    return (
      <React.Fragment>
        <Typography variant="h6">
          <strong>My Followers</strong>
        </Typography>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <List className={classes.root}>
              {userFollowers?.map((followers) => (
                <React.Fragment>
                  <ListItem alignItems="flex-start" key={followers.id}>
                    <ListItemAvatar>
                      <Link to={`/profile/${followers.userId}`}>
                        <Avatar alt="user profile" src={followers.userAvatar} />
                      </Link>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<Link to={`/profile/${followers.userId}`}>{followers.userName}</Link>}
                      secondary={
                        <React.Fragment>{followers.location}</React.Fragment>
                      }
                    />
                    <Button 
                      variant="outlined" className="btn-spacing"
                      onClick={() => handleRemove(followers.userId)}
                    >
                      Remove
                    </Button>
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              ))}
            </List>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  return <div className="my-post-container">{renderUserInformation()}</div>;
}
