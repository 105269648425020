import React, { useState, useRef, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useHistory, Prompt } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PostEditor } from "../elements/postEditor";
import {
  CreatePost,
  fileUpload,
  UpdatePost,
} from "redux/actions/createPostAction";
import { getUserProfile } from "redux/actions/userProfileAction";
import { toastOpen } from "redux/actions/globalAction";
import { getpostDetails, getPostAttachment } from "redux/actions/postDetailsAction";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Paper,
  FormControl,
  Select,
  InputLabel,
  // Card,
  // CardContent
} from "@material-ui/core";
import Images from "static/images/fdsImages";
import "./index.scss";
import { staticCategory } from "core/utils";
import { setAutoSavePostId } from "redux/actions/createPostAction";
import BorderColorIcon from "@material-ui/icons/BorderColor";
// import AttachFileIcon from '@material-ui/icons/AttachFile';
// import CloseIcon from '@material-ui/icons/Close';
import Compressor from "compressorjs";

export default function UseraddPost(props) {
  const { postId, isPostEdiatble, isfromUserFlow, isfromDraft } =
    props?.location.state || {};
  // Thumbnail placeholer image
  const thumbnailPlaceHolder = Images.thumbnailPlaceHolder;
  const [selctBoxValue, setSelctBoxValue] = useState({});
  const [uploadPostThumbnail, setuploadPostThumbnail] =
  useState(thumbnailPlaceHolder);
  const [thumbnailLoad, setThumbnailLoad] = useState(null);
  const [postImgLoader, setpostImgLoader] = useState(false);
  const [postTitle, setPostTitle] = useState("");
  const [postEditorValue, setPostEditorValue] = useState("");
  const [uploadFileName, setUploadFileName] = useState("");
  const [open, setOpen] = useState(true);
  const [startTimer, setTimerStart] = useState(false);
  const [manualSave, setManualSave] = useState(0)

  const dispatch = useDispatch();

  // let postPreview = useRef();
  // method to push component
  let history = useHistory();

  // Global data selector
  const { userProfile } = useSelector((state) => state.userProfile);
  const { userInfo: profileInfo } = userProfile ? userProfile.userProfile : {};
  const { profilePic, userId, firstName, lastName } = profileInfo || "";

  // Post edit code start
  const { postContent, autoSavePostResponse } = useSelector(
    (state) => state.postDetails
  );
  const postDetails = postContent?.postContent;
  const postTitile = postContent?.postTitle;
  const category = postContent?.category;
  const postThumbnail = postContent?.postThumbnail;
  const savedPostId = autoSavePostResponse?.id;
  const postCreatedDate = autoSavePostResponse?.updatedAt;

  //  Mobile footer bug fix
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      let elem = document.getElementsByClassName("mui-fixed");
      if (elem.length > 2) {
        elem[1].style.display = "block";
      }
    };
  }, []);

  
  // Get Post details
  useEffect(() => {
    if (postId) {
      dispatch(getpostDetails(postId, userId));
    }
    return () => {
      dispatch(setAutoSavePostId({}));
    };
  }, [dispatch, postId, userId]);

  // Post prepopulated data
  useEffect(() => {
    if (postId) {
      setPostTitle(isPostEdiatble && unescape(postTitile));
      setPostEditorValue(isPostEdiatble && unescape(postDetails));
      setSelctBoxValue(isPostEdiatble && { name: category });
      setuploadPostThumbnail(isPostEdiatble && postThumbnail);
    }
  }, [
    category,
    dispatch,
    isPostEdiatble,
    postDetails,
    postId,
    postThumbnail,
    postTitile,
    userId,
  ]);

  // Auto save feature, while setInterval state will be clear so passing state value as dependency and passing to autoSave method.
  //  without dependecy all state value will be null
  useEffect(() => {
    // Post attachments
    dispatch(getPostAttachment());

    postEditorValue.length > 8 && setTimerStart(true);
    var autoSaveInterval =
      startTimer &&
      setInterval(() => {
        autoSave(
          true,
          postTitle,
          selctBoxValue.name,
          uploadPostThumbnail,
          savedPostId
        );
      }, 300000);
    return () => {
      clearInterval(autoSaveInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    postEditorValue.length,
    postTitle,
    selctBoxValue,
    uploadPostThumbnail,
    savedPostId,
  ]);

  // Code to fix manual save update flow
  useEffect(()=>{
    setManualSave(savedPostId)
  },[savedPostId])
  // Target to post edit
  let getPostvalue = useRef();

  // Method on change editor data seting to state
  const editContent = (content) => {
    setPostEditorValue(content);
  };

  // Method to upload Cover image
  const uploadpostThumbnail = async ({ target }) => {
    // let imagebase64 = "";
    var file = target.files[0];
    setUploadFileName(file.name);
    if (file.size <= 4200000) {
      setThumbnailLoad(true);
      new Compressor(file, {
        quality: 0.6,
        success: async function (result) {
          let blobToFile = new File([result], result.name);
          const Thumbnail = await fileUpload(blobToFile);
          const thumbnailImg = Thumbnail?.[0].url || uploadPostThumbnail;
          // upload file response adding to state
          setuploadPostThumbnail(thumbnailImg);
          setThumbnailLoad(false);
        },
        error(err) {
          console.log(err.message);
        },
      });
    } else {
      dispatch(
        toastOpen({
          isToastOpen: true,
          toastMessage: "Thumbnail size should be less than 4mb",
          toastVariant: "error",
        })
      );
    }
  };

  // Method to get post editor value
  const postSubmit = async () => {
    // console.log(getPostvalue.current)
    const editor = getPostvalue.current.getEditor();
    const unprivilegedEditor =
      getPostvalue.current.makeUnprivilegedEditor(editor);
    // unprivilegedEditor.getHTML() to get editor content as HTML
    // postPreview.current.innerHTML = postEditorValue;
    const postContent = unprivilegedEditor.getHTML();
    // final structure to create post
    const postData = {
      postThumbnail: uploadPostThumbnail,
      postTitle: escape(postTitle),
      postAvatar: profilePic,
      postUserName: firstName + lastName,
      tagName: selctBoxValue.name,
      postContent: escape(postContent),
      category: selctBoxValue.name,
      userId: userId,
      isDraft: false,
    };
    // finalObjecttoPost Object will be push to API
    const postContentSize = new Blob([postContent]).size;

    if (postContentSize > 1000000) {
      dispatch(
        toastOpen({
          isToastOpen: true,
          toastMessage: "Excced Post Size Max 1mb",
          toastVariant: "error",
        })
      );
    } else if (
      unescape(postData.postTitle).trim() === "" ||
      postData.category === undefined ||
      (unescape(postData.postContent)
        .replace(/<[^>]*>/g, "")
        .trim() === ""
        ? !(
            unescape(postData.postContent).includes("img") ||
            unescape(postData.postContent).includes("iframe")
          )
        : false)
    ) {
      dispatch(
        toastOpen({
          isToastOpen: true,
          toastMessage:
            "Post title, Post content and Category Should not be empty",
          toastVariant: "error",
        })
      );
    } else {
      // if isPostEdiatble true post will update else create new post
      setOpen(false);
      if (isPostEdiatble || savedPostId) {
        await dispatch(
          UpdatePost(savedPostId ? savedPostId : postId, postData)
        );
        // history.push(isfromUserFlow ? `/settings/${userId}` : "/admin")
        // if draft id exisit move to home else move to user setting
        savedPostId
          ? history.push({ pathname: "/Home" })
          : history.push({
              pathname: isfromUserFlow ? `/settings/${userId}` : "/admin",
              state: { activeTab: 1 },
            });
      } else {
        await dispatch(CreatePost(postData));
        history.push("/");
      }

      dispatch(getUserProfile(userId));
    }
  };
  // Auto save method start
  const autoSave = async (
    isAutoSave = false,
    title = postTitle,
    category = selctBoxValue.name,
    thumbnail = uploadPostThumbnail,
    savedPostId
  ) => {
    const editor = getPostvalue.current.getEditor();
    const unprivilegedEditor =
      getPostvalue.current.makeUnprivilegedEditor(editor);
    // unprivilegedEditor.getHTML() to get editor content as HTML
    // postPreview.current.innerHTML = postEditorValue;
    const postContent = unprivilegedEditor.getHTML();
    // final structure to create
    const postData = {
      postThumbnail: thumbnail,
      postTitle: escape(title),
      postAvatar: profilePic,
      postUserName: firstName + lastName,
      tagName: category,
      postContent: escape(postContent),
      category: category,
      userId: userId,
      isDraft: true,
    };
    // finalObjecttoPost Object will be push to API
    const postContentSize = new Blob([postContent]).size;
    // Draft and Auto save logic flow
    const flows = {
      isDraftFlow: true,
      autoSave: true,
    };
    if (postContentSize > 1000000) {
      dispatch(
        toastOpen({
          isToastOpen: true,
          toastMessage: "Excced Post Size Max 1mb",
          toastVariant: "error",
        })
      );
    } else {
      // Auto save flow
      if (isAutoSave) {
        if (isPostEdiatble || savedPostId) {
          dispatch(
            UpdatePost(
              savedPostId ? savedPostId : postId,
              postData,
              flows.isDraftFlow,
              flows.autoSave
            )
          );
          console.log("Save Api call edit flow");
        } else {
          console.log("Save Api call create flow");
          dispatch(CreatePost(postData, flows.isDraftFlow, flows.autoSave));
        }
      } else {
        // saved button flow
        if (isPostEdiatble || manualSave) {
          dispatch(
            UpdatePost(
              manualSave ? manualSave : postId,
              postData,
              flows.isDraftFlow
            )
          );
          console.log("Save Api call edit flow");
        } else {
          console.log("Save Api call create flow");
          dispatch(CreatePost(postData, flows.isDraftFlow));
        }
      }
    }
  };

  const handlePostLoader = (load) => {
    setpostImgLoader(load);
  };

  const slectCategory = function (event) {
    setSelctBoxValue({
      ...selctBoxValue,
      name: event.target.value,
    });
  };

  // Cover image upload component
  const renderThumbnailUpload = () => {
    return (
      <Grid
        container
        className="add-post-thumbnail"
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item md={8}>
          <Grid container>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                p={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                className="upload-container"
              >
                <Typography variant="h5">Cover Image</Typography>
                <Box
                  mt={1}
                  p={3}
                  pt={4}
                  pb={4}
                  className="upolad-thumbnail"
                  height="45px"
                >
                  <input
                    accept="image/x-png,image/jpeg"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    value=""
                    onChange={(e) => uploadpostThumbnail(e)}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="secondary"
                      component="span"
                      size="large"
                    >
                      Upload
                    </Button>
                  </label>
                  <Box mt={1}>
                    <Typography variant="body2" color="textPrimary">
                      {uploadFileName}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Paper>
                <Box height="195px">
                  <Grid container>
                    <Grid item md={12}>
                      <Box
                        p={2}
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                      >
                        <Typography variant="subtitle1">
                          Select your post Category
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        p={3}
                      >
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel htmlFor="grouped-selec">
                            Select Category
                          </InputLabel>
                          <Select
                            native
                            label="grouped-selec"
                            id="Select-Category"
                            onChange={slectCategory}
                          >
                            {!isPostEdiatble && (
                              <option
                                aria-label="None"
                                value=""
                                selected
                                disabled
                              />
                            )}
                            {staticCategory.map((categoryItem) => (
                              <option
                                value={categoryItem}
                                selected={
                                  category === categoryItem ? true : false
                                }
                              >
                                {categoryItem}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          {thumbnailLoad ? <LinearProgress /> : null}
        </Grid>
      </Grid>
    );
  };

  // const handleAttachment = async ({ target }) => {
  //   // let imagebase64 = "";
  //   var file = target.files[0];
  //   if (file.size <= 50000000) {
  //     let blobToFile = new File([file], file.name);
  //     fileUpload(blobToFile);   
  //   } else {
  //     dispatch(
  //       toastOpen({
  //         isToastOpen: true,
  //         toastMessage: "File size exceed",
  //         toastVariant: "error",
  //       })
  //     );
  //   }
  // };

  // const handleDelete = () =>{
  //   console.log('as')
  // }
  // const renderAttachment = () => {
  //   return(
  //     <React.Fragment>
  //       <Box display="flex">
  //       <Box mb={2} className="attachment-btn">
  //         <input accept="image/*" id="contained-button-file1" type="file" value="" 
  //         style={{'display':'none'}} 
  //         onChange={(e)=> handleAttachment(e)}/>
  //         <label htmlFor="contained-button-file1" >
  //           <Button variant="contained" startIcon={<AttachFileIcon />} component="span">
  //             Attachment
  //           </Button>
  //         </label>
  //      </Box>
  //      <Box pl={3}>
  //       <Typography variant="body1"> Max file size: 50mb</Typography>
  //       <Typography  variant="body1"> Supported files: ppt, zip, pdf, excel</Typography>
  //     </Box>
  //     </Box>
  //     <Box display={"flex"} mb={3} className="attachment-section">
  //       {attachments.map((item)=> 
  //       <Box width={300} mr={2}>
  //       <Card>
  //           <CardContent>
  //             <Box display={'flex'} justifyContent={'space-between'}>
  //               <strong>{item.fileName}</strong>
  //               <CloseIcon onClick={handleDelete}/>
  //             </Box>
  //           </CardContent>
  //       </Card>
  //       </Box>
  //       )}

  //     </Box>
  //     </React.Fragment>
  //   )
  // }

  const handleEditorFocus = () => {
    let postTitle = document.getElementsByClassName("post_title");
    let editorElement = document.getElementsByClassName("ql-editor");
    if (
      editorElement[0] === document.activeElement ||
      postTitle[0].childNodes[1].childNodes[0] === document.activeElement
    ) {
      let elem = document.getElementsByClassName("mui-fixed");
      elem[1].style.display = "none";
    }
  };

  const handleBlur = () => {
    let postTitle = document.getElementsByClassName("post_title");
    let element = document.getElementsByClassName("ql-editor");
    if (
      element[0] !== document.activeElement ||
      postTitle[0].childNodes[1].childNodes[0] === document.activeElement
    ) {
      let elem = document.getElementsByClassName("mui-fixed");
      elem[1].style.display = "block";
    }
  };

  const handleTitleUpdate = (e) => {
    setPostTitle(e.target.value);
  };

  
  const postEditorHeader = (
    <Box className="save-post-section">
      <Box p={3} display="flex" pb={0}>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <Typography variant="h5" color="secondary">
              {isPostEdiatble ? "Edit post" : "Add post"}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Button variant="outlined" onClick={() => autoSave(false)}>
                Save
              </Button>{" "}
              &nbsp; &nbsp;
              <Button variant="outlined" onClick={postSubmit}>
                {isPostEdiatble ? isfromDraft ? "Post" : "Update": "Post"}
                
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Box
            display="flex"
            className="view-draft-header"
            justifyContent="flex-end"
            alignItems="center"
            bgcolor={"white"}
            mt={5}
            p={2}
          >
            {postCreatedDate && (
              <Box
               className="auto-draft-text"
                p={1}
                mr={2}
              >
                {`Draft autosaved at ${new Date(
                  postCreatedDate * 1000
                ).toLocaleTimeString()}`}
              </Box>
            )}
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<BorderColorIcon fontSize="small" />}
              onClick={() =>
                history.push({
                  pathname: `/settings/${userId}`,
                  state: { activeTab: 6 },
                })
              }
            >
              View Drafts
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
  
  return (
    <React.Fragment>
      <Box mt={8} className="post-wrapper">
        <Grid container justify="center" className="post-container">
          <Grid item xs={12} sm={12} md={12}>
            {postEditorHeader}
          </Grid>
          <Grid item xs={10} sm={10} md={10}>
            <div className="post-form">
              {renderThumbnailUpload()}
              <TextField
                id="post-title"
                label="Type your title here…"
                size="medium"
                variant="filled"
                inputProps={{ maxLength: 500 }}
                onChange={handleTitleUpdate}
                autoComplete="false"
                fullWidth
                onFocus={handleEditorFocus}
                onBlur={handleBlur}
                className="post_title"
                value={postTitle}
              />
              {postImgLoader ? <LinearProgress /> : null}
              <PostEditor
                postRef={getPostvalue}
                content={postEditorValue}
                contentUpdate={editContent}
                loader={handlePostLoader}
                handleFocus={handleEditorFocus}
                handleBlur={handleBlur}
              />
              {/* {renderAttachment()} */}
            </div>
          </Grid>
        </Grid>
      </Box>
      <Prompt
        when={
          (postEditorValue.length > 6 &&
          open)
        }
        message={"Are you sure you want to leave?"}
      />
    </React.Fragment>
  );
}
