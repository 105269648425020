import React,{useState} from "react";
import {
  Typography,
  Container,
  Grid,
  Tab,
  Tabs,
  Box,
  Paper,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { getRole, Role } from "core/utils";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TeamsList from "components/cmsAdmin/pages/userManagement/teamsList";
import UserStatus from "components/cmsAdmin/pages/userManagement/userStatus";

import "./index.scss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    width: 100 + "%",
    [theme.breakpoints.down("xs")]: {
      marginTop: 56,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 56,
    },
    "@media (min-width: 1280px)": {
      marginTop: 69,
    },
  },
  appbar: {
    backgroundColor: "rgba(0, 0, 0, 0.9)",
  },
}));

export default function UserManagement() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  // const {tabIndex} = useSelector(state => state.cmsAdminUserModul);
  // const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // dispatch(handleIndexChange(newValue));
  };

  const handleChangeIndex = (index) => {
    setValue(index);
    // dispatch(handleIndexChange(index));
  };

  // Get User profile details
  const { userProfile = {} } = useSelector(
    (state) => state.userProfile?.userProfile || {}
  );

  // Find Role user is admin
  const isAdmin = Role.ADMIN === getRole(userProfile?.userInfo?.role);

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} md={12}>
        <div className={classes.root}>
          <Paper
            position="static"
            color="default"
            className={classes.appbar}
            square
          >
            <Container>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="secondary"
                aria-label="full width tabs"
                disableRipple={true}
                disableFocusRipple={true}
              >
                <Tab label="Teams" {...a11yProps(0)} />
                {isAdmin && <Tab label="User Status" {...a11yProps(1)} />}
              </Tabs>
            </Container>
          </Paper>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <TeamsList />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <UserStatus />
            </TabPanel>
          </SwipeableViews>
        </div>
      </Grid>
    </React.Fragment>
  );
}
