import * as actions from "redux/constant/types";

const intialState = {
  homeItemsLoad: false,
  homeItems: undefined,
  trendingPostsLoad: true,
  trendingPosts: undefined,
};

// Home reducer start
export const homeItemReducer = (state = intialState, action) => {
  switch (action.type) {
    case actions.HOME_ITEMS_LOAD:
      return {
        ...state,
        homeItemsLoad: true,
      };

    case actions.HOME_ITEMS_SUCCESS:
      return {
        ...state,
        homeItemsLoad: false,
        homeItems: action.payload,
      };
    case actions.TRENDING_ITEMS_LOAD:
      return {
        ...state,
        trendingPostLoad: action.payload,
      };
    case actions.TRENDING_ITEMS_SUCCESS:
      const { trendingPosts } = state;
      const { tagData } = action.payload;
      if (trendingPosts?.tagData) {
        let ids = trendingPosts.tagData.map((item) => item.id);
        let result = tagData.filter((item) => !ids.includes(item.id));
        action.payload.tagData = trendingPosts.tagData.concat(result);
      }
      return {
        ...state,
        trendingPostLoad: false,
        trendingPosts: action.payload,
      };
    default:
      return state;
  }
};
