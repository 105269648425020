import * as actions from "redux/constant/types";
import { toastOpen, isPageLoad } from "./globalAction";
import { getCookies, $axios, getEncrypt } from "core/utils";

export const teamsInfo = (payload) => ({
  type: actions.GET_TEAMS_INFO,
  payload,
});

export const userList = (payload) => ({
  type: actions.GET_USER_LIST,
  payload,
});

export const userStatusList = (payload) => ({
  type: actions.GET_USER_STATUS,
  payload,
});

export const userLogs = (payload) => ({
  type: actions.GET_USER_LOGS,
  payload,
});

export const clearStateValues = () => ({
  type: actions.CLEAR_LIST_STATE,
});


export const getTeamsInfo = (category = "News") => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";
    // const dataEncrypt = userId + "&" + userId;
    await $axios
      .get(`/api/poco/user/admin/teams?category=${category}`, {
        headers: { Authorization: `Bearer ${apiToken}`, JSessionID: userId },
      })
      .then((res) => {
        if (res) {
          dispatch(teamsInfo(res.data));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
        }
      });
  };
};

// Community all active users
export const getActiveUserList = (searchKey = '') => {
  return async (dispatch) => {
    // dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";
    // const dataEncrypt = userId + "&" + userId;
    await $axios
      .get(`/search/user?size=100&page=1&key=${searchKey}&role=nonadmin`, {
        headers: { Authorization: `Bearer ${apiToken}`,
        "Content-Type": "text/json",
         JSessionID: userId },
      })
      .then((res) => {
        if (res) {
          dispatch(userList(res.data));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
        }
      });
  };
};

// User status list
export const getUserStatus = (status = "", page = 1) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    dispatch(clearStateValues());
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";
    // const dataEncrypt = userId + "&" + userId;
    const payloadData = { status, page, size: 10 };

    await $axios
      .post(
        `/api/poco/user/admin/all`,
        getEncrypt(JSON.stringify(payloadData)),
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
            "Content-Type": "text/json",
            JSessionID: userId,
          },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(
            userStatusList({ userData: res.data, isFilter: false })
          );

          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

// All user logs
export const getUserLogs = (userId = 0) => {
  return async (dispatch) => {
    dispatch(userLogs({}));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId:loginId = 0 } = cookies ? cookies : "";
    // const dataEncrypt = userId + "&" + userId;
    await $axios
      .get(`/api/poco/user/report/${userId}`, {
        headers: { Authorization: `Bearer ${apiToken}`, JSessionID: loginId },
      })
      .then((res) => {
        if (res) {
          dispatch(userLogs(res.data));
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

// Add modetor or team memeber
export const addModeratorAndTeam = (
  userId = 0,
  role = "",
  roleCategory = ""
) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId: JsessionId = 0 } = cookies ? cookies : "";
    // const dataEncrypt = userId + "&" + userId;
    const data = { userId: userId, role: role, roleCategory: roleCategory };
    await $axios
      .post(`/api/poco/user/admin/team/add`, getEncrypt(JSON.stringify(data)), {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "Content-Type": "text/json",
          JSessionID: JsessionId,
        },
      })
      .then((res) => {
        if (res.data.success) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: res.data.message,
              toastVariant: "success",
            })
          );
          dispatch(getTeamsInfo(roleCategory));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        } else if (res.data.success === false) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: res.data.message,
              toastVariant: "error",
            })
          );
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

// Delete team or moderator
export const deleteModeratorAndTeam = (
  id = 0,
  userId = 0,
  selectedCategory = ""
) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId: JsessionId = 0 } = cookies ? cookies : "";
    const dataEncrypt = id + "&" + userId;
    await $axios
      .delete(`/api/poco/user/admin/team/${getEncrypt(dataEncrypt)}`, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "Content-Type": "text/json",
          JSessionID: JsessionId,
        },
      })
      .then((res) => {
        if (res) {
          dispatch(getTeamsInfo(selectedCategory));
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: "User deleted successfully",
              toastVariant: "success",
            })
          );
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: "error",
              toastVariant: "error",
            })
          );
        }
      });
  };
};

// Ban Active user
export const banActiveUsers = (activeUserId = 0, status = "", comment = "") => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";
    // const dataEncrypt = userId + "&" + userId;
    const payloadData = { userId: activeUserId, status, comment };
    await $axios
      .post(
        "/api/poco/user/admin/action",
        getEncrypt(JSON.stringify(payloadData)),
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
            "Content-Type": "text/json",
            JSessionID: userId,
          },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(
            userStatusList({ userData: activeUserId, isFilter: true })
          );
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: res.data.message,
              toastVariant: "success",
            })
          );
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.error,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

// Clear reported user
export const clearReportedUses = (clearUserId = 0, comment = "") => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";
    // const dataEncrypt = userId + "&" + userId;
    const payloadData = { userId: clearUserId, comment };

    await $axios
      .post(
        "/api/poco/user/report/clear",
        getEncrypt(JSON.stringify(payloadData)),
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
            "Content-Type": "text/json",
            JSessionID: userId,
          },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(userStatusList({ userData: clearUserId, isFilter: true }));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: "Report cleared successfully",
              toastVariant: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};
