import React from "react";
import { Grid, Typography, Box, Container } from "@material-ui/core";
// Reusable eles
import { PocoCard } from "components/elements/cards";
import { useSelector } from "react-redux";
import { ephocDateCovert } from "core/utils";
import "./index.scss";

export default function PostSearch(props) {
  // Get product object from redux state
  const { searchPostList = {} } = useSelector((state) => state.searchResult);
  const { tagData = [] } = searchPostList ||  {};

  const renderSearchPost = () => {
    return (
      <React.Fragment>
        <Container maxWidth="lg" className="search-post-list">
          <Box mt={12} mb={4}>
            <Box mb={2}>
              <Typography variant="h5">
                <strong>Search Result</strong>
              </Typography>
            </Box>
            {(tagData.length === 0) && (
              <Box textAlign="center" m={3}>
                <Typography variant="h6">No Search Result Found</Typography>
              </Box>
            )}
            <Grid container spacing={3}>
              {tagData?.map((ele) => (
                <React.Fragment>
                  <Grid item xs={12} sm={6} md={4} key={ele.id}>
                    <PocoCard
                      postid={ele.id}
                      Img={ele.postThumbnail}
                      Tittle={ele.postTitle}
                      ProfilePic={ele.postAvatar}
                      UserName={ele.postUserName}
                      postCreatedOn={ephocDateCovert(ele.createdAt)}
                      tag={ele.tagName}
                      view={ele.postViews}
                      like={ele.postLikes}
                      profileId={ele.userId}
                      isBookmarked={ele.isBookmarked}
                      isUserliked={ele.isUserliked}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </Container>
      </React.Fragment>
    );
  };
  return renderSearchPost();
}
