import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBookmarked } from "redux/actions/userPageAction";
import { PocoCard } from "components/elements/cards";
import { Grid, Box } from "@material-ui/core";
import { AddPost } from "components/elements/addPost";
import { ShowMore } from "components/elements/showMore";
import { showMorePostsAction } from "redux/actions/globalAction";

export default function UserBookmarked(props) {
  const { userId, isOtherUser } = props;
  const dispatch = useDispatch();
  const { showMorePosts } = useSelector(state=>state.global)
  const { userBookmarkPost } = showMorePosts;
  // const [postCount, setpostCount] = useState(10);

  // Get bookmared post by user id
  useEffect(() => {
    if (userId) {
      dispatch(getBookmarked(1, userBookmarkPost, userId));
    }
  }, [dispatch, userBookmarkPost, userId]);

  // Global state call
  const { userBookmarkedPostList } = useSelector((state) => state.userDetails);
  const { tagData, totalCount } =
    (userBookmarkedPostList && userBookmarkedPostList) || [];

  const postCountUpdate = () => {
    dispatch(showMorePostsAction({userBookmarkPost:userBookmarkPost+10}))
    // setpostCount(postCount + 10);
  };

  const renderUser = () => {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          {tagData?.map((alldata) => (
            <Grid item xs={12} sm={6} md={6} key={alldata.id} component="span">
              <PocoCard
                postid={alldata.id}
                Img={alldata.postThumbnail}
                Tittle={alldata.postTitle}
                ProfilePic={alldata.postAvatar}
                UserName={alldata.postUserName}
                postCreatedOn={alldata.postUserLastSeen}
                PostReply={alldata.postReply}
                tag={alldata.tagName}
                view={alldata.postViews}
                like={alldata.postLikes}
                profileId={alldata.userId}
                isBookmarked={alldata.isBookmarked}
                isUserliked={alldata.isUserLiked}
              />
            </Grid>
          ))}
        </Grid>
      </React.Fragment>
    );
  };
  return (
    <div className="user-profile-page">
      <Box mb={2} p={3}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={8}>
            <Grid container spacing={5}>
              {renderUser()}
            </Grid>
            <ShowMore
              data={tagData}
              totalCount={totalCount}
              propFunction={postCountUpdate}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            {!isOtherUser && <AddPost />}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
