import { getCookies, $axios } from "core/utils";
import * as actions from "redux/constant/types";
import { toastOpen, isPageLoad } from "./globalAction";

//  User page action start
export const alltaskLoad = () => ({
  type: actions.ALLTASK_ITEMS_LOAD,
});

export const alltaskSucces = (res) => ({
  type: actions.ALLTASK_ITEMS_SUCCESS,
  payload: res,
});

export const getallTaskItems = () => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId: loignId = 0 } = cookies ? cookies : "";

    dispatch(alltaskLoad());
    await $axios
      .get("/api/poco/user/task/all", {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          JSessionID: loignId,
           'content-type': 'text/json',
        },
      })
      .then((res) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (res) {
          dispatch(alltaskSucces(res.data));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        dispatch(
          toastOpen({
            isToastOpen: true,
            toastMessage: err.message,
            toastVariant: "error",
          })
        );
      });
  };
};
