import React from "react";
import { Box, Typography, Grid, Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import logo from "static/images/logo.png";
import "./index.scss";

function Footer() {
  // const { footerItems } = useSelector((state) => state.footerItems);
  const renderFooterItems = (
    <Box
      className="poco-community-footer"
      display={{ xs: "block", sm: "block", md: "block" }}
    >
      <Box display="flex">
        <Box flexGrow={5}></Box>
        <Box flexGrow={1}>
          <img src={logo} alt="" className="logo-footer" />
        </Box>
      </Box>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container>
            <Grid item md={4} xs={12} sm={4}>
              <Typography variant="h6">Information</Typography>
              <Typography variant="body1">
                <Link to="/aboutus">About us</Link>
              </Typography>
              <Typography variant="body1">
                <Link to="/termsuse">Terms of use</Link>
              </Typography>
              <Typography variant="body1">
                <Link to="/privacypolicy">Privacy policy</Link>
              </Typography>
            </Grid>
            <Grid item md={4} xs={12} sm={4}>
              <Typography variant="h6">Helpful Links</Typography>
              <Typography variant="body1">
                <a target="blank" href="https://www.poco.in/service-center">
                  Supports
                </a>
              </Typography>
              <Typography variant="body1">
                <Link to="/downloadcenter">Download center</Link>
              </Typography>
            </Grid>
            <Grid item md={4} xs={12} sm={4}>
              <Typography variant="h6">Contact us</Typography>
              <Typography variant="body1">1800-572-7626</Typography>
              <Typography variant="body1">service.in@poco.net</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider variant="middle" light />
      <Box pb={3} pt={3} textAlign="center">
        <Typography variant="body2" className="copyright">
          Copyright © 2010 - 2022 Xiaomi. All Rights Reserved
        </Typography>
      </Box>
    </Box>
  );
  return renderFooterItems;
}

export default Footer;
