import * as actions from "redux/constant/types";

const intialState = {
  menuItems: [],
  footerItems: null,
  downloadCenterList: [],
  notificationsList: [],
};

// Header reducer start
export const menuItemReducer = (state = intialState, action) => {
  switch (action.type) {
    case actions.MENU_ITEMS_SUCCESS:
      return {
        ...state,
        menuItems: action.payload,
      };
    default:
      return state;
  }
};

// Footer reducer start
export const footerItemsReducer = (state = intialState, action) => {
  switch (action.type) {
    case actions.FOOTER_ITEMS_SUCCESS:
      return {
        ...state,
        footerItems: action.payload,
      };
    default:
      return state;
  }
};

// Footer reducer start
export const downloadCenterReducer = (state = intialState, action) => {
  switch (action.type) {
    case actions.DOWNLOAD_CENTER_SCCESS:
      return {
        ...state,
        downloadCenterList: action.payload,
      };
    default:
      return state;
  }
};

export const notificationReducer = (state = intialState, action) => {
  switch (action.type) {
    case actions.NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationsList: action.payload,
      };
    default:
      return state;
  }
};
