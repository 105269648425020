import React, { useEffect } from "react";
import { PocoCard } from "components/elements/cards";
import { AddPost } from "components/elements/addPost";
import { ephocDateCovert } from "core/utils";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getTrendingPosts } from "redux/actions/homePageAction";
import { ShowMore } from "components/elements/showMore";
import { showMorePostsAction } from "redux/actions/globalAction";

export const Tranding = (props) => {
  const { userId } = props;
  const { showMorePosts } = useSelector((state) => state.global);
  const { trendingPost } = showMorePosts;
  // const [postCount, setpostCount] = useState(10);

  const dispatch = useDispatch();
  const { isValidApiToken } = useSelector((state) => state.apiAccess);

  useEffect(() => {
    if (isValidApiToken) {
      dispatch(getTrendingPosts(trendingPost,10 , userId));
    }
  }, [isValidApiToken, dispatch, trendingPost, userId]);

  // gobal store selctor
  const { trendingPosts } = useSelector((state) => state.homePageItems);
  const { tagData, totalCount } = trendingPosts || [];

  // method to increment count when page scroll down
  const postCountUpdate = () => {
    dispatch(showMorePostsAction({ trendingPost: trendingPost + 1 }));
    // setpostCount(postCount + 10);
  };

  const renderTrending = () => {
    return (
      <React.Fragment>
        {tagData?.map((ele) => (
          <Grid item xs={12} sm={6} md={6} key={ele.id}>
            <PocoCard
              postid={ele.id}
              Img={ele.postThumbnail}
              Tittle={ele.postTitle}
              ProfilePic={ele.postAvatar}
              UserName={ele.postUserName}
              postCreatedOn={ephocDateCovert(ele.createdAt)}
              tag={ele.tagName}
              view={ele.postViews}
              like={ele.postLikes}
              profileId={ele.userId}
              isBookmarked={ele.isBookmarked}
              isUserliked={ele.isUserLiked}
            />
          </Grid>
        ))}
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={8}>
          <Grid container spacing={3}>
            {renderTrending()}
          </Grid>
          <ShowMore
            data={tagData}
            totalCount={totalCount}
            propFunction={postCountUpdate}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <AddPost />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
