import { combineReducers } from "redux";
import {
  menuItemReducer,
  footerItemsReducer,
  downloadCenterReducer,
  notificationReducer,
} from "./headerFooterReducer";
import { homeItemReducer } from "./homePageReducer";
import { userProfileReducer } from "./userProfileReducer";
import { productReducer } from "./productReducer";
import { userItemReducer } from "./userPageReducer";
import { discoverItemReducer } from "./discoverPageReducer";
import { taskReducer } from "./taskReducer";
import { calenderReducer } from "./calenderReducer";
import { postDetailsReducer } from "./postDetailsReducer";
import { allPostReducer } from "./allPostReducer";
import { apiAccess } from "./apiTokenReducer";
import { searchPostReducer } from "./searchReducer";
import { cmsAdminModuleReducer } from "./cmsAdminUserModul";
import globalReducer from "./globalReducer";

export default combineReducers({
  global: globalReducer,
  menuItem: menuItemReducer,
  footerItems: footerItemsReducer,
  homePageItems: homeItemReducer,
  userProfile: userProfileReducer,
  userDetails: userItemReducer,
  taskPageItems: taskReducer,
  calenderReducer: calenderReducer,
  postDetails: postDetailsReducer,
  product: productReducer,
  discover: discoverItemReducer,
  allPost: allPostReducer,
  apiAccess: apiAccess,
  searchResult: searchPostReducer,
  downloadCenter: downloadCenterReducer,
  cmsAdminUserModul: cmsAdminModuleReducer,
  notifications: notificationReducer,
});
