import React from "react";
import { Typography, Box, Tooltip, Fab } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export function ShowMore(props) {
  const { data, totalCount = 1, propFunction } = props;
  return (
    <React.Fragment>
      <Box
        display="block"
        justifyContent="center"
        textAlign="center"
        mt={5}
        width="100%"
      >
        {data?.length === totalCount ? (
          <React.Fragment>
            {data?.length > 10 ? (
              <Typography variant="h6" >No more post available</Typography>
            ) : null}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {data && (
              <Tooltip title="Show More">
                
                  <Fab color="primary" onClick={propFunction}>
                    <ExpandMoreIcon fontSize="large" />
                  </Fab>
              </Tooltip>
            )}
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
}
