import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  ephocDateCovert,
  debounce,
  staticCategory,
  getRole,
  Role,
} from "core/utils";
import {
  getcmsPostlist,
  PostSectionEnum,
  movePostTo,
} from "redux/actions/cmsPostManagement";
import { Paper, Button, Box, Popover, IconButton } from "@material-ui/core";
import { tableStyle } from "core/theme/makeStyle";
import SearchFilter from "components/cmsAdmin/components/searchFilter";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "components/cmsAdmin/components/modal";

export default function UnverifedPost(props) {
  const [value, setValue] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [imgeSrc, setImgeSrc] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({ postId: 0, userComment: "" });

  const classes = tableStyle();

  // Get User profile details
  const { userProfile = {} } = useSelector(
    (state) => state.userProfile?.userProfile || {}
  );

  const isModerator = Role.MODETATOR === getRole(userProfile?.userInfo?.role);

  const staticCategoryForAdmin = [];
  const moderatorCategory = [];
  const profileData = userProfile?.userInfo?.roleCategory || [];
  profileData.forEach((ele) => moderatorCategory.push([{ category: ele }]));
  staticCategory.forEach((ele) =>
    staticCategoryForAdmin.push([{ category: ele }])
  );

  const structureCategory = isModerator
    ? moderatorCategory.flat()
    : staticCategoryForAdmin.flat();

  useEffect(() => {
    setValue([structureCategory[0]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModerator]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userProfile?.userInfo && value[0]?.category !== undefined)
      dispatch(getcmsPostlist(0, 1, "", value, PostSectionEnum.UNVERIFIED));
  }, [dispatch, value, userProfile]);

  const { cmsUnverifedPostList: { tagData = [], totalCount = 0 } = {} } =
    useSelector((state) => state.cmsAdminUserModul);

  const rowCreate = (
    postName,
    postThumbnail,
    UserName,
    category,
    createdTime,
    editPost,
    moveToVerifed
  ) => {
    return {
      postName,
      postThumbnail,
      UserName,
      category,
      createdTime,
      editPost,
      moveToVerifed,
    };
  };

  const unverifiedColumns = [
    { id: "postName", label: "Post Name", minWidth: 170 },
    { id: "postThumbnail", label: "Post Thumbnail", minWidth: 100 },
    {
      id: "UserName",
      label: "Username",
      minWidth: 170,
      align: "left",
    },
    {
      id: "category",
      label: "Category",
      minWidth: 170,
      align: "left",
    },
    {
      id: "createdTime",
      label: "Post Created Time",
      minWidth: 170,
      align: "left",
    },
    {
      id: "editPost",
      label: "Edit Post",
      minWidth: 170,
      align: "center",
    },
    {
      id: "moveToVerifed",
      label: "Move To Verifed",
      minWidth: 170,
      align: "center",
    },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setImgeSrc(event.currentTarget.src);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const modalElOpen = (postId) => {
    setModalOpen(true);
    setModalData({ ...modalData, postId });
  };

  // Delete User method
  const confirmation = () => {
    if (modalData.userComment.trim()) {
      dispatch(
        movePostTo(
          modalData.postId,
          PostSectionEnum.VERIFIED,
          modalData.userComment
        )
      );
      dispatch(getcmsPostlist(0, 0, "", value, PostSectionEnum.UNVERIFIED));
      setModalOpen(false);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // get value of modals
  const getValue = (value) => {
    setModalData({ ...modalData, userComment: value });
  };

  // get value of modals
  const getSearchValue = (searcValue) => {
    const getSearchValue = debounce(() =>
      dispatch(
        getcmsPostlist(0, 0, searcValue, value, PostSectionEnum.UNVERIFIED)
      )
    );
    if (searcValue.length > 1 || searcValue.length < 1) getSearchValue();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    const pageCount = newPage === 0 ? 1 : newPage + 1;
    dispatch(
      getcmsPostlist(0, 1, "", value, PostSectionEnum.UNVERIFIED, pageCount)
    );
    setPage(newPage);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const unverifiedData = tagData?.map((ele) =>
    rowCreate(
      <Link
        to={{
          pathname: `/admin/post/${ele.id}`,
          state: { isAdmin: true, btnUnverify: false, postId: ele.id },
        }}
      >
        {unescape(ele.postTitle)}
      </Link>,
      <Link>
        <img
          src={ele.postThumbnail}
          width="50"
          alt={ele.postThumbnail}
          onClick={handleClick}
        />
      </Link>,
      ele.postUserName,
      ele.category,
      ephocDateCovert(ele.createdAt),
      <IconButton aria-label="edit">
        <Link
          to={{
            pathname: `/admin/editpost`,
            state: { isPostEdiatble: true, postId: ele.id },
          }}
        >
          <EditIcon fontSize="small" />
        </Link>
      </IconButton>,
      <IconButton onClick={() => modalElOpen(ele.id)}>
        <OpenWithIcon fontSize="small" />
      </IconButton>
    )
  );

  return (
    <React.Fragment>
      <SearchFilter
        setSearchValue={(val) => getSearchValue(val)}
        filterValue={value}
        filtersetValue={setValue}
        data={structureCategory}
      />
      <Box mt={5}>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="a dense table" size="small">
              <TableHead>
                <TableRow>
                  {unverifiedColumns.map((column) => (
                    <TableCell key={column.id} align={column.align}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {unverifiedData.map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      {unverifiedColumns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
          />
        </Paper>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box display="Flex" flexDirection="column">
            <Box>
              <img
                src={imgeSrc}
                alt=""
                flexGrow={1}
                width="200"
                hegight="200"
              />
            </Box>
            <Button
              onClick={handleClose}
              color="primary"
              variant="contained"
              size="small"
            >
              Close
            </Button>
          </Box>
        </Popover>
        <Modal
          title="Move To Verifed"
          open={modalOpen}
          handleClose={closeModal}
          getValue={(val) => getValue(val)}
          confirm={confirmation}
        />
      </Box>
    </React.Fragment>
  );
}
