import * as actions from "redux/constant/types";
import { getCookies, $axios, getEncrypt } from "core/utils";
import { toastOpen, isPageLoad } from "redux/actions/globalAction";

//  treniding page action start
export const trendingItemsLoad = (payload) => ({
  type: actions.TRENDING_ITEMS_LOAD,
  payload,
});

export const trendingItemsSucces = (res) => ({
  type: actions.TRENDING_ITEMS_SUCCESS,
  payload: res,
});

export const getTrendingPosts = (page = 1, size = 10, currentUserId = 0) => {
  return async (dispatch) => {
    const cookies = getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";
    dispatch(trendingItemsLoad(true));
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    const dataEncrypt = currentUserId + '&' + currentUserId
    await $axios
      .get(`/api/poco/v1/post/trending?page=${page}&size=${size}&query=current_userid:eq:${getEncrypt(dataEncrypt)}`, {
        headers: { Authorization: `Bearer ${apiToken}`,  "JSessionID": userId },
      })
      .then((res) => {
        if (res) {
          dispatch(trendingItemsSucces(res.data));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(trendingItemsLoad(false));
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};
