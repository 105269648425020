import * as actions from "redux/constant/types";

const intialState = {
  alltaskLoad: false,
  alltask: undefined,
};

// Home reducer start
export const taskReducer = (state = intialState, action) => {
  switch (action.type) {
    case actions.ALLTASK_ITEMS_LOAD:
      return {
        ...state,
        alltaskLoad: true,
      };

    case actions.ALLTASK_ITEMS_SUCCESS:
      return {
        ...state,
        alltaskLoad: false,
        alltask: action.payload,
      };
    default:
      return state;
  }
};
