import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRole, Role, staticCategory, debounce } from "core/utils";
import {
  getTeamsInfo,
  getActiveUserList,
  addModeratorAndTeam,
  deleteModeratorAndTeam,
} from "redux/actions/cmsAdminUserManagement";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import {
  Card,
  Typography,
  CardContent,
  Box,
  Grid,
  Container,
  Button,
  Divider,
  Dialog,
  ListItemAvatar,
  ListItemText,
  List,
  ListItem,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import "./index.scss";
import { toastOpen } from "redux/actions/globalAction";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function TeamsList(props) {
  const [moderatorbtnToggle, setmoderatorbtnToggle] = useState(false);
  const [teamMemberbtnToggle, setteamMemberbtnToggle] = useState(false);
  const [open, setOpen] = useState(false);
  const [teamModal, setTeamModal] = useState(false);
  const [addingUser, setAddingUser] = useState({ userId: 0, category: "" });
  const [category, setSlectCategory] = useState("News");

  const { isValidApiToken } = useSelector((state) => state.apiAccess);

  // Get User profile details
  const { userProfile = {} } = useSelector(
    (state) => state.userProfile?.userProfile || {}
  );

  // Find Role user
  const isAdmin = Role.ADMIN === getRole(userProfile?.userInfo?.role);
  const isModerator = Role.MODETATOR === getRole(userProfile?.userInfo?.role);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (isValidApiToken) {
  //     dispatch(getActiveUserList());
  //   }
  // }, [dispatch, isValidApiToken]);

  useEffect(() => {
    if (isValidApiToken) {
      isAdmin && dispatch(getTeamsInfo("News"));
      isModerator &&
        dispatch(getTeamsInfo(userProfile?.userInfo?.roleCategory[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAdmin, isModerator, isValidApiToken]);

  // Get all moderator and team member user information
  const { TeamsInformation = {}, userList = {} } = useSelector(
    (state) => state.cmsAdminUserModul
  );

 
  // A static freezed object for role check
  const staticRole = Object.freeze({
    MODERTOR: "ROLE_MODERATOR",
    TEAMMATES: "TEAMMATES",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleTeamClickOpen = () => {
    setTeamModal(true);
  };
  const handleClose = () => {
    setOpen(false);
    setTeamModal(false);
    // setAddingUser({ userId: 0, category: "" });
  };

  const handleTeamBtn = () => {
    setteamMemberbtnToggle(!teamMemberbtnToggle);
  };
  const handlemoderatorBtn = () => {
    setmoderatorbtnToggle(!moderatorbtnToggle);
  };

  const deleteUserAccess = (id, userId) => {
    dispatch(deleteModeratorAndTeam(id, userId, category));
  };
  // add user method
  const addUser = (role) => {
    if (addingUser.category !== "" && addingUser.userId !== 0) {
      if (role === staticRole.MODERTOR) {
        dispatch(
          addModeratorAndTeam(
            addingUser.userId,
            staticRole.MODERTOR,
            addingUser.category
          )
        );
        handleClose();
      } else if (role === staticRole.TEAMMATES) {
        dispatch(
          addModeratorAndTeam(
            addingUser.userId,
            staticRole.TEAMMATES,
            addingUser.category
          )
        );
        handleClose();
      }
    } else {
      dispatch(
        toastOpen({
          isToastOpen: true,
          toastMessage: "Please select valid option",
          toastVariant: "error",
        })
      );
    }
  };
  const searchList = userList.userData || []
  return (
    <React.Fragment>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={6} md={8}></Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FormControl fullWidth variant="outlined" className="mb-20">
              <InputLabel id="demo-simple-select-label">
                Select category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select category"
                native
                onChange={(e) => {
                  dispatch(getTeamsInfo(e.target.value));
                  setSlectCategory(e.target.value);
                }}
              >
                {isModerator
                  ? userProfile?.userInfo?.roleCategory.map((element) => (
                      <option
                        value={element}
                        selected={addingUser.category === element}
                      >
                        {element}
                      </option>
                    ))
                  : staticCategory.map((element) => (
                      <option
                        value={element}
                        selected={addingUser.category === element}
                      >
                        {element}
                      </option>
                    ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8} md={8}>
                <Box display="flex">
                  <Typography variant="h6">Moderator</Typography>
                  &nbsp;
                  {moderatorbtnToggle && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickOpen}
                    >
                      Add
                    </Button>
                  )}
                </Box>
                <List>
                  {TeamsInformation?.moderator?.map((ele) => (
                    <React.Fragment>
                      <ListItem key={ele.id}>
                        <ListItemAvatar>
                          <Avatar src={ele.profilePic} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={ele.firstName + " " + ele.lastName}
                          secondary={ele.roleCategory}
                        />
                        {moderatorbtnToggle && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => deleteUserAccess(ele.id, ele.userId)}
                          >
                            Delete
                          </Button>
                        )}
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}
                </List>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                {isAdmin && (
                  <React.Fragment>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handlemoderatorBtn}
                    >
                      Edit
                    </Button>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <Container>
        <Box mt={3}>
          <Card>
            <CardContent>
              <Box display="flex">
                <Typography variant="h6">Team Members</Typography>
                &nbsp;
                {teamMemberbtnToggle && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleTeamClickOpen}
                  >
                    Add
                  </Button>
                )}
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={8} sm={8} md={8}>
                  {TeamsInformation?.teamMembers?.map((ele) => (
                    <React.Fragment>
                      <ListItem key={ele.id}>
                        <ListItemAvatar>
                          <Avatar src={ele.profilePic} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={ele.firstName + " " + ele.lastName}
                          secondary={ele.roleCategory}
                        />
                        {teamMemberbtnToggle && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => deleteUserAccess(ele.id, ele.userId)}
                          >
                            Delete
                          </Button>
                        )}
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  {isAdmin && (
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleTeamBtn}
                    >
                      Edit
                    </Button>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Container>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add Moderator
        </DialogTitle>
        <DialogContent dividers>
          <FormControl fullWidth variant="outlined" required>
             <Autocomplete
            id="search"
            options={searchList?.map((item)=>{
              return {...item,fullName:item.fullName}
            })}
            // autoHighlight={true}
            forcePopupIcon={true}
            // loading
            autoSelect={true}
            closeIcon={false}
            noOptionsText="Search Result"
            clearText="asd"
            getOptionLabel={(option) => option.fullName}
            renderOption={(option) => (
              <React.Fragment>
                <Box className="auto-suggest">
                  <span onClick={()=> setAddingUser({ ...addingUser, userId: option.userId })}>
                    <Box display="flex">
                      <Typography variant="subtitle2" >
                        {option.fullName}
                      </Typography>
                    </Box>
                    </span>
                </Box>
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search"
                variant="outlined"
                color="secondary"
                autoFocus
                onKeyDown={(e,value) => e.target.value.length >= 2 && dispatch(debounce(getActiveUserList(e.target.value), 2000))}
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
          </FormControl>
          <FormControl fullWidth variant="outlined" className="mt-20">
            <InputLabel id="demo-simple-select-label">
              Select category
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select category"
              onChange={(e) =>
                setAddingUser({ ...addingUser, category: e.target.value })
              }
            >
              {staticCategory.map((element) => (
                <MenuItem value={element} key={element.id}>
                  {element}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            color="secondary"
            onClick={() => addUser(staticRole.MODERTOR)}
          >
            Save
          </Button>
          <Button
            autoFocus
            variant="contained"
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={teamModal}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add Teams
        </DialogTitle>
        <DialogContent dividers>
          <FormControl fullWidth variant="outlined">
          <Autocomplete
            id="search"
            options={searchList?.map((item)=>{
              return {...item,fullName:item.fullName}
            })}
            // autoHighlight={true}
            forcePopupIcon={true}
            // loading
            autoSelect={true}
            closeIcon={false}
            noOptionsText="Search Result"
            clearText="asd"
            getOptionLabel={(option) => option.fullName}
            renderOption={(option) => (
              <React.Fragment>
                <Box className="auto-suggest">
                  <span onClick={()=> setAddingUser({ ...addingUser, userId: option.userId })}>
                    <Box display="flex" >
                      <Typography variant="subtitle2">
                      {option.fullName}
                      </Typography>
                    </Box>
                    </span>
                </Box>
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search"
                variant="outlined"
                color="secondary"
                autoFocus
                onKeyDown={(e, value) => e.target.value.length >= 2 && dispatch(debounce(getActiveUserList(e.target.value), 2000))}
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
          </FormControl>
          <FormControl fullWidth variant="outlined" className="mt-20">
            <InputLabel id="demo-simple-select-label">
              Select category
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select category"
              onChange={(e) =>
                setAddingUser({ ...addingUser, category: e.target.value })
              }
            >
              {staticCategory.map((element) => (
                <MenuItem value={element} key={element.id}>
                  {element}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            color="secondary"
            onClick={() => addUser(staticRole.TEAMMATES)}
          >
            Save
          </Button>
          <Button
            autoFocus
            variant="contained"
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
