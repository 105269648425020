import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  ButtonGroup,
  Button,
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core/";
import CustomModal from "components/elements/customModal";
import {
  movePostTo,
  postLogs,
  PostSectionEnum,
} from "redux/actions/cmsPostManagement";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

export default function LogAndCommentSection(props) {
  const {
    btnDelete = true,
    btnEdit = btnDelete,
    btnVerify = true,
    btnUnverify = true,
    postId,
    userId,
  } = props;

  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [section, setSection] = useState("");

  const dispatch = useDispatch();

  const { postLogs: logsList } = useSelector(
    (state) => state.cmsAdminUserModul
  );

  let history = useHistory();

  const modalOpen = (sectionParam) => {
    setOpen(true);
    setSection(sectionParam);
  };
  const modalClose = () => {
    setOpen(false);
    setComment("");
    setSection("");
  };
  const handleSubmit = () => {
    if (comment.trim()) {
      dispatch(movePostTo(postId, section, comment, history));
      // history.goBack();
      modalClose();
    }
  };
  useEffect(() => {
    dispatch(postLogs(postId, userId));
  }, [dispatch, postId, userId]);

  return (
    <React.Fragment>
      <Box textAlign="center" className="post-logs">
        <Card variant="outlined">
          <CardContent>
            <ButtonGroup
              variant="contained"
              color="secondary"
              aria-label="contained primary button group"
              size="small"
              disableElevation
            >
              {btnDelete && (
                <Button onClick={() => modalOpen(PostSectionEnum.DELETED)}>
                  Delete
                </Button>
              )}
              {btnEdit && (
                <Button>
                  <Link
                    to={{
                      pathname: `/admin/editpost`,
                      state: {
                        isPostEdiatble: true,
                        postId: postId,
                      },
                    }}
                  >
                    Edit
                  </Link>
                </Button>
              )}
              {btnVerify && (
                <Button
                  onClick={() => modalOpen(PostSectionEnum.VERIFIED)}
                  fullWidth
                >
                  Move to Verified
                </Button>
              )}
              {btnUnverify && (
                <Button onClick={() => modalOpen(PostSectionEnum.UNVERIFIED)}>
                  Move to Unverified
                </Button>
              )}
            </ButtonGroup>
          </CardContent>
        </Card>
        <Box mt={2}>
          <Card variant="outlined">
            <CardContent>
              <List>
                {logsList.length > 0
                  ? logsList.map((log) => {
                      return (
                        <React.Fragment>
                          <ListItem>
                            <ListItemText
                              primary={`${log.reportedBy} has ${
                                PostSectionEnum[log.type]
                              } the post`}
                              secondary={`Reason : ${
                                log.reason
                              } \n Created Time: ${new Date(
                                log.createdDate * 1000
                              ).toLocaleString()}`}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      );
                    })
                  : "No log data"}
              </List>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <CustomModal
        title="Add Comment"
        open={open}
        modalSubmit={handleSubmit}
        modalClose={modalClose}
      >
        {
          <TextField
            maxRows={7}
            rows={7}
            label="Add Comment"
            variant="outlined"
            onChange={(e) => setComment(e.target.value)}
            multiline
            fullWidth
          />
        }
      </CustomModal>
    </React.Fragment>
  );
}
