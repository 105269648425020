import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import {
  getallCalenderItems,
  calendarCheckIn,
} from "redux/actions/calenderAction";
import { Button, Container, Box, Avatar } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../index.scss";

const localizer = momentLocalizer(moment);

export const Allcalender = (props) => {
  const dispatch = useDispatch();
  const { isValidApiToken } = useSelector((state) => state.apiAccess);
  const { userProfile } = useSelector((state) => state.userProfile);

  // Destructuring user information
  const { userProfile: profileInfo } = userProfile ? userProfile : {};
  const { userInfo } = profileInfo || {};
  const { userId, profilePic, firstName, lastName } = userInfo || "";

  useEffect(() => {
    window.scrollTo(0,0);

    if (isValidApiToken && userId) {
      dispatch(getallCalenderItems(userId));
    }
  }, [dispatch, isValidApiToken, userId]);

  const { allcalender } = useSelector((state) => state.calenderReducer);
  const { points = "", totalCheckInDays ="" } = allcalender || "";
  const calDate = allcalender?.checkInDates || [];

  // Api response epoch date converted into YYYY-MM-DD
  let ecochConvertedDate = [];
  calDate.forEach((item) => {
    ecochConvertedDate.push({
      id: item.id,
      userId: item.userId,
      eventClasses: item.eventClasses,
      start: item.actualDate,
      end: item.actualDate,
    });
  });

  // method to user checkiI
  const handleCheckIn = () => {
    dispatch(calendarCheckIn(userId));
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid item xs={12} sm={12} md={12}>
          <Box mt={12} className="poco-calendar" mb={10}>
            <Box mb={1} p={3} bgcolor="#ece70b">
              <Grid container>
                <Grid item xs={12} md={10} sm={6}>
                  <Box flexGrow={1} display="flex">
                    <Box mr={2}>
                      <Avatar src={profilePic}></Avatar>
                    </Box>
                    <Box alignItems="center">
                      <Typography variant="body1">
                        {" "}
                        <strong>
                          {firstName} <strong>{lastName}</strong>
                        </strong>
                      </Typography>
                      <Typography variant="caption">
                        {" "}
                        <strong>{points} Points</strong>
                      </Typography>
                      &nbsp;|&nbsp;
                      <Typography variant="caption">
                        {" "}
                        <strong>{totalCheckInDays} Total check-ins</strong>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={2} sm={6}>
                  <Button
                    className="chekin-btn"
                    variant="contained"
                    color="primary"
                    onClick={handleCheckIn}
                  >
                    <CheckCircleIcon /> &nbsp; Check In
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Calendar
              localizer={localizer}
              defaultDate={new Date()}
              defaultView="month"
              events={ecochConvertedDate}
              style={{ height: "80vh" }}
              views={["month"]}
            />
          </Box>
        </Grid>
      </Container>
    </React.Fragment>
  );
};