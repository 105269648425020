import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Container,
  Box,
  Typography,
  CardContent,
  Card,
  Chip,
} from "@material-ui/core";
import { getallTaskItems } from "redux/actions/taskAction";
import DoneIcon from "@material-ui/icons/Done";
import { taskStyle } from "core/theme/makeStyle";
import "../index.scss";

export const Alltask = (props) => {
  const { isValidApiToken } = useSelector((state) => state.apiAccess);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (isValidApiToken) {
      dispatch(getallTaskItems());
    }
  }, [dispatch, isValidApiToken]);

  const { alltask } = useSelector((state) => state.taskPageItems);
  const {
    userProfile: {
      userProfile: { userInfo: { rewardPoints = 0 } = {} } = {},
    } = {},
  } = useSelector((state) => state.userProfile);

  const classes = taskStyle();


  const renderTaskList = (task) => {
    return task.isActive ? (
      <Grid
        item
        xs={12}
        sm={4}
        md={3}
        key={task.id}
        className={task.isTaskCompleted && "disable-completed"}
      >
        <Link to={!task.isTaskCompleted && task.path}>
          <Card className={classes.root}>
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography component="h6" variant="h6">
                  {task.taskName}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {task.description}
                </Typography>
              </CardContent>
              <Grid container>
                <Grid item xs={6}>
                  <Box p={1} textAlign="left">
                    <Typography variant="subtitle2" color="textSecondary">
                      {task.completedCount}/{task.actionCount}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box p={1} textAlign="right">
                    <Chip
                      icon={task.isTaskCompleted && <DoneIcon />}
                      size="small"
                      variant="default"
                      color={task.isTaskCompleted ? "primary" : "secondary"}
                      label={
                        task.isTaskCompleted
                          ? `Earned ${task.rewardPoint} points`
                          : `Get ${task.rewardPoint} points`
                      }
                    />
                  </Box>
                </Grid>
              </Grid>

            </div>
          </Card>
        </Link>
      </Grid>
    ) : null;
  };
  return (
    <React.Fragment>
      <Container maxWidth="lg" className="task-section">
        <Grid container>
          <Box className="taskarea" mt={11} display="flex">
            <Box flexGrow={1}>
              <Typography variant="h5">Tasks</Typography>
              <Typography variant="body1">
                Perform the following activites to win medals
              </Typography>
            </Box>
            <Box className="taskarea-rhs">
              <Typography variant="body1">
                Total earned reward points
              </Typography>
              <Typography variant="body1" align="center">
                {rewardPoints}
              </Typography>
            </Box>
          </Box>
          {alltask?.map((task) => renderTaskList(task))}
        </Grid>
      </Container>
    </React.Fragment>
  );
};
