import { tocamelCase, getCookies, $axios, getEncrypt } from "core/utils";
import { toastOpen, isPageLoad } from "./globalAction";
import * as actions from "redux/constant/types";

// method to get thumbnail url
export const fileUpload = async (uploadedFile = "", userId = 0) => {
  //  method will get token to access api
  const cookies = getCookies();
  const { apiToken, userId:loginUser = 0 } = cookies ? cookies : "";

  const data = new FormData();
  data.append("files", uploadedFile);
  const dataEncrypt = userId + '&' + userId
  const result = await $axios
    .post(`/api/poco/v1/post/upload/${getEncrypt(dataEncrypt)}`, data, {
      headers: { Authorization: `Bearer ${apiToken}`, "JSessionID": loginUser },
    })
    .then((response) => tocamelCase(response))
    .catch((err) => {
      if (err.response) {
        console.log(err.response.data.message);
      }
    });
  if (result) {
    return result.data;
  }
};

//  Auto Save
export const setAutoSavePostId =(res)=>({
  type: actions.SET_AUTOSAVE_POSTID,
  payload: res
})

// method to create post
export const CreatePost = (post, isDraftFlow = false, isAutoLoad = false) => {
  return async (dispatch) => {
    !isAutoLoad && dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";
    await $axios
      .post(`/api/poco/v1/post/add`, getEncrypt(JSON.stringify(post)), {
        headers: {
          Authorization: `Bearer ${apiToken}`,
           'content-type': 'text/json',
          "JSessionID": userId 
        },
      })
      .then((response) => {
        if(response){
          dispatch(setAutoSavePostId(tocamelCase(response.data)))
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
          !isAutoLoad && dispatch(
             toastOpen({
               isToastOpen: true,
               toastMessage: isDraftFlow ? "Post Drafted Successfully" :"Post Created Successfully",
               toastVariant: "success",
             })
           );
        }
      })
      .catch((err) => {
        if (err.response) {
          !isAutoLoad &&  dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
          !isAutoLoad && dispatch(isPageLoad({ isPageLoaderOpen: false }));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      });

  };
};

// method to update post
export const UpdatePost = (postId,postData, isDraftFlow = false, isAutoLoad = false) => {
  return async (dispatch) => {
    !isAutoLoad && dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId = 0 } = cookies ? cookies : "";
    const dataEncrypt = postId + '&' + userId
    const result = await $axios
      .put(`/api/poco/v1/post/${getEncrypt(dataEncrypt)}`, getEncrypt(JSON.stringify(postData)), {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "Content-type": "text/json",
          "JSessionID": userId 
        },
      })
      .then((response) => tocamelCase(response))
      .catch((err) => {
        if (err.response) {
          !isAutoLoad && dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
          !isAutoLoad &&  dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      });

    if (result) {
      !isAutoLoad && dispatch(isPageLoad({ isPageLoaderOpen: false }));
      !isAutoLoad &&  dispatch(
        toastOpen({
          isToastOpen: true,
          toastMessage: isDraftFlow ? "Drafted post updated Successfully " :"Post Updated Successfully",
          toastVariant: "success",
        })
      );
    }
  };
};
