import React, { useEffect } from "react";
import { Grid, Container, Box } from "@material-ui/core";

export const Privacypolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container >
          <Box mt={8} mb={4} p={2}>
            <h3>POCO COMMUNITY PRIVACY POLICY</h3>
            <p> Our Privacy Policy was updated on 20th April 2021.</p>
            <p>
              {" "}
              Please take a moment to familiarize yourself with our privacy
              practices and let us know if you have any questions.
            </p>
            <h4>About us</h4>
            <p>
              Xiaomi Technology India Private Limited and its affiliated
              companies (hereinafter referred to as "Xiaomi", "we", "our", "us")
              take your privacy very seriously. This Privacy Policy is designed
              with your needs in mind, and it is important that you have a
              comprehensive understanding of our personal information collection
              and usage practices, while ensuring that ultimately, you have
              control of your personal information provided to us.
            </p>
            <h4>About this privacy policy</h4>
            <p>
              This Privacy Policy explains how Xiaomi collects, uses, stores,
              discloses, processes and protects any personal information that
              you give us or that we collect from you. Should we ask you to
              provide certain information by which you can be identified when
              you use the Xiaomi services, it will only be used in accordance
              with this Privacy Policy and/or our terms and conditions for
              users.{" "}
            </p>
            <p>
              Under this Privacy Policy, “personal information” means
              information that that relates to you, which, either directly or
              indirectly, in combination with other information available or
              likely to be available with Xiaomi, is capable of identifying you.
              We will use your personal information strictly in accordance with
              this privacy policy.{" "}
            </p>
            <h4>How can we help you</h4>
            <p>
              Ultimately, what we want is the best for all our users. Should you
              have any questions with our data handling practices as summarized
              in this Privacy Policy, please contact us at
              https://privacy.mi.com/support to address your specific concerns.
            </p>
            <h4>1. What information is collected and how can we use it?</h4>
            <p>
              1.1 What information is collected by us? In order to provide our
              services to you, we will ask you to provide personal information
              that is necessary to provide those services to you. We will only
              collect the information that is necessary for its specified,
              concrete, explicit and legitimate purposes and not further
              processed in a manner that is incompatible with those purposes.
              You have the right to choose whether or not to provide the
              information we have requested, but in most cases, if you do not
              provide your personal information, we may not be able to provide
              you with our services or respond to your queries. Depending on the
              service you choose, we may collect the following types of
              information:
            </p>
            <p>
              1.1.1 Information that you provide to us We may collect any
              personal information you provide to us, which is necessary for the
              service you choose. For example, you may provide your name,
              contact details like e-mail address, phone number, residential
              address, and profile pictures when you sign in; we may also
              collect your comment content to present on the comment page.
            </p>
            <p>
              1.1.2 Information that we collect in your use of services We may
              collect you nickname, browsing record, posting record, your like
              and comment record during your usage of our service, in order to
              present the above records on your personal page.
            </p>
            <p>
              1.1.3 Non-personally identifiable information We may also collect
              other types of information which are not directly or indirectly
              linked to an inBox mt={12}idual and which may not be defined as
              personal information according to applicable local laws. Such
              information may include statistical data generated when you use a
              specific service (e.g. daily usage events, page access events,
              page access time events and session events); network monitoring
              data (e.g. request time, number of request or error request etc.).
              The purpose for such collecting is to improve the services we
              provide to you. The type and amount of information collected
              depends on how you use our services.
            </p>
            <p>
              Such data is not personal information as it cannot be used to
              identify you. However, if we combine non-personal information with
              personal information, such information will be treated as personal
              information.
            </p>
            <p>1.2 How we use the personal information that we collect</p>
            <p>
              The purpose of collecting personal information is to provide you
              with our services, and to ensure that we comply with applicable
              laws, regulations and other regulatory requirements. Here are more
              detailed examples on how we use your information (which may
              include personal information): • Providing, processing,
              maintaining, improving and developing our services to you. •
              Handling your questions or requests about our services, such as
              sending notifications. • Internal purposes such as data analysis,
              research, and development of statistical information related to
              the use of our services for improvement. • Storing and maintaining
              information related to you for our business operations (such as
              business statistics) or for fulfilling our legal obligations. •
              Complying with orders from government, regulatory and statutory
              authorities and/or courts of competent jurisdiction. • Other
              purposes with your consent.
            </p>
            <h4>
              2. How we share, transfer and publicly disclose your personal
              information
            </h4>
            <p>2.1 Sharing</p>
            <p>
              We do not sell any personal information to third parties. And
              unless obtain your consent, we would not share your personal
              information to any third parties.
            </p>
            <p>
              2.1.1 Sharing that you actively choose or request With your
              explicit consent or at your request, we will share your personal
              information within the scope of your consent/request with specific
              third parties designated by you.
            </p>
            <p>2.1.2 Sharing information with our group</p>
            <p>
              In order to successfully conduct business operations and to
              provide you with all the functions of our services, we may share
              your personal information from time to time to other Xiaomi
              affiliates and group companies.
            </p>
            <p>
              2.1.3 Sharing with Third Party Service Providers and business
              Partners{" "}
            </p>
            <p>
              To help us provide you with services described in this Privacy
              Policy, we may, where necessary, share your personal information
              with our third party service providers and business partners. We
              will conduct due diligence and have contracts in place to ensure
              that third-party service providers comply with the applicable
              privacy laws in your jurisdiction.{" "}
            </p>
            <p>2.1.4 Other</p>
            <p>
              In accordance with legal requirements, legal procedures,
              litigation and/or requests from public agencies and government
              agencies, Xiaomi may need to disclose your personal information.
              If the disclosure is necessary or appropriate for national
              security, law enforcement, or other matters of public importance,
              we may also disclose information about you. In order to enforce
              our terms or protect our business, rights, assets or services, or
              to protect users, or if the disclosure is reasonably necessary for
              the following purposes (detecting, preventing and resolving fraud,
              unauthorized use of the service, violations of our terms or
              policies, or other harmful or illegal activities), we may also
              disclose information about you. (There may be occasions when
              Xiaomi may collect, use or disclose your personal information
              without your consent if it is and only to the extent it is
              permitted under applicable data protection laws). This may include
              providing your personal information to public or government
              agencies; communicating with third-party partners about the
              reliability of your account to prevent fraud, violations, and
              other harmful behaviours.{" "}
            </p>
            <p>In addition, we may share your personal information with:</p>
            <p>
              - our accountants, auditors, lawyers, or similar advisers when we
              ask them to provide us with professional advice; and - investors
              and other relevant third parties in the event of an actual or
              potential sale or other corporate transaction related to an entity
              in the Xiaomi and its affiliated companies; and - any other third
              parties, if authorized by you to do so in relation to a specific
              disclosure.
            </p>
            <p>2.2 Transfer</p>
            <p>
              Xiaomi will not transfer your information to any subject except in
              the following cases:
            </p>
            <p>
              - Where we have obtained your explicit consent; - If Xiaomi is
              involved in the merger, acquisition, or sale of all or part of its
              assets, we will notify you of any changes in the ownership, use,
              and any choice of your personal information you possibly possess
              by email and/or by posting a prominent notice on our application.
            </p>
            <p>2.3 Public Disclosure</p>
            <p>
              Xiaomi will only publicly disclose your personal information under
              the following circumstances:
            </p>
            <p>
              - Where we have obtained your explicit consent; - Public
              disclosure based on laws and regulations, legal procedures,
              litigation, or at the request of the competent government
              departments.
            </p>
            <h4>3. How we store and protect your personal information</h4>
            <p>3.1 Xiaomi's security safeguards</p>
            <p>
              We are committed to keeping your personal information secure. In
              order to prevent unauthorized access, disclosure, or other similar
              risks, we have put in place legally required physical, electronic
              and managerial procedures to safeguard and secure the information
              we collect on your using our services. We will ensure that we
              safeguard your personal information in accordance with applicable
              law. All your personal information is stored on secure servers and
              protected in controlled facilities. We classify your information
              based on importance and sensitivity and ensure that your personal
              information has the required level of security. We regularly
              review our information collection, storage, and processing
              practices, including physical security measures, to guard against
              any unauthorized access and use. We conduct due diligence on
              third-party service providers and business partners to make sure
              that they are able to protect your personal information. We also
              check those appropriate security standards are maintained by these
              third parties by putting in place appropriate contractual
              restrictions, and where necessary, carrying out audits and
              assessments. In addition, our employees and those of our business
              partners and third-party service providers who access your
              personal information are subject to enforceable contractual
              obligations of confidentiality. We conduct security and privacy
              protection training courses and tests to enhance our employees'
              awareness of the importance of protecting personal information. We
              will take all practicable steps to safeguard your personal
              information. However, you should be aware that the use of the
              Internet is not entirely secure, and for this reason, we cannot
              guarantee the security or integrity of any personal information
              when transferred from you or to you via the Internet. We handle
              personal data breaches as required by applicable data protection
              law which includes, where required, notifying the breach to the
              relevant data protection supervisory authority and data subjects.
            </p>
            <p>3.2 Retention Policy</p>
            <p>
              We retain personal information for the period necessary for the
              purpose of the information collection described in this Privacy
              Policy, or as required by applicable laws. We will cease to retain
              and delete or anonymize personal information once the purpose of
              collection is fulfilled, or after we confirm your request for
              erasure, or after we terminate the operation of the corresponding
              service. An exception to this is personal information that we are
              processing for compliance with applicable laws, orders of relevant
              authorities, public interest, scientific, historical research, or
              statistical purposes. Xiaomi will continue to retain this type of
              information for longer than its standard retention period, even
              after deletion or cancellation of your user account, where
              required and/or permitted based on applicable laws or your
              request.
            </p>
            <h4>4. Your Rights</h4>
            <p>4.1 Controlling Settings</p>
            <p>
              Xiaomi recognizes that privacy concerns differ from person to
              person. Therefore, we provide examples of ways Xiaomi makes
              available to you on collection, use, disclosure or processing of
              your personal information and control your privacy settings. If
              you have previously agreed to us using your personal information
              for the aforementioned purposes, you may change your mind at any
              time by writing or emailing us at privacy@xiaomi.com.
            </p>
            <p>
              4.2 Your rights to your personal information Depending on
              applicable laws and regulations, you have the right to access,
              rectification, and erasure of any other personal information that
              we hold about you (hereinafter referred to as the request).
            </p>
            <p>
              This Privacy Policy requires that your request satisfy applicable
              laws and regulations and the following conditions:
            </p>
            <p>
              1. Through Xiaomi's exclusive access to request and for the
              protection of your information security, your request should be in
              writing; 2. Provide sufficient information to enable Xiaomi to
              verify your identity and ensure that the applicant is the subject
              or legally authorized person of the requested information. Once we
              obtain sufficient information to confirm that your request can be
              processed, we shall proceed to respond to your request within any
              timeframe set out under your applicable data protection laws.
            </p>
            <p>In detail:</p>
            <p>
              - You have the right to be provided with clear, transparent and
              easily understandable information about how we use your personal
              information and your rights. This is why we are providing you with
              the information in this Privacy Policy.
            </p>
            <p>
              - If any information we are holding on you is incorrect or
              incomplete, you are entitled to have your personal information
              corrected or completed based on the purpose of use.
            </p>
            <p>
              - Based on the requirements of applicable laws, you have the right
              to request the deletion or removal of your personal information
              where there is no compelling reason for us to keep using it. We
              shall consider the grounds regarding your erasure request and take
              reasonable steps, including technical measures. If the right is
              upheld, we may not be able to immediately remove the information
              from the backup system due to applicable legal and security
              technologies. If this is the case, we will securely store your
              personal information and isolate it from any further processing
              until the backup can be cleared or be made anonymous.
            </p>
            <p>
              - We have the right to refuse to process requests that are not
              meaningful/entangled, requests that damage others' right of
              privacy, extremely unrealistic requests, requests that require
              disproportionate technical work, and requests not required under
              local law, information that have been made public, the information
              given under confidential conditions. If we believe that certain
              aspects of the request to delete or access the information may
              result in our inability to legally use the information for the
              aforementioned anti-fraud and security purposes, it may also be
              rejected.
            </p>
            <h4>4.3 Withdrawal of Consent</h4>
            <p>
              You may withdraw your consent previously provided to us for a
              particular purpose by submitting a request, including collecting,
              using, and/or disclosing your personal information in our
              possession or control. You can send an email to
              privacy@xiaomi.com. We will process your request within a
              reasonable time from when the request was made, and thereafter not
              collect, use and/or disclose your personal information as per your
              request. Depending on the extent of your withdrawal of consent,
              please note that you may not be able to continue receiving the
              full benefit of Xiaomi’s products and services. The withdrawal of
              your consent or authorization will not affect the validity of our
              processing carried out on the basis of the consent up until the
              point of withdrawal.
            </p>
            <h4>5. How your personal information is transferred globally</h4>
            <p>
              Your personal information will be stored only in data centres in
              India, and will not be transferred or stored outside India.
            </p>
            <h4>6. Protection of minors</h4>
            <p>
              We consider it the responsibility of the parent or guardian to
              supervise the child's use of our services. However, we do not
              offer services directly to a child or use personal data of
              children for the purposes of marketing. If you are a parent or
              guardian and you believe that the minor has provided Xiaomi with
              personal information, please contact us at privacy@xiaomi.com to
              ensure that the personal information is removed immediately and
              that the minor is unsubscribed from any of the applicable Xiaomi
              services.
            </p>
            <h4>
              7. Do I have to agree to any third party terms and conditions?
            </h4>
            <p>
              Our Privacy Policy does not apply to products or services offered
              by a third party. Depending on the service you use, it may
              incorporate a third parties’ products or services. Some of these
              will be provided in the form of links to third parties’ websites,
              and some will be accessed in the form of SDKs, APIs, etc. Your
              information may also be collected when you use these services. For
              this reason, we strongly suggest that you take the time to read
              the third party’s privacy policy just like you read ours. We are
              not responsible for and cannot control how third parties use
              personal information which they collect from you. Our Privacy
              Policy does not apply to other sites linked from our services.{" "}
            </p>
            <h4>8. How we update this privacy policy</h4>
            <p>
              We review the Privacy Policy periodically based on changes in
              business and technology, and we may update this Privacy Policy. If
              we make a change to this Privacy Policy, we will notify you via
              your registered contact information such as email (sent to the
              email address specified in your account) or publish on the
              homepage of the services, or notify you via mobile devices so that
              you can learn about the information we collect and how we use it.
              We will ask for your explicit consent when we collect additional
              personal information from you or when we use or disclose your
              personal information for new purposes.
            </p>
            <h4>9. Contact us</h4>
            <p>
              If you have any comments or questions about this Privacy Policy or
              any questions relating to Xiaomi’s collection, use, or disclosure
              of your personal information, please contact us at the address
              below referencing “Privacy Policy”.
            </p>
            <p>Xiaomi Technology India Private Limited,</p>
            <p>Building Orchid, Block E, Embassy Tech Village,</p>
            <p>Outer Ring Road, Devarabisanahalli, Bengaluru, </p>
            <p>Karnataka – 560103, India</p>
            <p>Email: privacy@xiaomi.com</p>
            <p>
              For any other complaints, comments or questions about POCO
              Community, not relating to this Privacy Policy, please email the
              Grievance Officer at the email address provided below.
            </p>
            <h4>Grievance Officer Details:</h4>
            <p>Name: Vishwanath C</p>
            <p>Email ID: grievance.officer@xiaomi.com</p>
            <p>Contact no.: 080 6885 6286, Mon-Sat: 9 AM to 6 PM</p>
            <p>Address: Xiaomi Technology India Pvt Ltd, Ground Floor,</p>
            <p>AKR Infinity, Kudlu Gate,</p>
            <p> Krishna Reddy Industrial Area,</p>
            <p> Hosapalaya, Muneshwara Nagar,</p>
            <p>Bengaluru, Karnataka 560068,</p> India
            <p>Thank you for taking the time to read our Privacy Policy!</p>
          </Box>
        </Grid>
      </Container>
      <div className="clearboth">&nbsp;</div>
    </React.Fragment>
  );
};
