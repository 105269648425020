import * as actions from "redux/constant/types";
import { getTopRakingUsers, getUserProfile } from "./userProfileAction";
import { toastOpen, isPageLoad } from "redux/actions/globalAction";
import { getCookies, $axios, getEncrypt } from "core/utils";

export const followersLoad = () => ({
  type: actions.USER_FOLLOWERS_LOAD,
});

export const followersSuccess = (res) => ({
  type: actions.USER_FOLLOWERS_SCCESS,
  payload: res,
});

export const myDevicesSuccess = (res) => ({
  type: actions.MY_DEVICES_SUCCESS,
  payload: res,
});

export const myDraftSuccess = (res) => ({
  type: actions.MY_DRAFT_SUCCESS,
  payload: res,
});

//  user followers  action
export const getFollowers = (userId = 0) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId: loginId = 0 } = cookies ? cookies : "";
    const dataEncrypt = userId + "&" + userId;
    await $axios
      .get(`/api/poco/user/user-follow/followers/${getEncrypt(dataEncrypt)}`, {
        headers: { Authorization: `Bearer ${apiToken}`, JSessionID: loginId },
      })
      .then((res) => {
        if (res) {
          dispatch(followersSuccess(res.data));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

export const followingLoad = () => ({
  type: actions.USER_FOLLOWING_LOAD,
});

export const followingSuccess = (res) => ({
  type: actions.USER_FOLLOWING_SCCESS,
  payload: res,
});

//  user followings action
export const getFollowings = (userId = 0) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId: loginId = 0 } = cookies ? cookies : "";
    const dataEncrypt = userId + "&" + userId;
    await $axios
      .get(`/api/poco/user/user-follow/followings/${getEncrypt(dataEncrypt)}`, {
        headers: { Authorization: `Bearer ${apiToken}`, JSessionID: loginId },
      })
      .then((res) => {
        if (res) {
          dispatch(followingSuccess(res.data));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

//  user follow action
export const followUser = (userId = 0, followerId = 0) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId: loginId = 0 } = cookies ? cookies : "";
    // object to follow user
    const followUser = {
      userId: userId,
      followerId: followerId,
    };
    await $axios
      .post(
        `/api/poco/user/user-follow/follow`,
        getEncrypt(JSON.stringify(followUser)),
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
             'content-type': 'text/json',
            JSessionID: loginId,
          },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(getFollowings(userId));
          dispatch(getTopRakingUsers(userId));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: "Following",
              toastVariant: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

//  user unfollow action
//  type 0 for remove follower and type 1 for unfollow
export const unfollowUser = (userId = 0, followerId = 0, type = 0) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId: loginId = 0 } = cookies ? cookies : "";
    const removefollower = followerId + "&" + userId;
    const unfollow = userId + "&" + followerId;
    await $axios
      .delete(
        `${
          type === 0
            ? `/api/poco/user/user-follow/unfollow?userId=${getEncrypt(
                removefollower
              )}`
            : `/api/poco/user/user-follow/unfollow?userId=${getEncrypt(
                unfollow
              )}`
        }`,
        {
          headers: { Authorization: `Bearer ${apiToken}`, JSessionID: loginId },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(getFollowers(userId));
          dispatch(getFollowings(userId));
          dispatch(getTopRakingUsers(userId));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: "Unfollowing",
              toastVariant: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

export const bookmarkedLoad = () => ({
  type: actions.USER_BOOKMARKED_LOAD,
});

export const bookmarkedSuccess = (res) => ({
  type: actions.USER_BOOKMARKED_SCCESS,
  payload: res,
});

//  user bookmared posts
export const getBookmarked = (page = 1, size = 10, userId = 0) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId:loginId = 0 } = cookies ? cookies : "";
    const dataEncrypt = userId + "&" + userId;
    await $axios
      .get(
        `/api/poco/v1/post/bookmarks?page=${page}&size=${size}&userId=${getEncrypt(
          dataEncrypt
        )}`,
        {
          headers: { Authorization: `Bearer ${apiToken}`, "JSessionID": loginId  },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(bookmarkedSuccess(res.data));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

//  post bookmark action
export const postBookMark = (postId = 0, userId = 0) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId:loginId = 0 } = cookies ? cookies : "";
    // object to follow user
    const bookMark = {
      postId: postId,
      userId: userId,
    };
    await $axios
      .post(
        `/api/poco/v1/post/bookmark`,
        getEncrypt(JSON.stringify(bookMark)),
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
             'content-type': 'text/json',
            "JSessionID": loginId 
          },
        }
      )
      .then((res) => {
        if (res) {
          res.status === 200
            ? dispatch(
                toastOpen({
                  isToastOpen: true,
                  toastMessage: "Post Bookmarked",
                  toastVariant: "success",
                })
              )
            : dispatch(
                toastOpen({
                  isToastOpen: true,
                  toastMessage: res.data.message,
                  toastVariant: "success",
                })
              );
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

//  post bookmark delete action
export const deleteBookMark = (userId = 0, postId = 0) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId:loginId = 0 } = cookies ? cookies : "";
    const dataEncrypt = postId + "&" + userId;
    await $axios
      .delete(`/api/poco/v1/post/bookmark?postId=${getEncrypt(dataEncrypt)}`, {
        headers: { Authorization: `Bearer ${apiToken}`, "JSessionID": loginId  },
      })
      .then((res) => {
        if (res) {
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: "Bookmark Deleted",
              toastVariant: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

// Report user action
export const reportUser = (userId = 0, comment = 0) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId: loginId = 0 } = cookies ? cookies : "";
    // object to follow user
    const requestBody = {
      userId: userId,
      comment: comment,
    };
    await $axios
      .post(
        `/api/poco/user/report`,
        getEncrypt(JSON.stringify(requestBody)),
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
             'content-type': 'text/json',
            JSessionID: loginId,
          },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: res.data.message,
              toastVariant: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};


//  Get User Devices List
export const getMyDevices = () => {
  return async (dispatch) => {
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId:loginId = 0 } = cookies ? cookies : "";
    await $axios
      .get(
        `/api/poco/user/profile/imei`,
        {
          headers: { Authorization: `Bearer ${apiToken}`, "JSessionID": loginId  },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(myDevicesSuccess(res.data));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

//  Delete devices action
export const removeMydevices = (imei1 = null) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId:loginId = 0 } = cookies ? cookies : "";
    await $axios
      .delete(`/api/poco/user/profile/imei?imei=${imei1}`, {
        headers: { Authorization: `Bearer ${apiToken}`, "JSessionID": loginId  },
      })
      .then((res) => {
        if (res) {
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: "Device Deleted",
              toastVariant: "success",
            })
          );
         res.status === 200 && dispatch(myDevicesSuccess(imei1))
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

// Report user action
export const registerIMEINumber = (imei1 = 0,userId = 0) => {
  return async (dispatch) => {
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId: loginId = 0 } = cookies ? cookies : "";
    // object to follow user
    const requestBody = {
      imei1,
      userId,
    };
    await $axios
      .post(
        `/api/poco/user/profile/imei`,
        getEncrypt(JSON.stringify(requestBody)),
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
             'Content-type': 'text/json',
            JSessionID: loginId,
          },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: "Device added successfully",
              toastVariant: "success",
            })
          );
          res.status === 200 && dispatch(getMyDevices())
          res.status === 200 && dispatch(getUserProfile())
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

//  Get MyDraft list 
export const getMyDrafts = (page= 1, size = 10) => {
  return async (dispatch) => {
    //  method will get token to access api
    const cookies = getCookies();
    const { apiToken, userId:loginId = 0 } = cookies ? cookies : "";
    await $axios
      .get(
        `/api/poco/v1/post/draft?page=${page}&size=${size}`,
        {
          headers: { Authorization: `Bearer ${apiToken}`, "JSessionID": loginId  },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(myDraftSuccess(res.data));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.response.data.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};
