import React, { useEffect } from "react";
import { Grid, Container, Box, Typography } from "@material-ui/core";
import { Imagesimport } from "core/utils";
import "./index.scss";

export const NotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Container maxWidth="lg" className="not-found-section">
        <Box height="90vh">
          <Grid container>
            <Grid item xs={12} sm={7} md={8} lg={8}>
              <Box mt={12} textAlign="center">
                <Typography className="error-code" component="span">
                  Oops !
                </Typography>
                <Typography className="page">
                  We can't seem to find the page <br /> You're looking for.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5} md={2} lg={2}>
              <Box mt={12} className="mt-0">
                <img
                  src={Imagesimport["poco-log.png"]}
                  className="poco-icon"
                  alt=""
                  width="100%"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <div className="clearboth">&nbsp;</div>
    </React.Fragment>
  );
};
