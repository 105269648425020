import React, { useEffect } from "react";
// Matrial element
import { Grid, Box } from "@material-ui/core";

import { AddPost } from "components/elements/addPost";
import PocoXSeries from "./postList/pocoXseries";
import PocoMSeries from "./postList/pocoMseries";
import PocoCSeries from "./postList/pocoCseries";
import PocoFSeries from "./postList/pocoFseries";
// Redux action calls
import "./index.scss";

export default function ProductPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    // get url product url id to scroll into a section
    let url = window.location.pathname;
    let productSeriesId = url.substring(url.lastIndexOf("/") + 1);
    const productScroll = document.getElementById(productSeriesId);
    setTimeout(() => {
      if (productScroll) {
        productScroll.scrollIntoView({
          top: 200,
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    });
  });

  return (
    <div className="product-page">
      <Box mt={10} mb={2} p={3}>
        <Grid container component="span" spacing={5}>
          <Grid item xs={12} sm={12} md={8} component="span">
            <Grid container spacing={5} component="span">
              <PocoXSeries />
              <PocoMSeries />
              <PocoCSeries />
              <PocoFSeries />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} component="span">
            <Box mt={5}>
              <AddPost />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
