import * as actions from "redux/constant/types";
import { getCookies, $axios, getEncrypt } from "core/utils";
import { toastOpen, isPageLoad } from "redux/actions/globalAction";

// Header action start
export const menuItemsSucess = (res) => ({
  type: actions.MENU_ITEMS_SUCCESS,
  payload: res,
});

// headerMenu static json structure
export const getMenuItems = () => {
  const headerStatic = {
    headerMenu: [
      {
        id: "be2dcc10-5c7a-11eb-9168-931778d0bc",
        name: "Home",
        path: "/home",
        isHoverMenu: false,
        subMenus: [],
      },
      {
        id: "be2dcc10-5c7a-11eb-9168-931778d09dbc",
        name: "Discover",
        path: "/discover/all",
        isHoverMenu: true,
        subMenus: [
          {
            id: "SFEF123-FSDF",
            name: "News",
          },
          {
            id: "ASD23-EFSC",
            name: "Events",
          },
          {
            id: "ZXC-34ASD",
            name: "Photography",
          },
          {
            id: "ZXC-34ASD",
            name: "Tech",
          },
          {
            id: "ZXC-34ASD",
            name: "Resources",
          },
        ],
      },
      {
        id: "ca7e4350-5c7a-11eb-9e1e-cf3e549c3962",
        name: "Series",
        path: "/Thread/all",
        isHoverMenu: true,
        subMenus: [
          {
            id: "defj21-1ncjd",
            name: "X Series",
          },
          {
            id: "efj252-1ncjd",
            name: "M Series",
          },
          {
            id: "123457-32434",
            name: "C Series",
          },
          {
            id: "378EE7R6-QWRWER",
            name: "F Series",
            product: [
              {
                name: "POCO X2",
              },
              {
                name: "POCO X3",
              },
            ],
          },
        ],
      },
      {
        id: "be2dascc10ss-5c7a-11eb-9168-931778d09dbc",
        name: "Suggestions & feedback",
        path: "/feedback",
        isHoverMenu: false,
        subMenus: [],
      },
    ],
  };
  return (dispatch) => {
    dispatch(menuItemsSucess(headerStatic.headerMenu));
  };
};

// Footer action start
export const footerItemsLoad = (load) => ({
  type: actions.FOOTER_ITEMS_LOAD,
  payload: load,
});

export const footerItemsSucces = (res) => ({
  type: actions.FOOTER_ITEMS_SUCCESS,
  payload: res,
});

// Download center action start
export const downloadCenterSuccess = (res) => ({
  type: actions.DOWNLOAD_CENTER_SCCESS,
  payload: res,
});

export const getDownloadCenter = (userId) => {
  return async (dispatch) => {
    const cookies = getCookies();
    const { apiToken, userId: loignId = 0 } = cookies ? cookies : "";
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    const dataEncrypt = userId + "&" + userId;
    // dispatch(downloadCenterSuccess(data));
    await $axios
      .get(
        `/api/poco/v1/post/downloadCenter/all?userId=${getEncrypt(
          dataEncrypt
        )}`,
        {
          headers: { Authorization: `Bearer ${apiToken}`, JSessionID: loignId },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(downloadCenterSuccess(res.data.resultData));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        if (err.response) {
          dispatch(
            toastOpen({
              isToastOpen: true,
              toastMessage: err.message,
              toastVariant: "error",
            })
          );
        }
      });
  };
};

// Kudos count increase
export const postKudosSuccess = (res) => ({
  type: actions.KUDOS_COUNT_SUCCESS,
  payload: res,
});

export const notificationSuccess = (res) => ({
  type: actions.NOTIFICATION_SUCCESS,
  payload: res,
});

export const postKudosCount = (cardId = 0, userId = 0) => {
  return async (dispatch) => {
    const cookies = getCookies();
    const { apiToken, userId: loignId = 0 } = cookies ? cookies : "";
    const payloadData = {
      fileId: cardId,
      userId,
    };
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    await $axios
      .post(
        `/api/poco/v1/post/downloadCenter/thanks`,
        getEncrypt(JSON.stringify(payloadData)),
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
            JSessionID: loignId,
            "Content-Type": "text/json",
          },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(getDownloadCenter(userId));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        dispatch(
          toastOpen({
            isToastOpen: true,
            toastMessage: err.message,
            toastVariant: "error",
          })
        );
      });
  };
};


export const getNotification = () => {
  return async (dispatch) => {
    const cookies = getCookies();
    const { apiToken, userId: loginId = 0 } = cookies ? cookies : "";
    dispatch(isPageLoad({ isPageLoaderOpen: true }));
    await $axios
      .get(
        `/api/poco/v1/post/notification`,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
            JSessionID: loginId,
            "Content-Type": "text/json",
          },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(notificationSuccess(res.data));
          dispatch(isPageLoad({ isPageLoaderOpen: false }));
        }
      })
      .catch((err) => {
        dispatch(isPageLoad({ isPageLoaderOpen: false }));
        dispatch(
          toastOpen({
            isToastOpen: true,
            toastMessage: err.message,
            toastVariant: "error",
          })
        );
      });
  };
};
