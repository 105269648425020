import React from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { CustomLink } from "components/elements/customLink";
import "./index.scss";

export function AddPost(props) {
  const { isLoggedinUser } = useSelector((state) => state.userProfile);

  return (
    <div className="add-post-section">
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Box
            display="flex"
            p={4}
            bgcolor="text.primary"
            justifyContent="center"
            height={70}
            className="d-none-xs"
          >
            <CustomLink loggedIn={isLoggedinUser} path={"/addpost"} isfromUserFlow={true} >
              <Button
                size="large"
                variant="contained"
                color="secondary"
                fullWidth
                disableRipple
                disableFocusRipple
              >
                <strong>Add Post</strong>
              </Button>
            </CustomLink>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Box
            display="flex"
            mt={3}
            mb={3}
            p={2}
            bgcolor="text.primary"
            justifyContent="center"
            height={70}
            className="d-none-xs"
          >
            <CustomLink loggedIn={isLoggedinUser} path={"/calendar"}>
              <Button
                size="large"
                variant="text"
                color="default"
                fullWidth
                disableRipple
                disableFocusRipple
              >
                <strong>Check In</strong>
              </Button>
            </CustomLink>

            <CustomLink loggedIn={isLoggedinUser} path={"/task"}>
              <Button
                size="large"
                variant="text"
                color="default"
                fullWidth
                disableRipple
                disableFocusRipple
              >
                <strong>Tasks</strong>
              </Button>
            </CustomLink>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
