import * as actions from "redux/constant/types";

const intialState = {
  postContentLoad: false,
  postContent: undefined,
  userCommentsLoad: false,
  userComments: undefined,
  postLikeDisLikeCountsLoad: false,
  postLikeDisLikeCounts: undefined,
  autoSavePostResponse: undefined,
  attachments: []
};

// Home reducer start
export const postDetailsReducer = (state = intialState, action) => {
  switch (action.type) {
    case actions.ALLPOSTDETAILS_ITEMS_LOAD:
      return {
        ...state,
        postContentLoad: true,
      };

    case actions.ALLPOSTDETAILS_ITEMS_SUCCESS:
      return {
        ...state,
        postContentLoad: false,
        postContent: action.payload,
      };
      case actions.CLEAR_POSTDETAILS_CONTENT:
      return {
        ...state,
        postContentLoad: false,
        postContent: undefined,
      };
      case actions.USER_COMMENTS_LOAD:
        return {
          ...state,
          userCommentsLoad: true,
        };
  
      case actions.USER_COMMENTS_SUCCESS:
        return {
          ...state,
          userCommentsLoad: false,
          userComments: action.payload,
        };
        case actions.POST_LIKE_DISLIKE_COUNT_LOAD:
        return {
          ...state,
          postLikeDisLikeCountsLoad: action.payload,
        };
        case actions.POST_LIKE_DISLIKE_COUNT_SUCCESS:
        return {
          ...state,
          postLikeDisLikeCounts: action.payload,
        };
        case actions.SET_AUTOSAVE_POSTID:
        return {
          ...state,
          autoSavePostResponse:  action.payload
        };
        case actions.GET_ATTACHMENTS:
          return {
            ...state,
            attachments:  action.payload
          };
    default:
      return state;
  }
};
