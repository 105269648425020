import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { MainLayout } from "components/elements/layout/mainLayout";
import HomePage from "components/homePage";
import ProductPage from "components/productPage";
import DiscoverPage from "components/discoverPage";
import AddpostPage from "components/addpostPage";
import FeedbackPage from "components/feedbackPage";
import Suggestion from "components/feedbackPage/suggestion";
import ReportBug from "components/feedbackPage/reportBug";
// import VotePoll from "components/feedbackPage/votePoll";
import UserProfile from "components/userpage/userTab";
import UserSettingPage from "components/userpage/userProfileSettings";
import PostManagement from "components/cmsAdmin/pages/postManagement";
import UserManagement from "components/cmsAdmin/pages/userManagement";
// import Adminlogin from "components/cmsAdmin/Adminlogin";
// import HomeLayout from "components/cmsAdmin/pages/HomeLayout";
// import CMSHome from "components/cmsAdmin/pages/CMSHome";
// import Events from "components/cmsAdmin/pages/Events/Events";
// import News from "components/cmsAdmin/pages/News/News";
// import Dashboard from "components/cmsAdmin/pages/Dashboard";
import PostSearch from "components/postSearchPage";
import { PostPage } from "components/postPage";
import { Allcalender } from "components/homePage/TabComponents/calender";
import { Alltask } from "components/homePage/TabComponents/task";
import { ErrorHandler } from "components/elements/errorhandler";
import { Aboutus } from "components/elements/footer/Aboutus";
import { Termsuse } from "components/elements/footer/Termsuse";
import { Privacypolicy } from "components/elements/footer/Privacypolicy";
import { DownloadCenter } from "components/elements/footer/Downloadcenter";
import { FAQ } from "components/elements/footer/subPage/Faq";
import { Instruction } from "components/elements/footer/subPage/Instruction";
import { NotFound } from "components/elements/notFound";
import { isValidToken } from "core/utils";

//import {LoginLayoutRoute}  from "components/elements/layout/layoutAdmin";
// import Accessories from 'components/cmsAdmin/pages/Dashboard';
//import ProductOverview from "components/cmsAdmin/pages/ProductOverview/ProductOverview";
//import ProductSpecification from "components/cmsAdmin/pages/ProductSpecification/ProductSpecification";

const loggedIn = isValidToken();
const Router = () => (
  <BrowserRouter>
    <ErrorHandler>
      <MainLayout isLoggedIn={loggedIn}>
        {window.location.href.includes("token") && <Redirect to="/home" />}
        <Switch>
          {/* <PrivateRoute path="/home">
            <HomePage />
          </PrivateRoute> */}
          {/* <PrivateRoute exact path="/">
            <HomePage />
          </PrivateRoute> */}
          {/* <PrivateRoute exact path="/Thread/:id">
            <ProductPage />
          </PrivateRoute>
          <PrivateRoute exact path="/discover/:id">
            <DiscoverPage />
          </PrivateRoute>
          <PrivateRoute exact path="/feedback">
            <FeedbackPage />
          </PrivateRoute>
          <PrivateRoute exact path="/suggestion">
            <Suggestion />
          </PrivateRoute>
          <PrivateRoute exact path="/reportbug">
            <ReportBug />
          </PrivateRoute> */}
          <PrivateRoute exact path="/task">
            <Alltask />
          </PrivateRoute>
          {/* <PrivateRoute exact path="/post/:id">
            <PostPage />
          </PrivateRoute> */}
          {/* <PrivateRoute exact path="/search">
            <PostSearch />
          </PrivateRoute> */}
          {/* Below route for guest users routing */}
          <Route exact path="/" component={HomePage} />
          <Route exact path="/home" component={HomePage} />
          <Route exact path="/Thread/:id" component={ProductPage} />
          <Route exact path="/discover/:id" component={DiscoverPage} />
          <Route exact path="/feedback" component={FeedbackPage} />
          <Route exact path="/suggestion" component={Suggestion} />
          <Route exact path="/reportbug" component={ReportBug} />
          {/* <Route exact path="/votepoll" component={VotePoll} /> */}
          {/* <Route exact path="/task" component={Alltask} /> */}
          <Route exact path="/post/:id" component={PostPage} />
          <Route exact path="/search" component={PostSearch} />

          {/* // admin section */}
          {/* <Route exact path="/admin" component={Adminlogin} />
          <Route exact path="/admin/dashboard" component={Dashboard} />
          <Route exact path="/homelayout" component={HomeLayout} />
          <Route exact path="/admin/cmshome" component={CMSHome} />
          <Route exact path="/admin/events" component={Events} />
          <Route exact path="/admin/news" component={News} /> */}
          {/* Footer components */}
          {/* <PrivateRoute exact path="/aboutus">
            <Aboutus />
          </PrivateRoute>
          <PrivateRoute exact path="/Termsuse">
            <Termsuse />
          </PrivateRoute>
          <PrivateRoute exact path="/privacypolicy">
            <Privacypolicy />
          </PrivateRoute> */}
          <PrivateRoute exact path="/downloadcenter">
            <DownloadCenter />
          </PrivateRoute>
          <PrivateRoute exact path="/instruction">
            <Instruction />
          </PrivateRoute>
          <PrivateRoute exact path="/faq">
            <FAQ />
          </PrivateRoute>
          {/* Guest user router for footer */}
          <Route exact path="/aboutus" component={Aboutus} />
          <Route exact path="/termsuse" component={Termsuse} />
          <Route exact path="/privacypolicy" component={Privacypolicy} />
          {/* Login required components */}
          <PrivateRoute exact path="/calendar">
            <Allcalender />
          </PrivateRoute>
          <PrivateRoute exact path="/addpost">
            <AddpostPage />
          </PrivateRoute>
          {/* <Route exact path="/profile/:userId" component={UserProfile} /> */}
          {/* <Route exact path="/profile/:userId">
            {loggedIn ? <UserProfile /> : <Redirect to="/" />}
          </Route> */}
          <PrivateRoute path="/profile/:userId">
            <UserProfile />
          </PrivateRoute>
          <PrivateRoute path="/settings/:userId">
            <UserSettingPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin">
            <PostManagement />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/user">
            <UserManagement />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/post/:id">
            <PostPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/editpost">
            <AddpostPage />
          </PrivateRoute>
          <Route path="*" component={NotFound} />
        </Switch>
      </MainLayout>
    </ErrorHandler>
  </BrowserRouter>
);

export default Router;

export function PrivateRoute({ children, ...rest }) {
  const clientToken = process.env.REACT_APP_CLIENT_SOCIAL_LOGIN_TOKEN;

  return (
    <Route
      render={() =>
        isValidToken() ? (
          <Route {...rest} component={children.type} />
        ) : (
          <Route
            {...rest}
            render={({ location }) => (
              <div style={{ display: "none" }}>
                {
                  (window.location.href = `${process.env.REACT_APP_SSO_URL}?callbackUrl=${window.location.href}&clientToken=${clientToken}`)
                }
              </div>
            )}
          />
        )
      }
    />
  );
}


// old code backup

// loggedIn ? (
//   <Route {...rest} component={children.type} />
// ) : (
//   <Route
//     {...rest}
//     render={({ location }) => (
//       <div style={{ display: "none" }}>
//         {
//           (window.location.href = `${process.env.REACT_APP_SSO_URL}?callbackUrl=${window.location.href}&clientToken=${clientToken}`)
//         }
//       </div>
//     )}
//   />
// );