import { fade, makeStyles,withStyles, emphasize } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

//  Header style start
export const HeaderStyle = makeStyles((theme) => ({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }));

  // Home page tab start
  export const homeTabStyle = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
  }));

// page loader style
  export const pageLoaderStyle = makeStyles((theme) => ({
    backdrop: {
       
      color: "#ece70b",
    },
  }));
  

  export const formStyle = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
      },
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
      },
      width: 100+'%'
    },
  }));


  export const userCommentStyle = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: '100%',
      backgroundColor: '#00000000',
    },
    inline: {
      display: 'inline',
    },
    
  }));
  
//  verticle Tab style
  export const verticleTabStyle = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      display: 'flex',
      height:"auto",
    },
    tabs: {
      borderLeft: `4px solid ${theme.palette.divider}`,
    }
  }));

//  Task style
  export const taskStyle = makeStyles((theme) => ({
    root: {
      display: "flex",
      marginTop: 20,
      marginBottom: 20,
      marginRight: 20,
      backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)",
    },
    details: {
      display: "flex",
      flexDirection: "column",
      width: 100 + "%",
    },
    content: {
      flex: "1 0 auto",
      height: 100,
      overflow: "hidden",
    },
    taskComplete: {
      color: theme.palette.success.light,
    },
  }));

  export const BreadcrumbStyle = withStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.grey[100],
      height: theme.spacing(3),
      color: theme.palette.grey[800],
      fontWeight: theme.typography.fontWeightRegular,
      cursor: "pointer",
      '&:hover, &:focus': {
        backgroundColor: theme.palette.grey[300],
      },
      '&:active': {
        boxShadow: theme.shadows[1],
        backgroundColor: emphasize(theme.palette.grey[300], 0.12),
      },
    },
  }))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591
  

  export const tableStyle = makeStyles({
    root: {
      width: "100%",
    },
    container: {
      maxHeight: "100%",
    },
  });