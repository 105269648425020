import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStateAndCityByPincode, updateUserProfile } from "redux/actions/userProfileAction";

import {
  Grid,
  Button,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Typography,
} from "@material-ui/core";

export default function UserInfromation() {
  const { userProfile } = useSelector((state) => state.userProfile);
  const { userInfo } = userProfile ? userProfile.userProfile : {};
  // user store information
  const { city, dob, firstName, gender, lastName, userId,state,pincode } = userInfo || "";
  const [userInformation, setUserInformation] = useState({
    firstName: firstName,
    lastName: lastName,
    country: "India",
    city: city,
    state:state,
    pincode:pincode,
    gender: gender,
    dob: dob,
  });

  const dispatch = useDispatch();

  const validateDate = (e) => {
    let todaysDate = new Date().getDate().toString().padStart(2, 0);
    let todaysMonth = (new Date().getMonth() + 1).toString().padStart(2, 0);
    let todaysYear = new Date().getFullYear().toString().padStart(2, 0);
    let isValidDate =
      new Date(`${todaysYear}-${todaysMonth}-${todaysDate}`) >=
      new Date(e.target.value);

    if (e.target.value.trim() && isValidDate) {
      setUserInformation({ ...userInformation, dob: e.target.value });
      if (e.target.value <= "1921-01-01") {
        setUserInformation({ ...userInformation, dob: "" });
      }
    } else {
      setUserInformation({ ...userInformation, dob: "" });
    }
  };

  const handleChange = (e, dob) => {
    if (dob) {
      setUserInformation({ ...userInformation, dob: e.target.value });
    } else {
      if (
        e.target.value.match(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/) ||
        e.target.value.trim() === ""
      ) {
        setUserInformation({
          ...userInformation,
          [e.target.name]: e.target.value,
        });
      }
    }
  };
  const callback = (data) =>{
    setUserInformation(pre=>({
      ...pre,
      state: data!==""?data.state:"",
      city: data!==""?data.city:"",
    }));
  }
  const handlePincode = (e) =>{
    let pincodeObj ={pincode: e.target.value}
    if(e.target.value.length===6){
      setUserInformation(pre=>({...pre,...pincodeObj}));
      getStateAndCityByPincode(e.target.value,callback,dispatch)
    }else{
      setUserInformation(pre=>({
        ...pre,
        ...pincodeObj,
        state: "",
        city: "",
      }));
    }
  }
  const updatedUserProfileInfo = () => {
    if (userInformation.firstName.trim() && isValidPincode() ){
      dispatch(updateUserProfile(userId, userInformation));
    }
  };

  const isValidPincode=()=>{
    return (userInformation.pincode==='' && userInformation.state==='' && userInformation.city==='') ||
            (userInformation.pincode!=='' && userInformation.state!=='' && userInformation.city!=='')
  }
  
  
  const renderUserInformation = () => {
    return (
      <React.Fragment>
        <Typography variant="h6">
          <strong>User Information</strong>
        </Typography>
        <form noValidate autoComplete="off">
          <Grid container component="span">
            <Grid container spacing={4} component="span">
              <Grid item xs={12} sm={6} md={6} component="span">
                <TextField
                  id="outlined"
                  label="First Name"
                  placeholder=""
                  variant="outlined"
                  size="medium"
                  margin="normal"
                  component="span"
                  name="firstName"
                  onInput={(e) => {
                    e.target.value = e.target.value.slice(0, 30);
                  }}
                  error={
                    userInformation.firstName
                      ? !userInformation.firstName.trim()
                      : true
                  }
                  helperText={
                    userInformation.firstName &&
                      userInformation.firstName.trim()
                      ? null
                      : "This field is Required!"
                  }
                  value={userInformation.firstName}
                  onChange={(e) => handleChange(e)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} component="span">
                <TextField
                  id="outlined"
                  label="Last Name"
                  placeholder=""
                  variant="outlined"
                  size="medium"
                  margin="normal"
                  component="span"
                  name="lastName"
                  onInput={(e) => {
                    e.target.value = e.target.value.slice(0, 30);
                  }}
                  value={userInformation.lastName}
                  onChange={(e) => handleChange(e)}
                  autoComplete={false}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={4} component="span">
              <Grid item xs={12} sm={6} md={6} component="span">
                <FormControl variant="outlined" fullWidth component="span">
                  <TextField
                    id="outlined"
                    label="Location"
                    placeholder=""
                    variant="outlined"
                    size="medium"
                    margin="normal"
                    component="span"
                    name="Location"
                    value="India"
                    onChange={(e) => handleChange(e)}
                    autoComplete={false}
                    fullWidth
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} component="span">
                <TextField
                  label="Enter pincode"
                  type="number"
                  placeholder=""
                  variant="outlined"
                  size="medium"
                  margin="normal"
                  component="span"
                  name="pincode"
                  fullWidth
                  error={!isValidPincode()}
                  helperText={
                    isValidPincode()
                      ? null
                      : "Invalid pincode!"
                  }
                  value={userInformation.pincode}
                  onChange={(e)=>handlePincode(e)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} component="span">
            <Grid item xs={12} sm={6} md={6} component="span">
                <TextField
                  label="State"
                  placeholder="State"
                  variant="outlined"
                  size="medium"
                  margin="normal"
                  component="span"
                  name="state"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={userInformation.state}
                  onChange={(e) => handleChange(e)}
                  disabled
                />

              </Grid>
              <Grid item xs={12} sm={6} md={6} component="span">
                <TextField
                  label="District"
                  placeholder="District"
                  variant="outlined"
                  size="medium"
                  margin="normal"
                  component="span"
                  name="city"
                  InputLabelProps={{ shrink: true }}
                  onInput={(e) => {
                    e.target.value = e.target.value.slice(0, 28);
                  }}
                  onChange={(e) => handleChange(e)}
                  value={userInformation.city}
                  fullWidth
                  disabled
                />

              </Grid>
            </Grid>

            <Grid container spacing={4} component="span">
              <Grid item xs={12} sm={6} md={6} component="span">
                <FormControl
                  variant="outlined"
                  margin="normal"
                  component="span"
                  fullWidth
                >
                  <InputLabel htmlFor="Gender">Gender</InputLabel>
                  <Select
                    native
                    name="gender"
                    value={userInformation.gender}
                    onChange={(e) => handleChange(e)}
                    label="Gender"
                  >
                    <option value={"Male"}>Male</option>
                    <option value={"Female"}>Female</option>
                    <option value={"Other"}>Other</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} component="span">
                <TextField
                  id="date"
                  label="Date of Birth"
                  margin="normal"
                  type="date"
                  value={userInformation.dob}
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    max: `${new Date().getFullYear()}-${(
                      Number(new Date().getMonth()) + 1
                    )
                      .toString()
                      .padStart(2, 0)}-${new Date()
                        .getDate()
                        .toString()
                        .padStart(2, 0)}`,
                  }}
                  onChange={(e) => handleChange(e, "dob")}
                  onBlur={validateDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Button
                variant="contained"
                color="secondary"
                onClick={updatedUserProfileInfo}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </React.Fragment>
    );
  };
  return (
    <div className="user-information-container">{renderUserInformation()}</div>
  );
}
