import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Box,
  Grid,
  InputAdornment,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from '@material-ui/icons/Check';

export default function SearchBar(props) {
  const { autoSearch, searchData } = props;
  const searchList = searchData || [];

  // method to push component
   let history = useHistory();
  //  console.log(searchList)

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="flex-end" onClick={props.close}>
        <IconButton color="secondary">
          <CloseIcon />
        </IconButton>
      </Box>
      <Grid
        container
        justify="center"
        alignItems="center"
        className="search-bar"
      >
        <Grid item xs={12} md={6} className="search-area">
          <Autocomplete
            id="search"
            options={searchList?.map(item =>{
              return {...item,postTitle:unescape(item.postTitle)}
            })
          }
            // autoHighlight={true}
            forcePopupIcon={true}
            // loading
            autoSelect={true}
            closeIcon={false}
            // popupIcon={
            //   <CheckIcon color="secondary" onClick={()=>history.push('/search')}/>
            // }
            noOptionsText="Search Result"
            clearText="asd"
            getOptionLabel={(option) => option.postTitle}
            renderOption={(option) => (
              <React.Fragment>
                <Box className="auto-suggest">
                  {/* commented code for to rediect post details page */}
                  {/* <Link to={`/post/${option.id}`}> */}
                  <Link to={`/search`}>
                    <Box display="flex">
                      <SearchIcon />
                      <Typography variant="subtitle2">
                        {option.postTitle}
                      </Typography>
                    </Box>
                  </Link>
                </Box>
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search"
                variant="outlined"
                color="secondary"
                autoFocus
                onKeyDown={(e) => autoSearch(e)}
                startadornment={
                  <InputAdornment position="start">
                    <SearchIcon color="secondary" />
                  </InputAdornment>
                }
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
          <Box className="search-enter">
            <CheckIcon onClick={()=>history.push('/search')} />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
