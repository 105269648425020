import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { Icon } from "@material-ui/core";
import { toastOpen } from "redux/actions/globalAction";
import './index.scss'

export default function ToastAlert() {
  const dispatch = useDispatch();

  const { toastMessage, isToastOpen, toastVariant } = useSelector(
    (state) => state.global
  );

  function handleClose() {
    dispatch(toastOpen({ isToastOpen: false }));
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      autoHideDuration={3000}
      className={toastVariant}
      open={isToastOpen}
      onClose={handleClose}
      aria-describedby="client-snackbar"
      message={<span id="client-snackbar">{toastMessage}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <Icon>close</Icon>
        </IconButton>,
      ]}
    />
  );
}
