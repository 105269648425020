import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// Material ui embaded style
import { HeaderStyle } from "core/theme/makeStyle";
// Redux action calls
import {
  getMenuItems,
  getNotification,
} from "redux/actions/headerFooterAction";
import { getSearchResult } from "redux/actions/searchAction";
import { getApitoken } from "redux/actions/apiTokenAction";
import {
  getUserProfile,
  getTopRakingUsers,
} from "redux/actions/userProfileAction";

import { getLoggedInuser } from "redux/actions/userAction";
// Child components
import MobileAppBar from "./mobileAppbar";
import SearchBar from "./searchBar";
// Reusable component
import { HoverMenu } from "../hoverMenu";
import { UserProfile } from "./userProfile";

// Material ui icons
import {
  Imagesimport,
  isValidToken,
  getCookies,
  isMobile,
  getLandscapeOrientation,
} from "core/utils";
import SearchIcon from "@material-ui/icons/Search";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import NotificationsIcon from "@material-ui/icons/Notifications";

import "./index.scss";
// Material ui elements
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Grid,
  Popover,
  Grow,
  List,
  ListItem,
  Card,
  CardContent,
} from "@material-ui/core";

// reuseable methods
import { debounce } from "core/utils";

export default function Header() {
  const dispatch = useDispatch();
  const classes = HeaderStyle();
  const [hoverMenu, setHoverMenu] = useState(false);
  const [profileHover, setProfileHover] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [getMenuId, setMenuId] = useState({ id: "", path: "" });
  const [anchorEl, setAnchorEl] = useState(null);

  let history = useHistory();
  const { isValidApiToken } = useSelector((state) => state.apiAccess);

  // <!-- Global site tag (gtag.js) - Google Analytics -->
  // const script = document.createElement('script');
  // script.src = `${process.env.REACT_APP_GOOGLE_ANALYTICS_SRC}`;
  // const headerUrl = document.scripts

  // for (let item of headerUrl) {
  //   if(item.src.includes('googletagmanager')){
  //     return
  //   }else{
  //     document.head.append(script);
  //   }

  // }

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());

  gtag("config", `${process.env.REACT_APP_GOOGLE_GTAG_CONFIG}`);

  useEffect(() => {
    //  call getapitoken as first priorty
    dispatch(getApitoken());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMenuItems());
    // isValidApiToken dependecy fro synchronus call
    if (isValidApiToken) {
      const cookies = getCookies();
      const { userId } = cookies;

      // Get user profle data
      if (userId !== undefined) {
        dispatch(getUserProfile(userId));
        dispatch(getTopRakingUsers(userId));
        dispatch(getNotification());
      }
    }
    // trending user action
  }, [isValidApiToken, dispatch]);

  // check is user logged in or not and get user id from cookies
  useEffect(() => {
    const isValid = isValidToken();
    dispatch(getLoggedInuser(isValid));
  }, [dispatch, history]);

  useEffect(() => {
    window.addEventListener("resize", function () {
      if (!getLandscapeOrientation()) {
        hoverMenuClose();
        hoverProfileClose();
      }
    });
  });

  // Hover menu open
  const hoverMenuOpen = (id, path) => (event) => {
    const urlPath = path.replace(/[^/]*$/, "");

    setHoverMenu(!hoverMenu);
    setProfileHover(false);
    setMenuId({ id: id, path: urlPath });
    setSearchOpen(false);
  };

  // Hover menu close
  const hoverMenuClose = () => {
    setHoverMenu(false);
  };

  const clickProfileOpen = () => {
    setProfileHover(!profileHover);
    setHoverMenu(false);
    setSearchOpen(false);
  };
  // Hover profile open
  const hoverProfileOpen = (event) => {
    setProfileHover(true);
    setHoverMenu(false);
    setSearchOpen(false);
  };

  // hover Search bar open
  const handleSearchBar = (event) => {
    setSearchOpen(true);
    setProfileHover(false);
    setHoverMenu(false);
  };

  // click hover Search bar open
  const clickSearchBar = (event) => {
    setSearchOpen(!isSearchOpen);
    setProfileHover(false);
    setHoverMenu(false);
  };

  const handleSearchBarClose = () => {
    setSearchOpen(false);
    setProfileHover(false);
    setHoverMenu(false);
  };

  // Hover profile close
  const hoverProfileClose = () => {
    setProfileHover(false);
  };

  const openNotification = (event) => {
    setAnchorEl(event.currentTarget);
    setProfileHover(false);
    setSearchOpen(false);
  };

  const closeNotification = () => {
    setAnchorEl(null);
  };

  // Menu hover passing data from local state
  const renderHoverMenuData = () => {
    // filtered data form array which maching to sate id
    const items = menuItems?.filter(
      (element) => element.id === getMenuId.id && element
    );
    const seriesName = Object.assign({}, ...items);
    const { subMenus } = seriesName;


    return (
      <Grid container className="product-series">
        <Grid item md={3}>
          {subMenus?.map((element, index) => (
            <Typography
              className={classes.title}
              key={index}
              variant="h6"
              noWrap
            >
              <strong>
                <Link
                  to={`${getMenuId.path}${element.name.replace(/ +/g, "")}`}
                >
                  {element.name}
                </Link>
              </strong>
            </Typography>
          ))}
        </Grid>
        <Grid item md={3}>
          {/* <PocoCard
          Img={"http://placeimg.com/640/480/technics"}
          isDark={true}
          Tittle={getMenuId}
        /> */}
        </Grid>
      </Grid>
    );
  };

  // Global state call
  const { menuItems } = useSelector((state) => state.menuItem);
  const { userProfileLoad, userProfile, topRankingUsers } = useSelector(
    (state) => state.userProfile
  );
  const { searchPostList } = useSelector((state) => state.searchResult);
  const { notificationsList = [] } = useSelector(
    (state) => state.notifications
  );

  //  search result destructure
  const { tagData } = searchPostList || [];
  const { userId = 0 } = userProfile?.userProfile || {};

  const autoSearch = (searchKey) => {
    if (searchKey.target.value.length >= 3) {
      dispatch(debounce(getSearchResult(userId, searchKey.target.value), 1000));
    }
    if (searchKey.key === "Enter") {
      history.push("/search");
    }
  };
  // notification open
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Header start
  const rednderAppBar = (
    <AppBar position="fixed" color="primary">
      <Toolbar>
        <Box display="flex" flexGrow={1}>
          <Typography className={classes.title} variant="h6" noWrap>
            <Link to="/home">
              <img
                src={Imagesimport["logo.png"]}
                alt="logo"
                className="header-logo"
              />
            </Link>
          </Typography>
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={closeNotification}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Grow
            in={open}
            style={{ transformOrigin: "0 0 0" }}
            {...(open ? { timeout: 1000 } : {})}
          >
            <Box p={1} width={300} height={300} backgroundColor="primary" justifyContent="center" display="flex" alignItems={notificationsList.length === 0?"center":""} >
              <List dense subheader>
             
                {notificationsList.length > 0 ? notificationsList.map((list,index) => (
                  <ListItem key={index}>
                    {list.type === "DELETED" ? (
                      <Card variant="outlined" className="w-100" >
                        <CardContent>
                          Your <strong>{unescape(list.postTitle)}</strong> post
                          has been deleted
                        </CardContent>
                      </Card>
                    ) : list.type === "UPDATED" ? (
                      <Card variant="outlined" className="w-100">
                        <CardContent>
                          Your <strong>{unescape(list.postTitle)}</strong> post
                          has been updated
                        </CardContent>
                      </Card>
                    ) : (
                      ""
                    )}
                  </ListItem>
                )) : <ListItem>No notifications yet</ListItem>}
              </List>
            </Box>
          </Grow>
        </Popover>
        <Box
          width="100%"
          display="flex"
          textAlign="center"
          className="content-space d-none-xs"
          textOverflow="ellipsis"
        >
          {menuItems?.map((menu) => (
            <Link to={menu.path} key={menu.id}>
              {isMobile ? (
                <Typography
                  variant="body2"
                  color="inherit"
                  key={menu.id}
                  onClick={
                    menu.isHoverMenu ? hoverMenuOpen(menu.id, menu.path) : null
                  }
                >
                  {menu.name}
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  color="inherit"
                  key={menu.id}
                  onMouseOver={
                    menu.isHoverMenu
                      ? hoverMenuOpen(menu.id, menu.path)
                      : hoverMenuClose
                  }
                >
                  {menu.name}
                </Typography>
              )}
            </Link>
          ))}
        </Box>
        <div className={classes.grow} />
        <Box display="flex">
          <Box mr={2}>
            {userId ? (
              <IconButton
                edge="end"
                aria-haspopup="true"
                color="inherit"
                onClick={openNotification}
              >
                <NotificationsIcon />
              </IconButton>
            ) : null}
          </Box>
          <Box mr={2} className="search-btn-hide">
            <IconButton
              edge="end"
              aria-haspopup="true"
              color="inherit"
              onMouseOver={!isMobile ? handleSearchBar : null}
              onClick={isMobile ? clickSearchBar : null}
            >
              <SearchIcon />
            </IconButton>
          </Box>
          <Box>
            <div className={classes.sectionDesktop}>
              <IconButton
                edge="end"
                aria-haspopup="true"
                onMouseOver={!isMobile ? hoverProfileOpen : null}
                onClick={isMobile ? clickProfileOpen : null}
                color="inherit"
              >
                <PersonRoundedIcon />
              </IconButton>
            </div>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );

  const MobileNavbar = (
    <Box display={{ xs: "block", sm: "block", md: "block" }}>
      <Box
        width="100%"
        display="flex"
        textAlign="center"
        className="content-space d-none-xs"
        textOverflow="ellipsis"
      >
        {menuItems?.map((menu) => (
          <Link to={menu.path} key={menu.id}>
            <Typography variant="body2" color="inherit" key={menu.id}>
              {menu.name}
            </Typography>
          </Link>
        ))}
      </Box>
    </Box>
  );

  return (
    <div className={`${classes.grow} poco-community-header`}>
      {/* Heder app bar for desktop */}
      {rednderAppBar}
      {/* Header Menu Hover Section */}
      <HoverMenu anchor="top" hover={hoverMenu} close={hoverMenuClose}>
        {renderHoverMenuData()}
      </HoverMenu>
      {/* Header Profile Hover Section for desktop*/}
      <HoverMenu anchor="top" hover={profileHover} close={hoverProfileClose}>
        <UserProfile
          isProfileLoad={userProfileLoad}
          {...userProfile}
          topRankingUsers={topRankingUsers}
        />
      </HoverMenu>
      {/* mobile bottom app bar */}

      <MobileAppBar
        isProfileLoad={userProfileLoad}
        {...userProfile}
        topRankingUsers={topRankingUsers}
        mobileNav={MobileNavbar}
      />

      <HoverMenu anchor="top" hover={isSearchOpen} close={handleSearchBarClose}>
        <SearchBar
          close={handleSearchBarClose}
          autoSearch={autoSearch}
          searchData={tagData}
        />
      </HoverMenu>
    </div>
  );
}
