import React, { useEffect } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
// Reusable eles
import { PocoCard } from "components/elements/cards";
import { useDispatch, useSelector } from "react-redux";
import { getAllposts } from "redux/actions/allPostAction";
import { ephocDateCovert } from "core/utils";
import { ShowMore } from "components/elements/showMore";
import { showMorePostsAction } from "redux/actions/globalAction";
import Banner from "static/images/fdsImages"

export default function Resources(props) {
  const { scrollView } = props;
  const dispatch = useDispatch();
  const { isValidApiToken } = useSelector((state) => state.apiAccess);
  // const [postCount, setpostCount] = useState(10);
  const { showMorePosts } = useSelector((state) => state.global);
  const { eventsPost } = showMorePosts;

  useEffect(() => {
    if (isValidApiToken) {
      dispatch(getAllposts(1, eventsPost, 0, "Resources"));
    }
  }, [dispatch, isValidApiToken, eventsPost]);

  // Get product object from redux state
  const { resourcesPost } = useSelector((state) => state.allPost);
  const { tagData, totalCount } = resourcesPost || [];

  const postCountUpdate = () => {
    // setpostCount(postCount + 10);
    dispatch(showMorePostsAction({ eventsPost: eventsPost + 10 }));
  };
  const renderProductSeriesPost = () => {
    return (
      <React.Fragment>
        <React.Fragment>
          <Box width="100%" pb={2} p={3} pt={9} ref={scrollView} id="Resources">
            <Box
              mb={3}
              display={{ xs: "none", sm: "block", md: "block", lg: "block" }}
            >
              <PocoCard Img={Banner.resourceBannerPc} height={300} isBanner={true} />
            </Box>
            <Box
              mb={3}
              display={{ xs: "block", sm: "none", md: "none", lg: "none" }}
            >
              <PocoCard Img={Banner.resourceBannerMob} height={300} isBanner={true} />
            </Box>
            <Typography variant="h5" display="block">
              <strong>Resources</strong>
            </Typography>
          </Box>
          {tagData?.map((ele) => (
            <Grid item xs={12} sm={6} md={6} component="span" key={ele.id}>
              <PocoCard
                postid={ele.id}
                Img={ele.postThumbnail}
                Tittle={ele.postTitle}
                ProfilePic={ele.postAvatar}
                UserName={ele.postUserName}
                postCreatedOn={ephocDateCovert(ele.createdAt)}
                tag={ele.tagName}
                view={ele.postViews}
                like={ele.postLikes}
                profileId={ele.userId}
              />
            </Grid>
          ))}
        </React.Fragment>
        <Box mb={4}  display="flex" justifyContent="center" width={1}>
        <ShowMore
          data={tagData}
          totalCount={totalCount}
          propFunction={postCountUpdate}
        />
        </Box>
      </React.Fragment>
    );
  };
  return renderProductSeriesPost();
}
