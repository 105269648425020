import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { fileUpload } from "redux/actions/createPostAction";
import { toastOpen } from "redux/actions/globalAction";
import Compressor from "compressorjs";
import "./index.scss";
import Delta from 'quill-delta';
import { isYoutubeVideo } from "core/utils";

export function PostEditor(props) {
  const {
    postRef,
    content,
    contentUpdate,
    editorReadOnly = false,
    loader,
    handleFocus,
    handleBlur,
  } = props;

  const dispatch = useDispatch();
  //  method to inster past value only text

  // useEffect(()=>{
  //   const editor = postRef.current.getEditor();
  //   editor.clipboard.addMatcher(Node.ELEMENT_NODE, function (node, delta) {
  //     const plaintext = node.innerText
  //     const Delta = Quill.import('delta')
  //     return new Delta().insert(plaintext)
  // })
  // },[])

  // method to upload post image in fds server

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const imageHandler = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.setAttribute("multiple", "multiple");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      let file_type = file.type;
      var ValidImageTypes = [
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/jpg",
        "image/GIF",
        "image/JPEG",
        "image/PNG",
        "image/JPG",
      ];
      // Save current cursor state
      const range = postRef.current.getEditor();
      // Insert loading placeholder image true
      loader(true);

      // Move cursor to right side of image (easier to continue typing)

      // range.setSelection(range.index + 1);
      if (ValidImageTypes.indexOf(file_type) >= 0) {
        // Gif will not be compressed bcoz gif file will be converted to PNG
        if (file_type === "image/gif") {
          let blobToFile = new File([file], file.name);
          const res = await fileUpload(blobToFile);
          const imageUrl = (await res) && res[0]?.url;

          loader(false);
          // Insert uploaded image
          range.insertEmbed(range.getSelection().index, "image", imageUrl);
          range.setSelection(range.getSelection().index+1)
        } else {
          for(let i=0; i<input.files.length; i++) {
          const temp = input.files[i];
          new Compressor(temp, {
            quality: 0.6,
            success: async function (result) {
              let blobToFile = new File([result], result.name);
              const res = await fileUpload(blobToFile);
              const imageUrl = (await res) && res[0].url;

              // Insert uploaded image
              range.insertEmbed(range.getSelection().index, "image", imageUrl);
              range.setSelection(range.getSelection().index+1)
              loader(false);
            },
            error(err) {
              loader(false);
            },
          });
        }
      }
      } else {
        loader(false);
        dispatch(
          toastOpen({
            isToastOpen: true,
            toastMessage: "Invalid file",
            toastVariant: "error",
          })
        );
      }

      // Remove placeholder image
      // temp.deleteText(position.index, 1);

      // disable loading placeholder image true
    };
  };

// restricting the videos other than youtube
var Video = Quill.import('formats/video');

var BuiltInCreateVideoFunc=Video.create;

Video.create = function customCreateVideoInput(value){
  
  // console.log(value)
  let url = value;
  
  let match = isYoutubeVideo(url)
// console.log(match[2]);
if (match && match[2].length === 11) {
  return BuiltInCreateVideoFunc.call(this,'https://www.youtube.com/embed/' + match[2] + '?showinfo=0')
}
return null
}

// add http if link doesnot starts with http or https
  var Link = Quill.import('formats/link');
  var builtInFunc = Link.sanitize;
  Link.sanitize = function customSanitizeLinkInput(linkValueInput) {
    var val = linkValueInput;

    // do nothing, since this implies user's already using a custom protocol
    if (/^\w+:/.test(val));
    else if (!/^https?:/.test(val))
      val = "http://" + val;

    return builtInFunc.call(this, val); // retain the built-in logic
  }

//  This code will prevent unusal auto scrolling of quill editor on paste.
//  Beginning
  const Clipboard = Quill.import('modules/clipboard')

  class CustomClipboard extends Clipboard {
    onPaste(e) {
      if (e.defaultPrevented || !this.quill.isEnabled()) return;
      let range = this.quill.getSelection();
      let delta = new Delta().retain(range.index);
      let scrollTop = this.quill.scrollingContainer.scrollTop;
      this.container.style.top = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0).toString() + 'px';
      this.container.focus();
      setTimeout(() => {
        this.quill.selection.update(Quill.sources.SILENT);
        let newDelta = this.convert()
        newDelta = {...newDelta,ops: newDelta.ops.filter(item=>{
           if (item.insert && item.insert.video){
            let match = isYoutubeVideo(item.insert.video)
            return match && match[2].length === 11
          }else{
            return true
          }
        })}
        delta = delta.concat(new Delta(newDelta)).delete(range.length);
        this.quill.updateContents(delta, Quill.sources.USER);
        this.quill.setSelection(delta.length() - range.length, Quill.sources.SILENT);
        this.quill.scrollingContainer.scrollTop = scrollTop;
      }, 1);
    }
  }
  
  Quill.register('modules/clipboard', CustomClipboard, true);
  
  // Quill.register({
  //   'modules/tableUI': QuillTableUI
  // }, true)
//  Ending

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ list: "bullet" }],
          ["link", "image", "video"],
          ["bold", "italic", "underline", "strike"], // toggled buttons
          [{ header: [1, 2, 3, 4, 5, 6, false] }], // header dropdown
          [{ color: [] }, { background: [] }], // dropdown with defaults
          [{ font: [] }], // font family
          [{ align: [] }], // text align
          ["clean"],
        ],
        handlers: {
          image: imageHandler,        
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "script",
    "align",
    "link",
    "image",
    "code-block",
    "formula",
    "video",
    "color",
    "background",
  ];

  return (
    <div className="post-editor" onFocus={handleFocus} onBlur={handleBlur}>
      <ReactQuill
        theme="snow"
        ref={postRef}
        value={content}
        modules={modules}
        formats={formats}
        onChange={contentUpdate}
        placeholder={"Write something"}
        readOnly={editorReadOnly}
        autoFocus="true"
        scrollingContainer="html, body"
      />
    </div>
  );
}
