import React from "react";
import { Provider, useDispatch } from "react-redux";
import { getCookies, debounce } from "core/utils";
import { getApitoken } from "redux/actions/apiTokenAction";
import ThemeProvide from "./core/theme/palate";
import Router from "./router/Router";
import ToastAlert from "components/elements/toast";
import Loader from "components/elements/loader";
import store from "./redux/store";
import "./styles/App.scss";
import "./styles/all.scss";

function App() {
  return (
    <Provider store={store}>
      <AppWrapper />
    </Provider>
  );
}

function AppWrapper() {
  const dispatch = useDispatch();

  const handleAccessToken = debounce(function () {
    const cookies = getCookies();
    const { apiToken } = cookies;

    if (apiToken === undefined) {
      dispatch(getApitoken());
    }
  }, 800);

  return (
    <React.Fragment>
      {/* Global component for client side alerts */}
      <ToastAlert />
      <Loader open={true}/>
      <div className="App" onMouseMove={handleAccessToken}>
        <ThemeProvide>
          <Router />
        </ThemeProvide>
      </div>
    </React.Fragment>
  );
}

export default App;
